/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// redux
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/auth";

const emptyForm = {
    email: ""
};

function Basic() {
    const dispatch = useDispatch();
    const { forgetPassLoading } = useSelector(state => state.auth);
    const [form, setForm] = useState(emptyForm);


    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        dispatch(actions.forgetPassword(form));
    };
    // "primary",
    // "secondary",
    // "info",
    // "success",
    // "warning",
    // "error",
    // "light",
    // "dark",
    // "none",

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="primary"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    py={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Forget Password
                    </MDTypography>
                    {/* <MDTypography display="block" variant="button" color="white" my={1}>
                        You will receive an e-mail in maximum 60 seconds
                    </MDTypography> */}
                    <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                        <Grid item xs={2}>
                            <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                                {/* <FacebookIcon color="inherit" /> */}
                            </MDTypography>
                        </Grid>
                        <Grid item xs={2}>
                            <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                                {/* <GitHubIcon color="inherit" /> */}
                            </MDTypography>
                        </Grid>
                        <Grid item xs={2}>
                            <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                                {/* <GoogleIcon color="inherit" /> */}
                            </MDTypography>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <MDBox mb={4}>
                            <MDInput onChange={handleChange} name='email' type="email" label="Email" variant="standard" fullWidth />
                        </MDBox>

                        {
                            forgetPassLoading ?
                                <MDBox mt={6} mb={1}>
                                    <MDButton variant="gradient" color="primary" fullWidth>
                                        Sending Request ...
                                    </MDButton>
                                </MDBox>
                                :
                                <MDBox mt={6} mb={1}>
                                    <MDButton onClick={() => handleSubmit()} variant="gradient" color="primary" fullWidth>
                                        Send request
                                    </MDButton>
                                </MDBox>
                        }
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default Basic;
