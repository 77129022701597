import * as data from "../data";
import * as actionTypes from "../constants";

const initialState = {
  loading: false,
  error: null,
  dashboardData: {},
  // users: data.users(),
  // userOptions: data.userOptions(),
  reportOptions: data.reportOptions(),
  categories: data.categories(),
  memories: {
    longTerm: data.longTermMemory(),
    shortTerm: data.shortTermMemory(),
  },
  playReport: {},
  moduleReport: {}
  // catChart: data.lineChart("Attention"),
  // subCatChart: data.lineChart("Episodic"),
  // donutChart: data.donutChart(),
};

export default function dashboard(state = initialState, action) {
  const { type, payload } = action;
  // console.log(action?.payload?.length > 0 ? action?.payload[0]?.DashboardScores[2] : "","REDUCER")
  switch (type) {
    case actionTypes.FETCH_REPORTS:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: action.payload,
        memories: {
          longTerm: action?.payload?.length > 0 && action?.payload[0]?.DashboardScores[2]?.subCategoryList[0],
          shortTerm: action?.payload?.length > 0 && action?.payload[0]?.DashboardScores[2]?.subCategoryList[1]
        },
        error: null,
      };

    case actionTypes.GET_MODULE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        moduleReport: action.payload
      }

    case actionTypes.GET_PLAY_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        playReport: action.payload
      }

    case actionTypes.GET_REPORT_CLEAR:
      return {
        ...initialState,
      };
    case actionTypes.GET_REPORT_FAIL:
    case actionTypes.GET_PLAY_REPORT_FAIL:
    case actionTypes.GET_MODULE_REPORT_FAIL:
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
}
