export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

///////////////////////////// PAGINATION ///////////////////////////////
export const RESET_FAMILY_PAGINATION_SUCCESS = "RESET_FAMILY_PAGINATION_SUCCESS";
export const RESET_FAMILY_PAGINATION_FAIL = "RESET_FAMILY_PAGINATION_FAIL";

export const RESET_EVENT_PAGINATION_SUCCESS = "RESET_EVENT_PAGINATION_SUCCESS";
export const RESET_EVENT_PAGINATION_FAIL = "RESET_EVENT_PAGINATION_FAIL";

export const RESET_ITEM_PAGINATION_SUCCESS = "RESET_ITEM_PAGINATION_SUCCESS";
export const RESET_ITEM_PAGINATION_FAIL = "RESET_ITEM_PAGINATION_FAIL";

export const RESET_SCHEDULE_PAGINATION_SUCCESS = "RESET_SCHEDULE_PAGINATION_SUCCESS";
export const RESET_SCHEDULE_PAGINATION_FAIL = "RESET_SCHEDULE_PAGINATION_FAIL";

export const RESET_SONG_PAGINATION_SUCCESS = "RESET_SONG_PAGINATION_SUCCESS";
export const RESET_SONG_PAGINATION_FAIL = "RESET_SONG_PAGINATION_FAIL";

/////////////////////////////// SEARCH /////////////////////////////////
export const SEARCH = "SEARCH";

export const FAMILY_SEARCH_SUCCESS = "FAMILY_SEARCH_SUCCESS";
export const FAMILY_SEARCH_FAIL = "FAMILY_SEARCH_FAIL";

export const EVENTS_SEARCH_SUCCESS = "EVENTS_SEARCH_SUCCESS";
export const EVENTS_SEARCH_FAIL = "EVENTS_SEARCH_FAIL";

export const ITEMS_SEARCH_SUCCESS = "ITEMS_SEARCH_SUCCESS";
export const ITEMS_SEARCH_FAIL = "ITEMS_SEARCH_FAIL";

export const SCHEDULE_SEARCH_SUCCESS = "SCHEDULE_SEARCH_SUCCESS";
export const SCHEDULE_SEARCH_FAIL = "SCHEDULE_SEARCH_FAIL";

export const SONGS_SEARCH_SUCCESS = "SONGS_SEARCH_SUCCESS";
export const SONGS_SEARCH_FAIL = "SONGS_SEARCH_FAIL";

/////////////////////////////// ROLES /////////////////////////////////
export const SUPER_ADMIN = "SuperAdmin";
export const ADMIN = "Admin";
export const ORGANIZATION = "Organization";
export const DOCTOR = "Doctor";
export const PATIENT = "EndUser";
export const ADVANCED_SECTION = [SUPER_ADMIN, ADMIN, ORGANIZATION, DOCTOR];

export const HIDE_ROUTES = (role) => {
  const HIDE_ROUTES_FOR_SUPER_ADMIN = [9, 10, 29, 28];
  const HIDE_ROUTES_FOR_ADMIN = [10, 24, 28];
  const HIDE_ROUTES_FOR_ORGANIZATION = [24, 28, 25, 29, 10, 33, 39, 40];
  const HIDE_ROUTES_FOR_DOCTOR = [24, 28, 25, 29, 26, 30, 10, 33, 35, 41];
  const HIDE_ROUTES_FOR_PATIENT = [23, 24, 25, 26, 30, 27, 31, 33, 35, 41];

  switch (role) {
    case ADMIN:
      return HIDE_ROUTES_FOR_ADMIN;
    case ORGANIZATION:
      return HIDE_ROUTES_FOR_ORGANIZATION;
    case DOCTOR:
      return HIDE_ROUTES_FOR_DOCTOR;
    case PATIENT:
      return HIDE_ROUTES_FOR_PATIENT;
    case SUPER_ADMIN:
    default:
      return HIDE_ROUTES_FOR_SUPER_ADMIN;
  }
};

/////////////////////////////// AUTH /////////////////////////////////
export const FETCH_USER = "FETCH_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const REGISTER_LOADING = "REGISTER_LOADING";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const FORGET_PASSWORD_LOADING = "FORGET_PASSWORD_LOADING";
export const FORGET_PASSWORD_REQ_SUCCESS = "FORGET_PASSWORD_REQ_SUCCESS";
export const FORGET_PASSWORD_REQ_FAIL = "FORGET_PASSWORD_REQ_FAIL";

export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

/////////////////////////////// FAMILY /////////////////////////////////
export const FETCH_FAMILIES = "FETCH_FAMILIES";
export const GET_FAMILIES_SUCCESS = "GET_FAMILIES_SUCCESS";
export const GET_FAMILIES_FAIL = "GET_FAMILIES_FAIL";

export const SAVING_FAMILY = "SAVING_FAMILY";
export const ADD_FAMILY_SUCCESS = "ADD_FAMILY_SUCCESS";
export const ADD_FAMILY_FAIL = "ADD_FAMILY_FAIL";
export const EDIT_FAMILY_SUCCESS = "EDIT_FAMILY_SUCCESS";
export const EDIT_FAMILY_FAIL = "EDIT_FAMILY_FAIL";
export const DELETE_FAMILY_SUCCESS = "DELETE_FAMILY_SUCCESS";
export const DELETE_FAMILY_FAIL = "DELETE_FAMILY_FAIL";

/////////////////////////////// EVENTS /////////////////////////////////
export const FETCH_EVENTS = "FETCH_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

export const SAVING_EVENT = "SAVING_EVENT";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL";
export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_FAIL = "EDIT_EVENT_FAIL";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL";

/////////////////////////////// ITEMS /////////////////////////////////
export const FETCH_ITEMS = "FETCH_ITEMS";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_FAIL = "GET_ITEMS_FAIL";

export const SAVING_ITEM = "SAVING_ITEM";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";
export const ADD_ITEM_FAIL = "ADD_ITEM_FAIL";
export const EDIT_ITEM_SUCCESS = "EDIT_ITEM_SUCCESS";
export const EDIT_ITEM_FAIL = "EDIT_ITEM_FAIL";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAIL = "DELETE_ITEM_FAIL";

/////////////////////////////// REMINDER /////////////////////////////////
export const FETCH_REMINDERS = "FETCH_REMINDERS";
export const GET_REMINDERS_SUCCESS = "GET_REMINDERS_SUCCESS";
export const GET_REMINDERS_FAIL = "GET_REMINDERS_FAIL";

export const SAVING_REMINDER = "SAVING_REMINDER";
export const ADD_REMINDER_SUCCESS = "ADD_REMINDER_SUCCESS";
export const ADD_REMINDER_FAIL = "ADD_REMINDER_FAIL";
export const EDIT_REMINDER_SUCCESS = "EDIT_REMINDER_SUCCESS";
export const EDIT_REMINDER_FAIL = "EDIT_REMINDER_FAIL";
export const DELETING_REMINDER = "DELETING_REMINDER";
export const DELETE_REMINDER_SUCCESS = "DELETE_REMINDER_SUCCESS";
export const DELETE_REMINDER_FAIL = "DELETE_REMINDER_FAIL";

/////////////////////////////// BEHAVIOURAL /////////////////////////////////
export const FETCH_BEHAVIOURALS = "FETCH_BEHAVIOURALS";
export const GET_BEHAVIOURALS_SUCCESS = "GET_BEHAVIOURALS_SUCCESS";
export const GET_BEHAVIOURALS_FAIL = "GET_BEHAVIOURALS_FAIL";

export const FETCH_BEHAVIOURAL_OPTIONS = "FETCH_BEHAVIOURAL_OPTIONS";
export const GET_BEHAVIOURAL_OPTIONS_SUCCESS = "GET_BEHAVIOURAL_OPTIONS_SUCCESS";
export const GET_BEHAVIOURAL_OPTIONS_FAIL = "GET_BEHAVIOURAL_OPTIONS_FAIL";

export const SAVING_BEHAVIOURAL = "SAVING_BEHAVIOURAL";
export const ADD_BEHAVIOURAL_SUCCESS = "ADD_BEHAVIOURAL_SUCCESS";
export const ADD_BEHAVIOURAL_FAIL = "ADD_BEHAVIOURAL_FAIL";
export const EDIT_BEHAVIOURAL_SUCCESS = "EDIT_BEHAVIOURAL_SUCCESS";
export const EDIT_BEHAVIOURAL_FAIL = "EDIT_BEHAVIOURAL_FAIL";
export const DELETE_BEHAVIOURAL_SUCCESS = "DELETE_BEHAVIOURAL_SUCCESS";
export const DELETE_BEHAVIOURAL_FAIL = "DELETE_BEHAVIOURAL_FAIL";

/////////////////////////////// SCHEDULE /////////////////////////////////
export const FETCH_SCHEDULES = "FETCH_SCHEDULES";
export const GET_SCHEDULES_SUCCESS = "GET_SCHEDULES_SUCCESS";
export const GET_SCHEDULES_FAIL = "GET_SCHEDULES_FAIL";

export const SAVING_SCHEDULE = "SAVING_SCHEDULE";
export const ADD_SCHEDULE_SUCCESS = "ADD_SCHEDULE_SUCCESS";
export const ADD_SCHEDULE_FAIL = "ADD_SCHEDULE_FAIL";
export const EDIT_SCHEDULE_SUCCESS = "EDIT_SCHEDULE_SUCCESS";
export const EDIT_SCHEDULE_FAIL = "EDIT_SCHEDULE_FAIL";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_FAIL = "DELETE_SCHEDULE_FAIL";

/////////////////////////////// SONGS /////////////////////////////////
export const FETCH_SONGS = "FETCH_SONGS";
export const GET_SONGS_SUCCESS = "GET_SONGS_SUCCESS";
export const GET_SONGS_FAIL = "GET_SONGS_FAIL";

export const SAVING_SONG = "SAVING_SONG";
export const ADD_SONG_SUCCESS = "ADD_SONG_SUCCESS";
export const ADD_SONG_FAIL = "ADD_SONG_FAIL";
export const EDIT_SONG_SUCCESS = "EDIT_SONG_SUCCESS";
export const EDIT_SONG_FAIL = "EDIT_SONG_FAIL";
export const DELETE_SONG_SUCCESS = "DELETE_SONG_SUCCESS";
export const DELETE_SONG_FAIL = "DELETE_SONG_FAIL";

/////////////////////////////// DASHBOARD /////////////////////////////////
export const FETCH_REPORTS = "FETCH_REPORTS";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAIL = "GET_REPORT_FAIL";
export const GET_REPORT_CLEAR = "GET_REPORT_CLEAR";

export const FETCH_PLAY_REPORTS = "FETCH_PLAY_REPORTS";
export const GET_PLAY_REPORT_SUCCESS = "GET_PLAY_REPORT_SUCCESS";
export const GET_PLAY_REPORT_FAIL = "GET_PLAY_REPORT_FAIL";
export const GET_PLAY_REPORT_CLEAR = "GET_PLAY_REPORT_CLEAR";

export const GET_MODULE_REPORT_SUCCESS = "GET_MODULE_REPORT_SUCCESS";
export const GET_MODULE_REPORT_FAIL = "GET_MODULE_REPORT_FAIL";

/////////////////////////////// PROFILE /////////////////////////////////
export const FETCH_PROFILE = "FETCH_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const FETCH_PROFILES = "FETCH_PROFILES";
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";
export const GET_PROFILES_FAIL = "GET_PROFILES_FAIL";

export const FETCH_OTHER_PROFILES = "FETCH_OTHER_PROFILES";
export const GET_OTHER_PROFILES_SUCCESS = "GET_OTHER_PROFILES_SUCCESS";
export const GET_OTHER_PROFILES_FAIL = "GET_OTHER_PROFILES_FAIL";

export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const GET_ACCOUNTS_FAIL = "GET_ACCOUNTS_FAIL";

export const FETCH_ALL_PROFILES = "FETCH_ALL_PROFILES";
export const GET_ALL_PROFILES_SUCCESS = "GET_ALL_PROFILES_SUCCESS";
export const GET_ALL_PROFILES_FAIL = "GET_ALL_PROFILES_FAIL";
export const GET_ALL_DOCTORS_SUCCESS = "GET_ALL_DOCTORS_SUCCESS"
export const GET_ALL_PATIENT_FAIL = "GET_ALL_PATIENT_FAIL"
export const GET_ALL_ORGS_FAIL = "GET_ALL_ORGS_FAIL"
// export const GET_AL="GET_AL"


//getting all DOCTORS
export const GET_All_DOCTORS = "GET_All_DOCTORS"
export const GET_All_DOCTORS_SUCCESS = "GET_All_DOCTORS_SUCCESS"
export const GET_All_DOCTORS_FAIL = "GET_All_DOCTORS_FAIL"


//getting all PATIENTS
export const GET_All_PATIENTS = "GET_All_PATIENTS"
export const GET_All_PATIENTS_SUCCESS = "GET_All_PATIENTS_SUCCESS"
export const GET_All_PATIENTS_FAIL = "GET_All_PATIENTS_FAIL"

//Getting user information
export const FETCH_USERINFO = "FETCH_USER_INFO";
export const GET_USERINFO_SUCCESS = "GET_USERINFO_SUCCESS";
export const GET_USERINFO_FAIL = "GET_USERINFO_FAIL"

// save/update user information
export const UPDATE_USERINFO = "UPDATE_USERINFO";
export const UPDATE_USERINFO_SUCCESS = "UPDATE_USERINFO_SUCCESS";
export const UPDATE_USERINFO_FAIL = "UPDATE_USERINFO_FAIL"

//change password
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL";

/////////////////////////////// ADVANCE_SEARCH_FAMILY /////////////////////////////////
export const ADVANCE_SEARCH_REQUEST_FAMILY = "ADVANCE_SEARCH_REQUEST_FAMILY";
export const GET_ADVANCE_SEARCH_FAMILY_SUCCESS = "GET_ADVANCE_SEARCH_FAMILY_SUCCESS";
export const GET_ADVANCE_SEARCH_FAMILY_FAIL = "GET_ADVANCE_SEARCH_FAMILY_FAIL";
export const RESET_FAMILY = "RESET_FAMILY";

/////////////////////////////// ADVANCE_SEARCH_EVENT /////////////////////////////////
export const ADVANCE_SEARCH_REQUEST_EVENT = "ADVANCE_SEARCH_REQUEST_EVENT";
export const GET_ADVANCE_SEARCH_EVENT_SUCCESS = "GET_ADVANCE_SEARCH_EVENT_SUCCESS";
export const GET_ADVANCE_SEARCH_EVENT_FAIL = "GET_ADVANCE_SEARCH_EVENT_FAIL";
export const RESET_EVENT = "RESET_EVENT";

/////////////////////////////// ADVANCE_SEARCH_ITEM /////////////////////////////////
export const ADVANCE_SEARCH_REQUEST_ITEM = "ADVANCE_SEARCH_REQUEST_ITEM";
export const GET_ADVANCE_SEARCH_ITEM_SUCCESS = "GET_ADVANCE_SEARCH_ITEM_SUCCESS";
export const GET_ADVANCE_SEARCH_ITEM_FAIL = "GET_ADVANCE_SEARCH_ITEM_FAIL";
export const RESET_ITEM = "RESET_ITEM";

/////////////////////////////// ADVANCE_SEARCH_BEHAVIOURAL /////////////////////////////////
export const ADVANCE_SEARCH_REQUEST_BEHAVIOURAL = "ADVANCE_SEARCH_REQUEST_BEHAVIOURAL";
export const GET_ADVANCE_SEARCH_BEHAVIOURAL_SUCCESS = "GET_ADVANCE_SEARCH_BEHAVIOURAL_SUCCESS";
export const GET_ADVANCE_SEARCH_BEHAVIOURAL_FAIL = "GET_ADVANCE_SEARCH_BEHAVIOURAL_FAIL";
export const RESET_BEHAVIOURAL = "RESET_BEHAVIOURAL";

/////////////////////////////// ADVANCE_SEARCH_SCHEDULE /////////////////////////////////
export const ADVANCE_SEARCH_REQUEST_SCHEDULE = "ADVANCE_SEARCH_REQUEST_SCHEDULE";
export const GET_ADVANCE_SEARCH_SCHEDULE_SUCCESS = "GET_ADVANCE_SEARCH_SCHEDULE_SUCCESS";
export const GET_ADVANCE_SEARCH_SCHEDULE_FAIL = "GET_ADVANCE_SEARCH_SCHEDULE_FAIL";
export const RESET_SCHEDULE = "RESET_SCHEDULE";

/////////////////////////////// ADVANCE_SEARCH_SONG /////////////////////////////////
export const ADVANCE_SEARCH_REQUEST_SONG = "ADVANCE_SEARCH_REQUEST_SONG";
export const GET_ADVANCE_SEARCH_SONG_SUCCESS = "GET_ADVANCE_SEARCH_SONG_SUCCESS";
export const GET_ADVANCE_SEARCH_SONG_FAIL = "GET_ADVANCE_SEARCH_SONG_FAIL";
export const RESET_SONG = "RESET_SONG";

/////////////////////////////// USERS /////////////////////////////////
export const CREATING_ORG = "CREATING_ORG";
export const CREATE_ORG_SUCCESS = "CREATE_ORG_SUCCESS";
export const CREATE_ORG_FAIL = "CREATE_ORG_FAIL";

export const CREATING_DOCTOR = "CREATING_DOCTOR";
export const CREATE_DOCTOR_SUCCESS = "CREATE_DOCTOR_SUCCESS";
export const CREATE_DOCTOR_FAIL = "CREATE_DOCTOR_FAIL";

export const CREATING_PATIENT = "CREATING_PATIENT";
export const CREATE_PATIENT_SUCCESS = "CREATE_PATIENT_SUCCESS";
export const CREATE_PATIENT_FAIL = "CREATE_PATIENT_FAIL";


//FEEDBACK
export const NEW_FEEDBACK = "NEW_FEEDBACK"
export const NEW_FEEDBACK_SUCCESS = "NEW_FEEDBACK_SUCCESS"
export const NEW_FEEDBACK_FAIL = "NEW_FEEDBACK_FAIL"

export const GET_PROFILES_BY_ID = "GET_PROFILES_BY_ID"
export const GET_PROFILES_BY_ID_SUCCESS = "GET_PROFILES_BY_ID_SUCCESS"
export const GET_PROFILES_BY_ID_FAIL = "GET_PROFILES_BY_ID_FAIL"


export const ADVANCE_SEARCH = "ADVANCE_SEARCH"
export const ADVANCE_SEARCH_SUCCESS = "ADVANCE_SEARCH_SUCCESS"
export const ADVANCE_SEARCH_FAIL = "ADVANCE_SEARCH_FAIL"


//GET_REPORTS_API

// export const GET_REPORTS = "GET_REPORTS"
// export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS"
// export const GET_REPORTS_FAIL = "GET_REPORTS_FAIL"
