import React from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// common
import Pagination from "layouts/pages/upload/common/Pagination";

// local
import EventCard from "./EventCard";

const EventContainer = ({ events, pagination, handlePagination, handleDelete }) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="primary"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            apple-airplay
          </Icon>
        </MDBox>
        <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
          List Of Events 
          <MDButton
            color="primary"
            className="absolute-r-16"
            onClick={() => navigate(`/upload/event/add-event`)}
          >
            + Add Event
          </MDButton>
        </MDTypography>
      </MDBox>
      <Grid container>
        {events &&
          events.map((ele, idx) => (
            <Grid key={idx} item xs={12} md={12} lg={12}>
              <MDBox p={2} pb={events.length - 1 === idx ? 2 : 0} m={0}>
                <EventCard
                  no={`${pagination.page * pagination.pageSize - pagination.pageSize + (idx + 1)}.`}
                  id={ele.id}
                  name={ele.name}
                  location={ele.location}
                  year={ele.year}
                  photo={ele.photo}
                  handleDelete={handleDelete}
                />
              </MDBox>
            </Grid>
          ))}
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Pagination
            pageCount={pagination.pageCount}
            page={pagination.page}
            handleClick={handlePagination}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default EventContainer;
