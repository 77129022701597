import * as actionTypes from "../constants";
import * as data from "../data";

const initialState = {
  adminFilters: data.adminFilters(),
};

export default function search(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case actionTypes.SEARCH:
    default:
      return state;
  }
}
