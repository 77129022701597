import * as actionTypes from "../constants";
import * as apis from "../requests/apis";
import * as payloads from "../requests/payloads";
import { clientRequest, contentHeader } from "../requests";

// Get Songs
export const getSongs = (page, pageSize, reset = false) => async (dispatch) => {
  try {
    if(reset) dispatch({ type: actionTypes.RESET_SONG });
    else dispatch({ type: actionTypes.FETCH_SONGS });
    const res = await clientRequest(
      actionTypes.GET,
      `${apis.GET_SONGS_API}?page=${page}&pageSize=${pageSize}`,
      null
    );
    const data = payloads.getSongsPayload(res.data.songList);
    const pagination = payloads.getPagination(res.data);
    dispatch({
      type: actionTypes.GET_SONGS_SUCCESS,
      payload: {
        data,
        pagination,
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_SONGS_FAIL,
      payload: err,
    });
  }
};

// Add Song
export const addSong = (form) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SAVING_SONG });
    const payload = payloads.songPayload(form);
    await clientRequest(actionTypes.POST, apis.ADD_SONG_API, payload, contentHeader);
    setTimeout(() => {
      dispatch({
        type: actionTypes.ADD_SONG_SUCCESS,
      });
    }, 2000);
  } catch (err) {
    dispatch({
      type: actionTypes.ADD_SONG_FAIL,
      payload: err,
    });
  }
};

// Edit Song
export const editSong = (form) => async (dispatch) => {
  try {
    // await clientRequest(actionTypes.GET, apis.GET_FAMILIES_API, null);

    dispatch({
      type: actionTypes.EDIT_SONG_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.EDIT_SONG_FAIL,
      payload: err,
    });
  }
};

// Delete Song
export const deleteSong = (form) => async (dispatch) => {
  try {
    await clientRequest(actionTypes.DELETE, `${apis.DELETE_SONG_API}?Id=${form.id}`, null);
    dispatch({
      type: actionTypes.DELETE_SONG_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.DELETE_SONG_FAIL,
      payload: err,
    });
  }
};

// Reset Songs Pagination
export const resetSongsPagination = () => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.RESET_SONG_PAGINATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.RESET_SONG_PAGINATION_FAIL,
    });
  }
};

// Get Advance Search
export const getAdvanceSearchSong = (username) => async (dispatch) => {
  try{
    dispatch({ type: actionTypes.ADVANCE_SEARCH_REQUEST_SONG });
    const res = await clientRequest(actionTypes.GET, `${apis.GET_ADVANCE_SEARCH_API}?username=${username}&searchType=song`);
    const data = await payloads.getSongsPayload(res.data);
    dispatch({
      type: actionTypes.GET_ADVANCE_SEARCH_SONG_SUCCESS,
      payload: data,
    });
  }catch(err) {
    dispatch({ type: actionTypes.GET_ADVANCE_SEARCH_SONG_FAIL, payload: err });
  }
}