import * as actionTypes from "../constants";
import * as apis from "../requests/apis";
import * as payloads from "../requests/payloads";
import * as utils from "../utils";
import * as data from "../data";
import { clientRequest, contentHeader, clientImageRequest } from "../requests";

// Get Families
export const getFamilies = (page, pageSize, reset = false) => async (dispatch) => {
  try {
    if (reset) dispatch({ type: actionTypes.RESET_FAMILY });
    else dispatch({ type: actionTypes.FETCH_FAMILIES });
    const res = await clientRequest(
      actionTypes.GET,
      `${apis.GET_FAMILIES_API}?page=${page}&pageSize=${pageSize}`,
      null
    );
    // console.log(res)
    const data = await payloads.getFamiliesPayload(res.data.familyList);
    // console.log(data)
    const pagination = payloads.getPagination(res.data);
    dispatch({
      type: actionTypes.GET_FAMILIES_SUCCESS,
      payload: {
        data,
        pagination,
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_FAMILIES_FAIL,
      payload: err,
    });
  }
};

// Add Family
export const addFamily = (form) => async (dispatch) => {
  try {
    const { firstName, lastName, relationship, photo, base64String } = form
    dispatch({ type: actionTypes.SAVING_FAMILY });
    let payload = ""
    if (!base64String) {
      payload = payloads.familyPayload({ firstName, lastName, relationship, photo });
    } else {
      payload = payloads.familyPayload({ firstName, lastName, relationship, base64String });
    }
    console.log(...payload)

    const result = await clientRequest(actionTypes.POST, apis.ADD_FAMILY_API, payload, contentHeader);
    console.log(result);
    dispatch({
      type: actionTypes.ADD_FAMILY_SUCCESS
    });
  } catch (err) {
    dispatch({
      type: actionTypes.ADD_FAMILY_FAIL,
      payload: err,
    });
  }
};

// Edit Family
export const editFamily = (form) => async (dispatch) => {
  try {
    // await clientRequest(actionTypes.GET, apis.GET_FAMILIES_API, null);

    dispatch({
      type: actionTypes.EDIT_FAMILY_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.EDIT_FAMILY_FAIL,
      payload: err,
    });
  }
};

// Delete Family
export const deleteFamily = (form) => async (dispatch) => {
  try {
    await clientRequest(actionTypes.POST, `${apis.DELETE_FAMILY_API}?Id=${form.id}`, null);
    dispatch({
      type: actionTypes.DELETE_FAMILY_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.DELETE_FAMILY_FAIL,
      payload: err,
    });
  }
};

// Reset Family Pagination
export const resetFamilyPagination = () => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.RESET_FAMILY_PAGINATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.RESET_FAMILY_PAGINATION_FAIL,
    });
  }
};

// Get Family Image 
export const getFamilyImage = async (photoId) => {
  try {
    const result = await clientImageRequest(actionTypes.GET, `${apis.GET_FAMILY_IMAGE_API}?id=${photoId}`, null, null, 'arraybuffer');
    if (result && result.data) return utils.imageEncode(result.data);
    else return data.getPlaceHolder();
  } catch (err) {
    return data.getPlaceHolder();
  }
}

// Get Advance Search
export const getAdvanceSearchFamily = (username) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.ADVANCE_SEARCH_REQUEST_FAMILY });
    const res = await clientRequest(actionTypes.GET, `${apis.GET_ADVANCE_SEARCH_API}?username=${username}&searchType=family`);
    const data = await payloads.getFamiliesPayload(res.data);
    console.log('advance search', data)
    dispatch({
      type: actionTypes.GET_ADVANCE_SEARCH_FAMILY_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({ type: actionTypes.GET_ADVANCE_SEARCH_FAMILY_FAIL, payload: err });
  }
}