import * as actionTypes from "../constants";
import * as data from "../data";

const initialState = {
  loading: true,
  error: null,
  events: [],
  fullData: [],
  pagination: {
    page: 1,
    pageSize: 10,
    pageCount: 1,
  },
  savingEvent: false,
  eventSaved: false,
  advanceSearchLoading: false,
  resetting: false,
};

export default function events(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_EVENTS:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_EVENTS_SUCCESS:
      return {
        ...state,
        error: null,
        events: payload.data,
        fullData: payload.data,
        pagination: payload.pagination,
        loading: false,
        savingEvent: false,
        eventSaved: false,
        advanceSearchLoading: false,
        resetting: false,
      };

    case actionTypes.SAVING_EVENT:
      return {
        ...state,
        savingEvent: true,
      };

    case actionTypes.ADD_EVENT_SUCCESS: {
      return {
        ...state,
        savingEvent: false,
        eventSaved: true,
      };
    }

    case actionTypes.EDIT_EVENT_SUCCESS: {
      let latestEvents = state.events.map((ele) => (ele.id === payload.id ? payload : ele));
      return {
        ...state,
        events: latestEvents,
        fullData: latestEvents,
      };
    }

    case actionTypes.DELETE_EVENT_SUCCESS: {
      let latestEvents = state.events.filter((ele) => ele.id !== payload.id);
      return {
        ...state,
        events: latestEvents,
        fullData: latestEvents,
      };
    }

    case actionTypes.EVENTS_SEARCH_SUCCESS: {
      let latestEvents = state.fullData.filter(
        (ele) =>
          ele.name.toUpperCase().includes(payload.toUpperCase()) ||
          ele.location.toUpperCase().includes(payload.toUpperCase()) ||
          ele.year.toString().toUpperCase().includes(payload.toUpperCase())
      );
      return {
        ...state,
        events: latestEvents,
      };
    }

    case actionTypes.RESET_EVENT_PAGINATION_SUCCESS:
      return {
        ...state,
        pagination: {
          page: 1,
          pageSize: 10,
          pageCount: 1,
        },
      };

    case actionTypes.GET_EVENTS_FAIL:
    case actionTypes.ADD_EVENT_FAIL:
    case actionTypes.EDIT_EVENT_FAIL:
    case actionTypes.DELETE_EVENT_FAIL:
    case actionTypes.EVENTS_SEARCH_FAIL:
    case actionTypes.RESET_EVENT_PAGINATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        savingEvent: false,
        eventSaved: false,
        advanceSearchLoading: false,
        resetting: false,
      };

    case actionTypes.ADVANCE_SEARCH_REQUEST_EVENT:
      return {
        ...state,
        advanceSearchLoading: true,
      };

    case actionTypes.GET_ADVANCE_SEARCH_EVENT_SUCCESS:
      return {
        ...state,
        events: payload,
        advanceSearchLoading: false,
      };

    case actionTypes.GET_ADVANCE_SEARCH_EVENT_FAIL:
      return {
        ...state,
        advanceSearchLoading: false,
      };

    case actionTypes.RESET_EVENT:
      return {
        ...state,
        resetting: true,
      };

    default:
      return state;
  }
}
