/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Dialog from "components/Dialog";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import EventCalendar from "examples/Calendar";

// local
import ReminderForm from "./ReminderForm";

// redux
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/reminder";
import * as utils from "redux/utils";

const emptyForm = {
  id: uuidv4(),
  medicationName: "",
  frequency: "",
  startDate: "",
  startTime: "",
  count: "",
  remindMeBefore: "",
};

const Reminder = () => {
  const dispatch = useDispatch();
  const reminder = useSelector((state) => state.reminder);

  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(emptyForm);
  const [deleteEvent, setDeleteEvent] = useState(false);

  useEffect(() => {
    dispatch(actions.getReminders());
  }, [dispatch]);

  useEffect(() => {
    if (reminder.reminderSaved || reminder.reminderDeleted) {
      setOpen(false);
      setForm(emptyForm);
      setDeleteEvent(false);
      dispatch(actions.getReminders());
    }
  }, [reminder.reminderSaved, reminder.reminderDeleted]);

  const handleClose = () => {
    setForm(emptyForm);
    setOpen(false);
  };

  const handleSave = () => {
    dispatch(actions.addReminder(form));
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSelect = (name, value) => {
    setForm({ ...form, [name]: value.name });
  };

  const handleDateClick = (e) => {
    const startDate = e.dateStr;
    setForm({ ...form, startDate });
    setOpen(true);
  };

  const handleEventClick = (e) => {
    setDeleteEvent(e.event._def.publicId);
  };

  const handleCloseDelete = () => {
    setDeleteEvent(false);
  };

  const handleDelete = () => {
    const form = { id: deleteEvent };
    dispatch(actions.deleteReminder(form));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card sx={{ width: "100%", height: 80, marginTop: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              people
            </Icon>
          </MDBox>
          <MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Reminder
              {/* <MDButton color="primary" className="absolute-r-16" onClick={handleOpen}>
                + Add New Reminder
              </MDButton> */}
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>

      <Dialog
        open={open}
        loading={reminder.savingReminder}
        handleClose={handleClose}
        handleSave={handleSave}
        title="Add New Reminder"
        content={
          <ReminderForm
            form={form}
            reminder={reminder}
            handleChange={handleChange}
            handleSelect={handleSelect}
          />
        }
      />

      <Dialog
        open={deleteEvent}
        loading={reminder.deletingReminder}
        handleClose={handleCloseDelete}
        handleSave={handleDelete}
        title="Delete"
        content={
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Are you sure you want to delete?
          </MDTypography>
        }
      />

      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12} sx={{ height: "max-content" }}>
            {useMemo(
              () => (
                <EventCalendar
                  initialView="dayGridMonth"
                  initialDate={utils.parseDate(new Date())}
                  events={reminder.reminders}
                  selectable
                  editable
                  dateClick={handleDateClick}
                  eventClick={handleEventClick}
                />
              ),
              [reminder.reminders]
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Reminder;
