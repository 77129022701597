import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MDBox from "components/MDBox";

const MemoryTypes = ({ name, value, handleChange }) => {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name={name}
        value={value}
        onChange={handleChange}
      >
        <MDBox display="flex" px={4}>
          <FormControlLabel value="Long Term" control={<Radio />} label="Long Term" />
          <FormControlLabel value="Short Term" control={<Radio />} label="Short Term" />
        </MDBox>
      </RadioGroup>
    </FormControl>
  );
};

export default MemoryTypes;
