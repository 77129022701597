import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultItem from "examples/Items/DefaultItem";
import MemoryTypes from "components/Radio/MemoryTypes";

const SubCategories = ({ types, ...props }) => {
  const memoryColor = (color) => {
    console.log(color, "COLOR")
    switch (color) {
      case "episodic memory":
        return "primary";
      case "procedural memory":
        return "secondary";
      case "semantic memory":
        return "warning";
      case "working memory":
        return "primary";
      case "working spatial memory":
        return "secondary";
      default:
        return "info";
    }
  };

  console.log('props chart', types)

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="warning"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            memory
          </Icon>
        </MDBox>
        <MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            SubCategories
          </MDTypography>
        </MDBox>
      </MDBox>
      <Grid container>
        <Grid item xs={12}>
          <MDBox mt={2}>
            <MemoryTypes
              name={props.memory.name}
              value={props.memory.value}
              handleChange={props.handleMemoryType}
            />
          </MDBox>
          <MDBox p={4}>
            {types &&
              types.map((ele, idx) => (
                <MDBox
                  key={idx}
                  mt={idx === 0 ? 0 : 2.5}
                  style={{
                    cursor: "pointer",
                    background: props.subCat?.name === ele.name && "lemonchiffon",
                  }}
                  onClick={() => props.handleSubCat(ele)}
                >
                  {console.log('ele',ele)}
                  <DefaultItem
                    color={memoryColor(ele.name?.toLowerCase().trim())}
                    icon={"memory"}
                    title={ele.name}
                    description={ele.score?.toFixed(2)}
                  />
                </MDBox>
              ))}
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SubCategories;
