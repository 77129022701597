import axios from "axios";
import * as services from "redux/services";

export const headers = {
  Accept: "application/json",
};

export const contentHeader = {
  "Content-Type": "application/json",
};

export const clientRequest = (method, url, data, customHeader) => {
  const token = `Bearer ${services.getToken()}`;
  return axios({
    headers: { ...headers, ...customHeader, Authorization: token },
    method,
    url,
    data,
  });
};

export const clientImageRequest = async (method, url, data, customHeader, responseType) => {
  const token = `Bearer ${services.getToken()}`;
  return axios({
    headers: { ...headers, ...customHeader, Authorization: token },
    method,
    url,
    data,
    responseType,
  });
};