import React, { useEffect } from "react";

// pages
import App from "App";

// redux
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/auth";

const Main = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(actions.getUser());
  }, [dispatch]);

  if (loading) return <div>Loading...</div>;
  else return <App />;
};

export default Main;
