/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Main from "Main";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";

// css
import "./index.css";

// redux
import { Provider } from "react-redux";
import store from "redux/store";
import { ToastContainer } from 'react-toastify'

ReactDOM.render(
  <div>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <Provider store={store}>
          <Main />
        </Provider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
    <ToastContainer style={{ fontSize: "12px" }} />
    <span
      style={{
        position: "fixed",
        right: 0,
        backgroundColor: "white",
        bottom: 0,
      }}
    >
      <div id="google_translate_element"></div>
    </span>
  </div >,
  document.getElementById("root")
);
