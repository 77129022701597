import * as actionTypes from "../constants";

const initialState = {
  loading: true,
  error: null,
  schedules: [],
  fullData: [],
  taskOptions: [],
  timeOptions: [],
  pagination: {
    page: 1,
    pageSize: 10,
    pageCount: 1,
  },
  savingSchedule: false,
  scheduleSaved: false,
  advanceSearchLoading: false,
  resetting: false,
};

export default function schedule(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_SCHEDULES:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        error: null,
        schedules: payload.data,
        fullData: payload.data,
        taskOptions: payload.taskOptions,
        timeOptions: payload.timeOptions,
        pagination: payload.pagination,
        loading: false,
        savingSchedule: false,
        scheduleSaved: false,
        advanceSearchLoading: false,
        resetting: false,
      };

    case actionTypes.SAVING_SCHEDULE:
      return {
        ...state,
        savingSchedule: true,
      };

    case actionTypes.ADD_SCHEDULE_SUCCESS: {
      return {
        ...state,
        savingSchedule: false,
        scheduleSaved: true,
      };
    }

    case actionTypes.EDIT_SCHEDULE_SUCCESS: {
      let latestSchedules = state.schedules.map((ele) => (ele.id === payload.id ? payload : ele));
      return {
        ...state,
        schedules: latestSchedules,
      };
    }

    case actionTypes.DELETE_SCHEDULE_SUCCESS: {
      let latestSchedules = state.schedules.filter((ele) => ele.id !== payload.id);
      return {
        ...state,
        schedules: latestSchedules,
        fullData: latestSchedules,
      };
    }

    case actionTypes.SCHEDULE_SEARCH_SUCCESS: {
      let latestSchedules = state.fullData.filter(
        (ele) =>
          ele.time.toUpperCase().includes(payload.toUpperCase()) ||
          ele.task.toUpperCase().includes(payload.toUpperCase())
      );
      return {
        ...state,
        schedules: latestSchedules,
      };
    }

    case actionTypes.RESET_SCHEDULE_PAGINATION_SUCCESS:
      return {
        ...state,
        pagination: {
          page: 1,
          pageSize: 10,
          pageCount: 1,
        },
      };

    case actionTypes.GET_SCHEDULES_FAIL:
    case actionTypes.ADD_SCHEDULE_FAIL:
    case actionTypes.EDIT_SCHEDULE_FAIL:
    case actionTypes.DELETE_SCHEDULE_FAIL:
    case actionTypes.SCHEDULE_SEARCH_FAIL:
    case actionTypes.RESET_SCHEDULE_PAGINATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        savingSchedule: false,
        scheduleSaved: false,
        advanceSearchLoading: false,
        resetting: false,
      };

    case actionTypes.ADVANCE_SEARCH_REQUEST_SCHEDULE:
      return {
        ...state,
        advanceSearchLoading: true,
      };

    case actionTypes.GET_ADVANCE_SEARCH_SCHEDULE_SUCCESS:
      return {
        ...state,
        schedules: payload,
        advanceSearchLoading: false,
      };

    case actionTypes.GET_ADVANCE_SEARCH_SCHEDULE_FAIL:
      return {
        ...state,
        advanceSearchLoading: false,
      };

    case actionTypes.RESET_SCHEDULE:
      return {
        ...state,
        resetting: true,
      };

    default:
      return state;
  }
}
