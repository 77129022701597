import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Select from "components/Select";

// layouts
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

const ReminderForm = ({ form, reminder, handleChange, handleSelect }) => {
  return (
    <MDBox p={1} pb={6}>
      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Start Date"
              name="startDate"
              value={form.startDate}
              onChange={handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Medication Name"
              name="medicationName"
              value={form.medicationName}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Select
              label="Frequency"
              options={reminder.frequencyOptions}
              name="frequency"
              value={form.frequency}
              handleSelect={handleSelect}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              label="Start Time"
              options={reminder.startTimeOptions}
              name="startTime"
              value={form.startTime}
              handleSelect={handleSelect}
            />
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Select
              label="How many times a day"
              options={reminder.countOptions}
              name="count"
              value={form.count}
              handleSelect={handleSelect}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              label="Remind Me Before"
              options={reminder.remindMeOptions}
              name="remindMeBefore"
              value={form.remindMeBefore}
              handleSelect={handleSelect}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default ReminderForm;
