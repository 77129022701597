import * as actionTypes from "../constants";
import * as apis from "../requests/apis";
import * as payloads from "../requests/payloads";
import { clientRequest } from "../requests";

// Get Reports
export const getReports = (email, dates) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_REPORTS });
    const res = await clientRequest(
      actionTypes.GET,
      `${apis.GET_REPORTS_API}?${email !== undefined ? `Email=${email}&` : ""}fromDate=${dates.fromDate
      }&toDate=${dates.toDate}`
    );
    console.log(res.data, "RESPONSE");
    dispatch({
      type: actionTypes.GET_REPORT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_REPORT_FAIL,
      payload: err,
    });
  }
};

// Get module Reports
export const getModuleReports = (email, dates) => async (dispatch) => {
  try {
    console.log('module action', email, dates);
    dispatch({ type: actionTypes.FETCH_REPORTS });
    const res = await clientRequest(
      actionTypes.GET,
      `${apis.GET_MODULE_PLAY_REPORTS_API}?${email !== undefined ? `Email=${email}&` : ""}fromDate=${dates.fromDate
      }&toDate=${dates.toDate}`
    );
    dispatch({
      type: actionTypes.GET_MODULE_REPORT_SUCCESS,
      payload: res.data[0],
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_MODULE_REPORT_FAIL,
      payload: err,
    });
  }
};

// Get play Reports
export const getPlayReports = (email, dates) => async (dispatch) => {
  try {
    console.log('play action', email, dates);

    dispatch({ type: actionTypes.FETCH_PLAY_REPORTS });
    const res = await clientRequest(
      actionTypes.GET,
      `${apis.GET_PLAY_REPORTS_API}?${email !== undefined ? `Email=${email}&` : ""}fromDate=${dates.fromDate
      }&toDate=${dates.toDate}`
    );
    dispatch({
      type: actionTypes.GET_PLAY_REPORT_SUCCESS,
      payload: res.data[0],
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_PLAY_REPORT_FAIL,
      payload: err,
    });
  }
};