import { IoMenuOutline } from "react-icons/io5";
import React from 'react';
import Button from './Button';
import "./header.css";
import { Link } from 'react-router-dom';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


export default function Navbar(to, BtnContent) {
    return (
        <div className="customContainer">
            <nav className="navbar navbar-expand-lg navbar-dark bg-custom">
                <a className="navbar-brand me-5" href="https://cosmaapp.com/">
                    <img
                        src="/Images/cosma.png"
                        height="48"
                        alt="Cosma Logo"
                        loading="lazy"
                    />
                    <span className='ms-3 brandName' style={{ color: "#46166b" }}>
                        Cosma Therapy
                    </span>
                </a>

                <button
                    className="navbar-toggler"
                    type="button"
                    data-mdb-toggle="collapse"
                    data-mdb-target="#navbarButtonsExample"
                    aria-controls="navbarButtonsExample"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <IoMenuOutline className='barContent' />
                </button>


                <div className="collapse navbar-collapse" id="navbarButtonsExample">

                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link" href="https://cosmaapp.com/">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://cosmaapp.com/features">Features</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://cosmaapp.com/subscribe">Subscribe</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://cosmaapp.com/blog">Blog</a>
                        </li>
                    </ul>
                    <div className="d-flex align-items-center ml-20">
                        <a className='csCustomBtn me-3 bookHeaderBtn' href='https://cosmaapp.com/bookdemo'>Book Demo</a>
                        <Button to="/auth/login" BtnContent='Login' />
                    </div>
                </div>
            </nav>
        </div>

    )
}