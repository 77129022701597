import * as actionTypes from "../constants";

const initialState = {
  loading: false,
  error: null,
  searching: false,
  profileInfo: {
    userName: "",
    orgName: "",
    diagnosisList: [],
    stagesList: [],
    sexList: [],
    religionList: [],
  },
  userInfo: {
    email: "",
    firstName: "",
    lastName: "",
    gender: "",
    age: 0,
    religion: "",
    diagnosis: "",
    stages: "",
    otherDiagnosis: "",
    role: "",
    qualifications: ""
  },
  profiles: null,
  otherProfiles: null,
  accounts: [],
  allProfiles: {
    orgs: [],
    doctors: [],
    patients: [],
    feedbacks: []
  },
  allProfilesById: [],
  allDoctors: [],
  allPatients: []
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;


  switch (type) {
    case actionTypes.FETCH_PROFILE:
    case actionTypes.FETCH_PROFILES:
    case actionTypes.FETCH_OTHER_PROFILES:
    case actionTypes.FETCH_ACCOUNTS:
    case actionTypes.FETCH_ALL_PROFILES:
    case actionTypes.FETCH_USERINFO:
    case actionTypes.UPDATE_USERINFO:
    case actionTypes.CHANGE_PASSWORD:
    case actionTypes.NEW_FEEDBACK:
    case actionTypes.GET_PROFILES_BY_ID:
    case actionTypes.GET_All_DOCTORS:
    case actionTypes.GET_All_PATIENTS:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_All_DOCTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        allDoctors: payload
      }
    case actionTypes.GET_All_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allPatients: payload
      }
    case actionTypes.GET_PROFILES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        searching: false,
        allProfilesById: payload
      };
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
    case actionTypes.NEW_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileInfo: payload,
        error: null,
      };
    case actionTypes.GET_USERINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: payload,
        error: null
      }
    case actionTypes.DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case actionTypes.DELETE_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case actionTypes.GET_PROFILE_FAIL:
    case actionTypes.GET_PROFILES_FAIL:
    case actionTypes.GET_OTHER_PROFILES_FAIL:
    case actionTypes.GET_ACCOUNTS_FAIL:
    case actionTypes.GET_ALL_PROFILES_FAIL:
    case actionTypes.GET_USER_FAIL:
    case actionTypes.UPDATE_USERINFO_FAIL:
    case actionTypes.CHANGE_PASSWORD_FAIL:
    case actionTypes.NEW_FEEDBACK_FAIL:
    case actionTypes.GET_PROFILES_BY_ID_FAIL:
    case actionTypes.GET_All_DOCTORS_FAIL:
    case actionTypes.GET_All_PATIENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case actionTypes.GET_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        profiles: payload,
        error: null,
      };
    case actionTypes.ADVANCE_SEARCH:
      return {
        ...state,
        searching: true
      };
    case actionTypes.ADVANCE_SEARCH_SUCCESS:
    case actionTypes.ADVANCE_SEARCH_FAIL:
      return {
        ...state,
        searching: false,
        allProfiles: payload
      }
    case actionTypes.GET_ALL_ORGS_FAIL:
      return {
        ...state,
        loading: false,
        allProfiles: {
          orgs: []
        }
      }

    case actionTypes.GET_OTHER_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        otherProfiles: payload,
        error: null,
      };

    case actionTypes.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        accounts: payload,
        error: null,
      };

    case actionTypes.GET_ALL_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        allProfiles: {
          orgs: payload.orgs,
          doctors: payload.doctors,
          patients: payload.patients,
          feedbacks: payload.feedbacks
        },
        error: null,
      };

    case actionTypes.UPDATE_USERINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: payload
      }

    default:
      return state;
  }
}
