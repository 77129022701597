import * as actionTypes from "../constants";
import * as data from "../data";

const initialState = {
  loading: true,
  error: null,
  families: [],
  fullData: [],
  pagination: {
    page: 1,
    pageSize: 10,
    pageCount: 1,
  },
  savingFamily: false,
  familySaved: false,
  advanceSearchLoading: false,
  resetting: false,
};

export default function family(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_FAMILIES:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_FAMILIES_SUCCESS:
      return {
        ...state,
        error: null,
        families: payload.data,
        fullData: payload.data,
        pagination: payload.pagination,
        loading: false,
        savingFamily: false,
        familySaved: false,
        advanceSearchLoading: false,
        resetting: false,
      };

    case actionTypes.SAVING_FAMILY:
      return {
        ...state,
        savingFamily: true,
      };

    case actionTypes.ADD_FAMILY_SUCCESS: {
      return {
        ...state,
        savingFamily: false,
        familySaved: true,
      };
    }

    case actionTypes.EDIT_FAMILY_SUCCESS: {
      let latestFamilies = state.families.map((ele) => (ele.id === payload.id ? payload : ele));
      return {
        ...state,
        families: latestFamilies,
        fullData: latestFamilies,
      };
    }

    case actionTypes.DELETE_FAMILY_SUCCESS: {
      let latestFamilies = state.families.filter((ele) => ele.id !== payload.id);
      return {
        ...state,
        families: latestFamilies,
        fullData: latestFamilies,
      };
    }

    case actionTypes.FAMILY_SEARCH_SUCCESS: {
      let latestFamilies = state.fullData.filter(
        (ele) =>
          ele.firstName.toUpperCase().includes(payload.toUpperCase()) ||
          ele.lastName.toUpperCase().includes(payload.toUpperCase()) ||
          ele.relationship.toUpperCase().includes(payload.toUpperCase())
      );
      return {
        ...state,
        families: latestFamilies,
      };
    }

    case actionTypes.RESET_FAMILY_PAGINATION_SUCCESS:
      return {
        ...state,
        pagination: {
          page: 1,
          pageSize: 10,
          pageCount: 1,
        },
      };

    case actionTypes.GET_FAMILIES_FAIL:
    case actionTypes.ADD_FAMILY_FAIL:
    case actionTypes.EDIT_FAMILY_FAIL:
    case actionTypes.DELETE_FAMILY_FAIL:
    case actionTypes.FAMILY_SEARCH_FAIL:
    case actionTypes.RESET_FAMILY_PAGINATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        savingFamily: false,
        familySaved: false,
        advanceSearchLoading: false,
        resetting: false,
      };

    case actionTypes.ADVANCE_SEARCH_REQUEST_FAMILY:
      return {
        ...state,
        advanceSearchLoading: true,
      };

    case actionTypes.GET_ADVANCE_SEARCH_FAMILY_SUCCESS:
      return {
        ...state,
        families: payload,
        advanceSearchLoading: false,
      };

    case actionTypes.GET_ADVANCE_SEARCH_FAMILY_FAIL:
      return {
        ...state,
        advanceSearchLoading: false,
      };

    case actionTypes.RESET_FAMILY:
      return {
        ...state,
        resetting: true,
      };

    default:
      return state;
  }
}
