/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Accordion from "components/Accordion";
import MDTypography from "components/MDTypography";

function Categories({ longTerm, shortTerm, ...props }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} lineHeight={1}>
        <MDTypography variant="h6" fontWeight="medium">
          Memories
        </MDTypography>
      </MDBox>

      <MDBox p={2}>
        <Accordion longTerm={longTerm} shortTerm={shortTerm} {...props}/>
      </MDBox>
    </Card>
  );
}

export default Categories;
