import React from "react";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Loader from "components/Loader";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// local
import ItemContainer from "./ItemContainer";
import Filters from "./Filters";

// redux
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/item";
import * as actionTypes from "redux/constants";

const Item = () => {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.item);
  const auth = useSelector((state) => state.auth);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [item.pagination.page]);

  React.useEffect(() => {
    dispatch(actions.getItems(item.pagination.page, item.pagination.pageSize));

    return () => {
      dispatch(actions.resetItemsPagination());
    };
  }, [dispatch]);

  const handlePagination = (page) => {
    dispatch(actions.getItems(page, item.pagination.pageSize));
  };

  const handleDelete = (id) => {
    const payload = { id };
    dispatch(actions.deleteItem(payload));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        {item.loading ? (
          <Loader />
        ) : (
          <>
            {actionTypes.ADVANCED_SECTION.includes(auth.user?.role) && (
              <Grid item xs={12} md={12} xl={12}>
                <MDBox py={3} pl={1}>
                { auth.user.role ==="SuperAdmin" &&
                    <Filters />
                  }
                </MDBox>
              </Grid>
            )}

<Grid item xs={12} md={12} xl={12}>
          <MDBox py={3} pl={1}>
                {            
              <div class="card bg-gradient-default">
                <div class="card-body">
                <h5 class="font-weight-normal text-info text-gradient">Instruction</h5>
                <blockquote class="blockquote text-white mb-0">
                  <p class="text-dark ms-3">You will upload at least 10 pictures of items that you have in your house and you use on a regular basis. You will be asked to name the item and write what is the primary colour of the item. You upload the picture of an item by choosing ‘Add Item’ follow the above steps and ‘Choose file’ from your personal storage e.g. in computer to upload the pictures.
The pictures cannot be of the same objects and of the same colours. Therefore, choose varying items with different colours.</p>

                </blockquote>
              </div>
            </div>
                }
          </MDBox>
        </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <MDBox py={3} pr={1}>
                <ItemContainer
                  items={item.items}
                  pagination={item.pagination}
                  handlePagination={handlePagination}
                  handleDelete={handleDelete}
                />
              </MDBox>
            </Grid>
          </>
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default Item;
