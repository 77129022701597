import * as actionTypes from "../constants";
import * as apis from "../requests/apis";
import * as payloads from "../requests/payloads";
import { clientRequest, contentHeader } from "../requests";

// Get Reminders
export const getReminders = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_REMINDERS });
    const frequency = await clientRequest(actionTypes.GET, apis.GET_FREQUENCY_LIST_API, null);
    const time = await clientRequest(actionTypes.GET, apis.GET_LIST_TIME_API, null);
    const reminder = await clientRequest(actionTypes.GET, apis.GET_REMINDERS_API, null);
    const frequencyOptions = payloads.getOptionsListPayload(frequency.data);
    const startTimeOptions = payloads.getOptionsListPayload(time.data);
    const reminders = payloads.getRemindersPayload(reminder.data);
    const data = {
      frequencyOptions,
      startTimeOptions,
      reminders
    }

    dispatch({
      type: actionTypes.GET_REMINDERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_REMINDERS_FAIL,
      payload: err,
    });
  }
};

// Add Reminder
export const addReminder = (form) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SAVING_REMINDER });
    const payload = payloads.reminderPayload(form);
    await clientRequest(actionTypes.POST, apis.ADD_REMINDER_API, payload, contentHeader);
    setTimeout(() => {
      dispatch({
        type: actionTypes.ADD_REMINDER_SUCCESS,
      });
    }, 2000);
  } catch (err) {
    dispatch({
      type: actionTypes.ADD_REMINDER_FAIL,
      payload: err,
    });
  }
};

// Edit Reminder
export const editReminder = (form) => async (dispatch) => {
  try {
    // await clientRequest(actionTypes.GET, apis.GET_FAMILIES_API, null);

    dispatch({
      type: actionTypes.EDIT_REMINDER_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.EDIT_REMINDER_FAIL,
      payload: err,
    });
  }
};

// Delete Reminder
export const deleteReminder = (form) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETING_REMINDER });
    await clientRequest(actionTypes.POST, `${apis.DELETE_REMINDER_API}?Id=${form.id}`, null);
    setTimeout(() => {
      dispatch({
        type: actionTypes.DELETE_REMINDER_SUCCESS,
        payload: form,
      });
    }, 2000)
  } catch (err) {
    dispatch({
      type: actionTypes.DELETE_REMINDER_FAIL,
      payload: err,
    });
  }
};
