import { Card } from "@mui/material"
import MDBox from "components/MDBox"
import BaseLayout from "layouts/pages/account/components/BaseLayout"
import { useDispatch, useSelector } from "react-redux"
import Loader from "components/Loader";
import MDTypography from "components/MDTypography"
import { useEffect } from "react";
import * as actions from "redux/actions/profile";
import * as actionType from "redux/constants"
import UserProfileFilter from "./UserProfile.filter";
import { Table } from "react-bootstrap";

const UserProfile = () => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile);
    const auth = useSelector((state) => state.auth);


    useEffect(() => {
        if (auth?.user.role === actionType.SUPER_ADMIN) {
            dispatch(actions.getAllProfilesById("", actionType.PATIENT));
        } else {
            dispatch(actions.getAllProfilesById(auth.user.email, actionType.PATIENT));
        }
    }, [dispatch]);

    console.log(profile)

    if (profile.loading) {
        return <Loader />;
    } else {
        return (
            <BaseLayout>
                {
                    auth.user.role === actionType.SUPER_ADMIN && <UserProfileFilter />
                }

                <MDBox mt={4}>
                    <MDBox mb={3}>
                        <Card id="2fa" sx={{ overflow: "visible" }}>
                            <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <MDTypography variant="h5">All User profiles</MDTypography>
                                {/* <MDBadge variant="contained" color="success" badgeContent="enabled" container /> */}
                            </MDBox>
                            <MDBox p={3}>
                                <MDBox>
                                    <div >
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Gender</th>
                                                    <th>Age</th>
                                                    <th>Religion </th>
                                                    <th>Diagnosis</th>
                                                    <th>Stages</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    profile?.allProfilesById && profile?.allProfilesById?.length > 0 &&
                                                    profile?.allProfilesById?.map((ele, idx) => (
                                                        <tr key={idx}>
                                                            <td>{ele.firstName}</td>
                                                            <td>{ele.email}</td>
                                                            <td>{ele.gender}</td>
                                                            <td>{ele.age}</td>
                                                            <td>{ele.religion}</td>
                                                            <td>{ele.diagnosis}</td>
                                                            <td>{ele.stages}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                        {
                                            !profile?.allProfilesById?.length && <p className="text-center p-5">No data found ! </p>
                                        }
                                    </div>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </MDBox>
                </MDBox>
            </BaseLayout>
        )
    }


}

export default UserProfile