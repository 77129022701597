import * as actionTypes from "../constants";
import * as data from "../data";

const initialState = {
  loading: true,
  error: null,
  songs: [],
  fullData: [],
  pagination: {
    page: 1,
    pageSize: 10,
    pageCount: 1,
  },
  savingSong: false,
  songSaved: false,
  advanceSearchLoading: false,
  resetting: false,
};

export default function songs(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_SONGS:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_SONGS_SUCCESS:
      return {
        ...state,
        error: null,
        songs: payload.data,
        fullData: payload.data,
        pagination: payload.pagination,
        loading: false,
        savingSong: false,
        songSaved: false,
        advanceSearchLoading: false,
        resetting: false,
      };

    case actionTypes.SAVING_SONG:
      return {
        ...state,
        savingSong: true,
      };

    case actionTypes.ADD_SONG_SUCCESS: {
      return {
        ...state,
        savingSong: false,
        songSaved: true,
      };
    }

    case actionTypes.EDIT_SONG_SUCCESS: {
      let latestSongs = state.songs.map((ele) => (ele.id === payload.id ? payload : ele));
      return {
        ...state,
        songs: latestSongs,
        fullData: latestSongs,
      };
    }

    case actionTypes.DELETE_SONG_SUCCESS: {
      let latestSongs = state.songs.filter((ele) => ele.id !== payload.id);
      return {
        ...state,
        songs: latestSongs,
        fullData: latestSongs,
      };
    }

    case actionTypes.SONGS_SEARCH_SUCCESS: {
      let latestSongs = state.fullData.filter((ele) =>
        ele.name.toUpperCase().includes(payload.toUpperCase())
      );
      return {
        ...state,
        songs: latestSongs,
      };
    }

    case actionTypes.RESET_SONG_PAGINATION_SUCCESS:
      return {
        ...state,
        pagination: {
          page: 1,
          pageSize: 10,
          pageCount: 1,
        },
      };

    case actionTypes.GET_SONGS_FAIL:
    case actionTypes.ADD_SONG_FAIL:
    case actionTypes.EDIT_SONG_FAIL:
    case actionTypes.DELETE_SONG_FAIL:
    case actionTypes.SONGS_SEARCH_FAIL:
    case actionTypes.RESET_SONG_PAGINATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        savingSong: false,
        songSaved: false,
        advanceSearchLoading: false,
        resetting: false,
      };

    case actionTypes.ADVANCE_SEARCH_REQUEST_SONG:
      return {
        ...state,
        advanceSearchLoading: true,
      };

    case actionTypes.GET_ADVANCE_SEARCH_SONG_SUCCESS:
      return {
        ...state,
        songs: payload,
        advanceSearchLoading: false,
      };

    case actionTypes.GET_ADVANCE_SEARCH_SONG_FAIL:
      return {
        ...state,
        advanceSearchLoading: false,
      };

    case actionTypes.RESET_SONG:
      return {
        ...state,
        resetting: true,
      };

    default:
      return state;
  }
}
