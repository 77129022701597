import React from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import BookingCard from "examples/Cards/BookingCard";

function EventCard({ no, id, name, location, year, photo, handleDelete }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Rotate Image" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={4}
      m={0}
    >
      <Grid container>
        <Grid item xs={12} md={4} lg={4}>
          <MDBox mt={3}>
            <MDBox width="100%" display="flex" flexDirection="column">
              <MDBox mb={1} lineHeight={0} display="flex" flexDirection="row">
                <MDTypography variant="subtitle1" fontWeight="medium">
                  {no}
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0} display="flex" flexDirection="row">
                <MDTypography variant="subtitle1" color="text">
                  Name:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {name}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0} display="flex">
                <MDTypography variant="subtitle1" color="text">
                  Location:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {location}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0} display="flex">
                <MDTypography variant="subtitle1" color="text">
                  Year:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {year}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <MDBox mt={3}>
            <BookingCard image={photo} action={actionButtons} />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <MDBox display="flex" justifyContent="end">
            <MDBox mr={1}>
              <MDButton variant="text" color="error" onClick={() => handleDelete(id)}>
                <Icon>delete</Icon>&nbsp;delete
              </MDButton>
            </MDBox>
            {/* <MDButton variant="text" color={darkMode ? "white" : "dark"}>
              <Icon>edit</Icon>&nbsp;edit
            </MDButton> */}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default EventCard;
