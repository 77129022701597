import * as actionTypes from "../constants";

const initialState = {
  loading: true,
  error: null,
  behaviourals: [],
  fullData: [],
  pagination: {
    page: 1,
    pageSize: 10,
    pageCount: 1,
  },
  exerciseOptions: [],
  walkOptions: [],
  medicationOptions: [],
  hobbyOptions: [],
  mileOptions: [],
  sleepOptions: [],
  timeOptions: [],
  savingBehavioural: false,
  behaviouralSaved: false,
  advanceSearchLoading: false,
  resetting: false,
};

export default function behavioural(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_BEHAVIOURALS:
    case actionTypes.FETCH_BEHAVIOURAL_OPTIONS:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_BEHAVIOURALS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        behaviourals: payload.behaviourals,
        savingBehavioural: false,
        behaviouralSaved: false,
        advanceSearchLoading: false,
        resetting: false,
      };

    case actionTypes.GET_BEHAVIOURAL_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        exerciseOptions: payload.exerciseOptions,
        walkOptions: payload.walkOptions,
        medicationOptions: payload.medicationOptions,
        hobbyOptions: payload.hobbyOptions,
        mileOptions: payload.mileOptions,
        sleepOptions: payload.sleepOptions,
        timeOptions: payload.timeOptions,
      };

    case actionTypes.SAVING_BEHAVIOURAL:
      return {
        ...state,
        savingBehavioural: true,
      };

    case actionTypes.ADD_BEHAVIOURAL_SUCCESS: {
      return {
        ...state,
        savingBehavioural: false,
        behaviouralSaved: true,
      };
    }

    case actionTypes.EDIT_BEHAVIOURAL_SUCCESS: {
      let lastestBehaviourals = state.behaviourals.map((ele) =>
        ele.id === payload.id ? payload : ele
      );
      return {
        ...state,
        behaviourals: lastestBehaviourals,
      };
    }

    case actionTypes.DELETE_BEHAVIOURAL_SUCCESS: {
      let lastestBehaviourals = state.behaviourals.filter((ele) => ele.id !== payload.id);
      return {
        ...state,
        behaviourals: lastestBehaviourals,
      };
    }

    case actionTypes.GET_BEHAVIOURALS_FAIL:
    case actionTypes.ADD_BEHAVIOURAL_FAIL:
    case actionTypes.EDIT_BEHAVIOURAL_FAIL:
    case actionTypes.DELETE_BEHAVIOURAL_FAIL:
    case actionTypes.GET_BEHAVIOURAL_OPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        savingBehavioural: false,
        behaviouralSaved: false,
        advanceSearchLoading: false,
        resetting: false,
      };

    case actionTypes.ADVANCE_SEARCH_REQUEST_BEHAVIOURAL:
      return {
        ...state,
        advanceSearchLoading: true,
      };

    case actionTypes.GET_ADVANCE_SEARCH_BEHAVIOURAL_SUCCESS:
      return {
        ...state,
        behaviourals: payload,
        advanceSearchLoading: false,
      };

    case actionTypes.GET_ADVANCE_SEARCH_BEHAVIOURAL_FAIL:
      return {
        ...state,
        advanceSearchLoading: false,
      };

    case actionTypes.RESET_BEHAVIOURAL:
      return {
        ...state,
        resetting: true,
      };

    default:
      return state;
  }
}
