import * as actionTypes from "../constants";
import * as apis from "../requests/apis";
import * as payloads from "../requests/payloads";
import * as utils from "../utils";
import * as data from "../data";
import { clientRequest, contentHeader, clientImageRequest } from "../requests";

// Get Items
export const getItems = (page, pageSize, reset = false) => async (dispatch) => {
  try {
    if (reset) dispatch({ type: actionTypes.RESET_ITEM });
    else dispatch({ type: actionTypes.FETCH_ITEMS });
    const itemsList = await clientRequest(
      actionTypes.GET,
      `${apis.GET_ITEMS_API}?page=${page}&pageSize=${pageSize}`,
      null
    );
    // const colorList = await clientRequest(actionTypes.GET, apis.GET_COLOR_LIST_API, null);
    const data = await payloads.getItemsPayload(itemsList.data.itemList);
    // const colorOptions = payloads.getColorOptionsListPayload(colorList.data);
    console.log('items data',data)

    const pagination = payloads.getPagination(itemsList.data);
    dispatch({
      type: actionTypes.GET_ITEMS_SUCCESS,
      payload: {
        data,
        pagination,
        // colorOptions,
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_ITEMS_FAIL,
      payload: err,
    });
  }
};

// Add Item
export const addItem = (form) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SAVING_ITEM });
    const payload = payloads.itemPayload(form);
     const result =await clientRequest(actionTypes.POST, apis.ADD_ITEM_API, payload, contentHeader);
     setTimeout(() => {
      dispatch({
        type: actionTypes.ADD_ITEM_SUCCESS,
      });
    }, 2000);
  } catch (err) {
    dispatch({
      type: actionTypes.ADD_ITEM_FAIL,
      payload: err,
    });
  }
};

// Edit Item
export const editItem = (form) => async (dispatch) => {
  try {
    // const res = await clientRequest(actionTypes.GET, apis.GET_ITEMS_API, null);

    dispatch({
      type: actionTypes.EDIT_ITEM_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.EDIT_ITEM_FAIL,
      payload: err,
    });
  }
};

// Delete Item
export const deleteItem = (form) => async (dispatch) => {
  try {
    await clientRequest(actionTypes.POST, `${apis.DELETE_ITEM_API}?Id=${form.id}`, null);
    dispatch({
      type: actionTypes.DELETE_ITEM_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.DELETE_ITEM_FAIL,
      payload: err,
    });
  }
};

// Reset Items Pagination
export const resetItemsPagination = () => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.RESET_ITEM_PAGINATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.RESET_ITEM_PAGINATION_FAIL,
    });
  }
};

// Get Item Image 
export const getItemImage = async (photoId) => {
  try {
    const result = await clientImageRequest(actionTypes.GET, `${apis.GET_ITEM_IMAGE_API}?id=${photoId}`, null, null, 'arraybuffer');
    if (result && result.data) return utils.imageEncode(result.data);
    else return data.getPlaceHolder();
  } catch (err) {
    return data.getPlaceHolder();
  }
}

// Get Advance Search
export const getAdvanceSearchItem = (username) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.ADVANCE_SEARCH_REQUEST_ITEM });
    const res = await clientRequest(actionTypes.GET, `${apis.GET_ADVANCE_SEARCH_API}?username=${username}&searchType=item`);
    const data = await payloads.getItemsPayload(res.data);
    dispatch({
      type: actionTypes.GET_ADVANCE_SEARCH_ITEM_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({ type: actionTypes.GET_ADVANCE_SEARCH_ITEM_FAIL, payload: err });
  }
}