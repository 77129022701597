import axios from "axios";
import * as actionTypes from "../constants";
import * as data from "../data";

const initialState = {
  loading: true,
  error: null,
  items: [],
  fullData: [],
  colorOptions: [],
  pagination: {
    page: 1,
    pageSize: 10,
    pageCount: 1,
  },
  savingItem: false,
  itemSaved: false,
  advanceSearchLoading: false,
  resetting: false,
};

export default function items(state = initialState, action) {
  const { type, payload } = action;

  // console.log(payload)
  switch (type) {
    case actionTypes.FETCH_ITEMS:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_ITEMS_SUCCESS:
      return {
        ...state,
        error: null,
        items: payload.data,
        fullData: payload.data,
        colorOptions: payload.colorOptions,
        pagination: payload.pagination,
        loading: false,
        savingItem: false,
        itemSaved: false,
        advanceSearchLoading: false,
        resetting: false,
      };

    case actionTypes.SAVING_ITEM:
      return {
        ...state,
        savingItem: true,
      };

    case actionTypes.ADD_ITEM_SUCCESS: {
      return {
        ...state,
        savingItem: false,
        itemSaved: true,
      };
    }

    case actionTypes.EDIT_ITEM_SUCCESS: {
      let latestItems = state.items.map((ele) => (ele.id === payload.id ? payload : ele));
      return {
        ...state,
        items: latestItems,
        fullData: latestItems,
      };
    }

    case actionTypes.DELETE_ITEM_SUCCESS: {
      let latestItems = state.items.filter((ele) => ele.id !== payload.id);
      return {
        ...state,
        items: latestItems,
        fullData: latestItems,
      };
    }

    case actionTypes.ITEMS_SEARCH_SUCCESS: {
      let latestItems = state.fullData.filter(
        (ele) =>
          ele.name.toUpperCase().includes(payload.toUpperCase()) ||
          ele.color.toUpperCase().includes(payload.toUpperCase())
      );
      return {
        ...state,
        items: latestItems,
      };
    }

    case actionTypes.RESET_ITEM_PAGINATION_SUCCESS:
      return {
        ...state,
        pagination: {
          page: 1,
          pageSize: 10,
          pageCount: 1,
        },
      };

    case actionTypes.GET_ITEMS_FAIL:
    case actionTypes.ADD_ITEM_FAIL:
    case actionTypes.EDIT_ITEM_FAIL:
    case actionTypes.DELETE_ITEM_FAIL:
    case actionTypes.ITEMS_SEARCH_FAIL:
    case actionTypes.RESET_ITEM_PAGINATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        savingItem: false,
        itemSaved: false,
        advanceSearchLoading: false,
        resetting: false,
      };

    case actionTypes.ADVANCE_SEARCH_REQUEST_ITEM:
      return {
        ...state,
        advanceSearchLoading: true,
      };

    case actionTypes.GET_ADVANCE_SEARCH_ITEM_SUCCESS:
      return {
        ...state,
        items: payload,
        advanceSearchLoading: false,
      };

    case actionTypes.GET_ADVANCE_SEARCH_ITEM_FAIL:
      return {
        ...state,
        advanceSearchLoading: false,
      };

    case actionTypes.RESET_ITEM:
      return {
        ...state,
        resetting: true,
      };

    default:
      return state;
  }
}
