import { Card } from "@mui/material"
import MDBox from "components/MDBox"
import BaseLayout from "layouts/pages/account/components/BaseLayout"
import { useDispatch, useSelector } from "react-redux"
import Loader from "components/Loader";
import MDTypography from "components/MDTypography"
import { useEffect } from "react";
import * as actions from "redux/actions/profile";
import * as actionType from "redux/constants"
import OrgFilter from "./Org.filter"
import { Table } from "react-bootstrap";


const OrganizationList = () => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile);
    const auth = useSelector((state) => state.auth);


    useEffect(() => {
        if (auth?.user.role === actionType.SUPER_ADMIN) {
            dispatch(actions.getOrgs());
        } else {
            dispatch(actions.getOrgs(auth.user.email));
        }
    }, [dispatch]);

    console.log(profile)

    if (profile.loading) {
        return <Loader />;
    } else {
        return (
            <BaseLayout>
                {
                    auth.user.role === actionType.SUPER_ADMIN && <OrgFilter />
                }

                <MDBox mt={4}>
                    <MDBox mb={3}>
                        <Card id="2fa" sx={{ overflow: "visible" }}>
                            <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <MDTypography variant="h5">All Organizations</MDTypography>
                                {/* <MDBadge variant="contained" color="success" badgeContent="enabled" container /> */}
                            </MDBox>
                            <MDBox p={3}>
                                <MDBox>
                                    <div>
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Subscribe Id</th>
                                                    <th>Customer Id</th>
                                                    <th>License purchased</th>
                                                    <th>Remaining</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    profile.allProfiles?.orgs?.length > 0 &&
                                                    profile.allProfiles?.orgs?.map((ele, idx) => (
                                                        <tr key={idx}>
                                                            <td>{ele.orgName}</td>
                                                            <td>{ele.orgEmail}</td>
                                                            <td>{ele.subscriptionId}</td>
                                                            <td>{ele.customerId}</td>
                                                            <td></td>
                                                            <td>{ele.quantity}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                        {
                                            !profile.allProfiles?.orgs?.length && <p className="text-center p-5">No data found ! </p>
                                        }
                                    </div>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </MDBox>
                </MDBox>
            </BaseLayout>
        )
    }


}

export default OrganizationList