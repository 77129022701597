import * as actionTypes from "../constants";
import * as apis from "../requests/apis";
import * as payloads from "../requests/payloads";
import { clientRequest, contentHeader } from "../requests";

// Get Schedules
export const getSchedules = (page, pageSize, reset = false) => async (dispatch) => {
  try {
    if(reset) dispatch({ type: actionTypes.RESET_SCHEDULE });
    else dispatch({ type: actionTypes.FETCH_SCHEDULES });
    const schedulesList = await clientRequest(
      actionTypes.GET,
      `${apis.GET_SCHEDULES_API}?page=${page}&pageSize=${pageSize}`,
      null
    );
    const tasksList = await clientRequest(actionTypes.GET, apis.GET_TASK_LIST_API, null);
    const timesList = await clientRequest(actionTypes.GET, apis.GET_TIME_LIST_API, null);
    const data = payloads.getSchedulesPayload(schedulesList.data.scheduleList);
    const taskOptions = payloads.getTaskOptionsListPayload(tasksList.data);
    const timeOptions = payloads.getOptionsListPayload(timesList.data);
    const pagination = payloads.getPagination(schedulesList.data);
    dispatch({
      type: actionTypes.GET_SCHEDULES_SUCCESS,
      payload: {
        data,
        pagination,
        taskOptions,
        timeOptions,
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_SCHEDULES_FAIL,
      payload: err,
    });
  }
};

// Add Schedule
export const addSchedule = (form) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SAVING_SCHEDULE });
    const payload = payloads.schedulePayload(form);
    await clientRequest(actionTypes.POST, apis.ADD_SCHEDULE_API, payload, contentHeader);
    setTimeout(() => {
      dispatch({
        type: actionTypes.ADD_SCHEDULE_SUCCESS,
      });
    }, 2000);
  } catch (err) {
    dispatch({
      type: actionTypes.ADD_SCHEDULE_FAIL,
      payload: err,
    });
  }
};

// Edit Schedule
export const editSchedule = (form) => async (dispatch) => {
  try {
    // await clientRequest(actionTypes.GET, apis.GET_FAMILIES_API, null);

    dispatch({
      type: actionTypes.EDIT_SCHEDULE_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.EDIT_SCHEDULE_FAIL,
      payload: err,
    });
  }
};

// Delete Schedule
export const deleteSchedule = (form) => async (dispatch) => {
  try {
    await clientRequest(actionTypes.DELETE, `${apis.DELETE_SCHEDULE_API}?Id=${form.id}`, null);
    dispatch({
      type: actionTypes.DELETE_SCHEDULE_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.DELETE_SCHEDULE_FAIL,
      payload: err,
    });
  }
};

// Reset Schedule Pagination
export const resetSchedulePagination = () => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.RESET_SCHEDULE_PAGINATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.RESET_SCHEDULE_PAGINATION_FAIL,
    });
  }
};

// Get Advance Search
export const getAdvanceSearchSchedule = (username) => async (dispatch) => {
  try{
    dispatch({ type: actionTypes.ADVANCE_SEARCH_REQUEST_SCHEDULE });
    const res = await clientRequest(actionTypes.GET, `${apis.GET_ADVANCE_SEARCH_API}?username=${username}&searchType=schedule`);
    const data = await payloads.getSchedulesPayload(res.data);
    dispatch({
      type: actionTypes.GET_ADVANCE_SEARCH_SCHEDULE_SUCCESS,
      payload: data,
    });
  }catch(err) {
    dispatch({ type: actionTypes.GET_ADVANCE_SEARCH_SCHEDULE_FAIL, payload: err });
  }
}
