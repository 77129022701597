import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const MyRadio = ({ name, value, handleChange }) => {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name={name}
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="TRUE" control={<Radio />} label="Yes" />
        <FormControlLabel value="FALSE" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
};

export default MyRadio;
