import PlaceHolder from "assets/images/placeholder2.jpg";

const songReference = "https://www.kozco.com/tech/piano2-CoolEdit.mp3";

// Upload Module
export const getPlaceHolder = () => {
  return PlaceHolder;
};

export const getSongReference = () => {
  return songReference;
};

export const pagination = () => {
  return { page: 1, pageSize: 10, pageCount: 1 };
};

export const families = () => {
  return [
    {
      id: 1,
      firstName: "John",
      lastName: "Mike",
      relationship: "Father",
      photo: PlaceHolder,
    },
    {
      id: 2,
      firstName: "Kelly",
      lastName: "Mike",
      relationship: "Mother",
      photo: PlaceHolder,
    },
    {
      id: 3,
      firstName: "Richard",
      lastName: "Mike",
      relationship: "Brother",
      photo: PlaceHolder,
    },
    {
      id: 4,
      firstName: "Kime",
      lastName: "Mike",
      relationship: "Sister",
      photo: PlaceHolder,
    },
    {
      id: 5,
      firstName: "Rock",
      lastName: "Mike",
      relationship: "Grand-Father",
      photo: PlaceHolder,
    },
  ];
};

export const events = () => {
  return [
    {
      id: 1,
      name: "Tea Party",
      location: "London",
      year: "2019",
      photo: PlaceHolder,
    },
    {
      id: 2,
      name: "Tea Party",
      location: "London",
      year: "2019",
      photo: PlaceHolder,
    },
    {
      id: 3,
      name: "Tea Party",
      location: "London",
      year: "2019",
      photo: PlaceHolder,
    },
    {
      id: 4,
      name: "Tea Party",
      location: "London",
      year: "2019",
      photo: PlaceHolder,
    },
    {
      id: 5,
      name: "Tea Party",
      location: "London",
      year: "2019",
      photo: PlaceHolder,
    },
  ];
};

export const items = () => {
  return [
    {
      id: 1,
      name: "Toy",
      color: "Red",
      photo: PlaceHolder,
    },
    {
      id: 2,
      name: "Toy",
      color: "Red",
      photo: PlaceHolder,
    },
    {
      id: 3,
      name: "Toy",
      color: "Red",
      photo: PlaceHolder,
    },
    {
      id: 4,
      name: "Toy",
      color: "Red",
      photo: PlaceHolder,
    },
    {
      id: 5,
      name: "Toy",
      color: "Red",
      photo: PlaceHolder,
    },
  ];
};

export const reminder = () => {
  return [
    {
      id: 1,
      title: "Toy",
      reminder: "9:00 AM",
    },
    {
      id: 2,
      title: "Toy",
      reminder: "9:00 AM",
    },
    {
      id: 3,
      title: "Toy",
      reminder: "9:00 AM",
    },
    {
      id: 4,
      title: "Toy",
      reminder: "9:00 AM",
    },
    {
      id: 5,
      title: "Toy",
      reminder: "9:00 AM",
    },
  ];
};

export const behavioural = () => {
  return [
    {
      id: 1,
      breakfastIntakeTime: "08:00 AM",
      lunchIntakeTime: "2:30 AM",
      dinnerIntakeTime: "6:00 PM",
      otherIntakeTime: null,
      breakfast1: "CEREAL",
      breakfast2: "APPLE",
      breakfast3: "SANDWICH",
      lunch1: "SALAD",
      lunch2: "FISH",
      lunch3: null,
      dinner1: "MILK",
      dinner2: "CEREAL",
      dinner3: "CURRY",
      other: null,
      sleepTime: "9:30 PM",
      sleepDuration: "4",
      sleeper: "TRUE",
      exercise: "EVERYDAY",
      walk: "EVERYDAY",
      miles: "1",
      medication: "REGULARLY",
      hobbies: "WATCHING TV",
    },
    {
      id: 2,
      breakfastIntakeTime: "08:00 AM",
      lunchIntakeTime: "2:30 AM",
      dinnerIntakeTime: "6:00 PM",
      otherIntakeTime: null,
      breakfast1: "CEREAL",
      breakfast2: "APPLE",
      breakfast3: "SANDWICH",
      lunch1: "SALAD",
      lunch2: "FISH",
      lunch3: null,
      dinner1: "MILK",
      dinner2: "CEREAL",
      dinner3: "CURRY",
      other: null,
      sleepTime: "9:30 PM",
      sleepDuration: "4",
      sleeper: "TRUE",
      exercise: "EVERYDAY",
      walk: "EVERYDAY",
      miles: "1",
      medication: "REGULARLY",
      hobbies: "WATCHING TV",
    },
    {
      id: 3,
      breakfastIntakeTime: "08:00 AM",
      lunchIntakeTime: "2:30 AM",
      dinnerIntakeTime: "6:00 PM",
      otherIntakeTime: null,
      breakfast1: "CEREAL",
      breakfast2: "APPLE",
      breakfast3: "SANDWICH",
      lunch1: "SALAD",
      lunch2: "FISH",
      lunch3: null,
      dinner1: "MILK",
      dinner2: "CEREAL",
      dinner3: "CURRY",
      other: null,
      sleepTime: "9:30 PM",
      sleepDuration: "4",
      sleeper: "TRUE",
      exercise: "EVERYDAY",
      walk: "EVERYDAY",
      miles: "1",
      medication: "REGULARLY",
      hobbies: "WATCHING TV",
    },
    {
      id: 4,
      breakfastIntakeTime: "08:00 AM",
      lunchIntakeTime: "2:30 AM",
      dinnerIntakeTime: "6:00 PM",
      otherIntakeTime: null,
      breakfast1: "CEREAL",
      breakfast2: "APPLE",
      breakfast3: "SANDWICH",
      lunch1: "SALAD",
      lunch2: "FISH",
      lunch3: null,
      dinner1: "MILK",
      dinner2: "CEREAL",
      dinner3: "CURRY",
      other: null,
      sleepTime: "9:30 PM",
      sleepDuration: "4",
      sleeper: "TRUE",
      exercise: "EVERYDAY",
      walk: "EVERYDAY",
      miles: "1",
      medication: "REGULARLY",
      hobbies: "WATCHING TV",
    },
    {
      id: 5,
      breakfastIntakeTime: "08:00 AM",
      lunchIntakeTime: "2:30 AM",
      dinnerIntakeTime: "6:00 PM",
      otherIntakeTime: null,
      breakfast1: "CEREAL",
      breakfast2: "APPLE",
      breakfast3: "SANDWICH",
      lunch1: "SALAD",
      lunch2: "FISH",
      lunch3: null,
      dinner1: "MILK",
      dinner2: "CEREAL",
      dinner3: "CURRY",
      other: null,
      sleepTime: "9:30 PM",
      sleepDuration: "4",
      sleeper: "TRUE",
      exercise: "EVERYDAY",
      walk: "EVERYDAY",
      miles: "1",
      medication: "REGULARLY",
      hobbies: "WATCHING TV",
    },
  ];
};

export const schedules = () => {
  return [
    {
      id: 1,
      task: "Wake Up",
      time: "6:30 AM",
    },
    {
      id: 2,
      task: "Wake Up",
      time: "6:30 AM",
    },
    {
      id: 3,
      task: "Wake Up",
      time: "6:30 AM",
    },
    {
      id: 4,
      task: "Wake Up",
      time: "6:30 AM",
    },
    {
      id: 5,
      task: "Wake Up",
      time: "6:30 AM",
    },
  ];
};

export const songs = () => {
  return [
    {
      id: 1,
      name: "Raj",
      songReference,
    },
    {
      id: 2,
      name: "Raj",
      songReference,
    },
    {
      id: 3,
      name: "Raj",
      songReference,
    },
    {
      id: 4,
      name: "Raj",
      songReference,
    },
    {
      id: 5,
      name: "Raj",
      songReference,
    },
  ];
};

export const taskOptions = () => {
  return [
    {
      id: 1,
      name: "Wake Up",
    },
    {
      id: 2,
      name: "Exercise",
    },
    {
      id: 3,
      name: "Breakfast",
    },
    {
      id: 4,
      name: "Lunch",
    },
    {
      id: 5,
      name: "Tea",
    },
    {
      id: 6,
      name: "Dinner",
    },
    {
      id: 7,
      name: "Medication",
    },
    {
      id: 8,
      name: "TV",
    },
    {
      id: 9,
      name: "Reading",
    },
    {
      id: 10,
      name: "Talk to family and friends",
    },
    {
      id: 11,
      name: "Career Visit",
    },
    {
      id: 12,
      name: "Walk",
    },
    {
      id: 13,
      name: "Nap",
    },
  ];
};

export const timeOptions = () => {
  return [
    {
      id: 1,
      name: "12:00 AM",
    },
    {
      id: 2,
      name: "12:30 AM",
    },
    {
      id: 3,
      name: "1:00 AM",
    },
    {
      id: 4,
      name: "1:30 AM",
    },
    {
      id: 5,
      name: "2:00 AM",
    },
    {
      id: 6,
      name: "2:30 AM",
    },
    {
      id: 7,
      name: "3:00 AM",
    },
    {
      id: 8,
      name: "3:30 AM",
    },
    {
      id: 9,
      name: "4:00 AM",
    },
    {
      id: 10,
      name: "4:30 AM",
    },
    {
      id: 11,
      name: "5:00 AM",
    },
    {
      id: 12,
      name: "5:30 AM",
    },
    {
      id: 13,
      name: "6:00 AM",
    },
    {
      id: 14,
      name: "6:30 AM",
    },
    {
      id: 15,
      name: "7:00 AM",
    },
    {
      id: 16,
      name: "7:30 AM",
    },
    {
      id: 17,
      name: "8:00 AM",
    },
    {
      id: 18,
      name: "8:30 AM",
    },
    {
      id: 19,
      name: "9:00 AM",
    },
    {
      id: 20,
      name: "9:30 AM",
    },
    {
      id: 21,
      name: "10:00 AM",
    },
    {
      id: 22,
      name: "10:30 AM",
    },
    {
      id: 23,
      name: "11:00 AM",
    },
    {
      id: 24,
      name: "11:30 AM",
    },
    {
      id: 25,
      name: "12:00 PM",
    },
    {
      id: 26,
      name: "12:30 PM",
    },
    {
      id: 27,
      name: "1:00 PM",
    },
    {
      id: 28,
      name: "1:30 PM",
    },
    {
      id: 29,
      name: "2:00 PM",
    },
    {
      id: 30,
      name: "2:30 PM",
    },
    {
      id: 31,
      name: "3:00 PM",
    },
    {
      id: 32,
      name: "3:30 PM",
    },
    {
      id: 33,
      name: "4:00 PM",
    },
    {
      id: 34,
      name: "4:30 PM",
    },
    {
      id: 35,
      name: "5:00 PM",
    },
    {
      id: 36,
      name: "5:30 PM",
    },
    {
      id: 37,
      name: "6:00 PM",
    },
    {
      id: 38,
      name: "6:30 PM",
    },
    {
      id: 39,
      name: "7:00 PM",
    },
    {
      id: 40,
      name: "7:30 PM",
    },
    {
      id: 41,
      name: "8:00 PM",
    },
    {
      id: 42,
      name: "8:30 PM",
    },
    {
      id: 43,
      name: "9:00 PM",
    },
    {
      id: 44,
      name: "9:30 PM",
    },
    {
      id: 45,
      name: "10:00 PM",
    },
    {
      id: 46,
      name: "10:30 PM",
    },
    {
      id: 47,
      name: "11:00 PM",
    },
    {
      id: 48,
      name: "11:30 PM",
    },
  ];
};

export const colorOptions = () => {
  return [
    {
      id: 1,
      name: "Red",
    },
    {
      id: 2,
      name: "Blue",
    },
    {
      id: 3,
      name: "Yellow",
    },
    {
      id: 4,
      name: "Green",
    },
    {
      id: 5,
      name: "Black",
    },
    {
      id: 6,
      name: "Purple",
    },
    {
      id: 7,
      name: "White",
    },
    {
      id: 8,
      name: "Gray",
    },
    {
      id: 9,
      name: "Brown",
    },
    {
      id: 10,
      name: "Pink",
    },
    {
      id: 11,
      name: "Orange",
    },
    {
      id: 12,
      name: "Dark Blue",
    },
  ];
};

export const sleepOptions = () => {
  let sleeps = [];
  for (let i = 0; i <= 24; i++) {
    sleeps.push({ id: i, name: i });
  }
  return sleeps;
};

export const exerciseOptions = () => {
  return [
    {
      id: 1,
      name: "Everyday",
    },
    {
      id: 2,
      name: "Weekly",
    },
    {
      id: 3,
      name: "Occasionally",
    },
    {
      id: 4,
      name: "Not at All",
    },
  ];
};

export const walkOptions = () => {
  return [
    {
      id: 1,
      name: "Everyday",
    },
    {
      id: 2,
      name: "Weekly",
    },
    {
      id: 3,
      name: "Occasionally",
    },
    {
      id: 4,
      name: "Not at All",
    },
  ];
};

export const mileOptions = () => {
  let miles = [];
  for (let i = 0; i <= 30; i++) {
    miles.push({ id: i, name: i });
  }
  return miles;
};

export const medicationOptions = () => {
  return [
    {
      id: 1,
      name: "Regularly",
    },
    {
      id: 2,
      name: "Do miss occasionally",
    },
    {
      id: 3,
      name: "Does not follow the Prescriptions",
    },
    {
      id: 4,
      name: "Forget to take Medication",
    },
    {
      id: 5,
      name: "Do not like to take Medication",
    },
  ];
};

export const hobbyOptions = () => {
  return [
    {
      id: 1,
      name: "Watching TV",
    },
    {
      id: 2,
      name: "Reading Books",
    },
    {
      id: 3,
      name: "Going to Clubs",
    },
    {
      id: 4,
      name: "Going to Pub",
    },
    {
      id: 5,
      name: "Meeting the Family",
    },
    {
      id: 6,
      name: "Pet Dogs",
    },
  ];
};

export const adminFilters = () => {
  return [
    {
      id: 1,
      name: "Username",
    },
  ];
};

export const frequencyOptions = () => {
  return [
    {
      id: 1,
      name: "Everyday",
    },
    {
      id: 2,
      name: "Specific day",
    },
  ];
};

export const startTimeOptions = () => {
  return [
    {
      id: 1,
      name: "00:00",
    },
    {
      id: 2,
      name: "00:15",
    },
    {
      id: 3,
      name: "00:30",
    },
  ];
};

export const countOptions = () => {
  let counts = [];
  for (let i = 1; i <= 24; i++) {
    counts.push({
      id: i,
      name: i,
    });
  }
  return counts;
};

export const remindMeOptions = () => {
  return [
    {
      id: 1,
      name: "0 Mins",
    },
    {
      id: 2,
      name: "15 Mins",
    },
    {
      id: 3,
      name: "30 Mins",
    },
  ];
};


export const userOptions = () => {
  return users().map((ele) => {
    return {
      id: ele.id,
      name: ele.email,
    };
  });
};

export const reportOptions = () => {
  return [
    {
      id: 1,
      name: "Today",
    },
    {
      id: 2,
      name: "Last Week",
    },
    {
      id: 3,
      name: "Last Month",
    },
    {
      id: 4,
      name: "Last Year",
    },
    {
      id: 5,
      name: "Datewise",
    },
  ];
};

export const categories = () => {
  return [
    {
      id: 1,
      name: "Attention",
      icon: "work",
      color: "primary",
      count: 0,
    },
    {
      id: 2,
      name: "Language",
      icon: "translate",
      color: "secondary",
      count: 0,
    },
    {
      id: 3,
      name: "Memory",
      icon: "folder-mydrive",
      color: "info",
      count: 0,
    },
    {
      id: 4,
      name: "Orientation",
      icon: "autorenew",
      color: "success",
      count: 0,
    },
    {
      id: 5,
      name: "Reaction Time",
      icon: "timer-sand",
      color: "warning",
      count: 0,
    },
    {
      id: 6,
      name: "Visual Memory",
      icon: "settings-input-svideo",
      color: "error",
      count: 0,
    },
  ];
};

export const longTermMemory = () => {
  return [
    {
      id: 1,
      name: "Episodic",
      icon: "memory",
      color: "primary",
      count: 10,
    },
    {
      id: 2,
      name: "Procedural",
      icon: "memory",
      color: "secondary",
      count: 5,
    },
    {
      id: 3,
      name: "Semantic",
      icon: "memory",
      color: "warning",
      count: 30,
    }
  ];
};

export const shortTermMemory = () => {
  return [
    {
      id: 1,
      name: "Working",
      icon: "memory",
      color: "primary",
      count: 20,
    },
    {
      id: 2,
      name: "Spatial",
      icon: "memory",
      color: "secondary",
      count: 10,
    }
  ];
};

export const lineChart = (label) => {
  return {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label, data: [50, 40, 300, 320, 500, 350, 200, 230, 500] },
  };
};

export const donutChart = () => {
  return {
    labels: ["Long Term Memory", "Short Term Memory"],
    datasets: {
      label: "Memories",
      backgroundColors: ["success", "info"],
      data: [15, 20],
    },
  };
};

export const licenceOptions = () => {
  return [
    {
      id: 1,
      name: "Licence Option 1",
    },
    {
      id: 2,
      name: "Licence Option 2",
    }
  ];
};