/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from "react"

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";

import axios from "axios"
import * as services from "redux/services";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/profile";

function BasicInfo(props) {
  // const {diagnosis, email, firstName, gender, lastName, qualifications, role, religion, stages } = profile?.userInfo
  const profile = useSelector((state) => state.profile);
  const auth = useSelector((state) => state.auth);


  const [form, setForm] = React.useState(profile?.userInfo)
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(actions.getProfileById(auth.user.email))
  // }, [dispatch]);

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value)
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    console.log('form', form)
    dispatch(actions.saveUserProfile(form))
  /*    try {
       console.log(form)
       const token = `Bearer ${services.getToken()}`;

       const req = await axios.post(`${process.env.REACT_APP_API}/Profile/SaveProfile`, form, {
         headers: {
           Authorization: token
         },
       })
       console.log(req);
       alert("Success")
     } catch (error) {
       console.log(error.message)
       alert(error.message)
     } */

  }

  console.log('form change', form)
  console.log('from basic info', profile)
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          {
            props.role !== props.actionTypes.PATIENT && <>
              <Grid item xs={12} sm={6}>
                <FormField name="orgName" label="Org Name" placeholder={props.orgName} value={profile?.userInfo?.firstName} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormField label="Role" placeholder={props.userName} value={profile?.userInfo?.role} />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormField
                  name="qualifications"
                  onChange={handleChange}
                  label="Qualifications"
                  placeholder={props.qualifications}
                  value={profile?.userInfo?.qualifications}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormField
                  name="email"
                  // onChange={handleChange}
                  label="Email"
                  value={profile?.userInfo?.email}
                  placeholder="example@gmail.com"
                  inputProps={{ type: "email" }}
                />
              </Grid>
            </>
          }


          {props.role === props.actionTypes.PATIENT && (
            <>
              <Grid item xs={12} sm={4}>
                <FormField label="Username" value={profile?.userInfo?.email} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField name="firstName" onChange={handleChange} label="First Name" defaultValue={profile?.userInfo?.firstName} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField name="lastName" onChange={handleChange} label="Last Name" defaultValue={profile?.userInfo?.lastName} />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      name="sex"
                      onChange={(e, value) => setForm({ ...form, gender: value })}
                      // defaultValue={props.sexList.map((e) => e.name)[0]}
                      value={profile?.userInfo?.gender}
                      options={props.sexList.map((e) => e.name)}
                      renderInput={(params) => (
                        <FormField  {...params} label="Sex" InputLabelProps={{ shrink: true }} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>

                    <FormField name="age" onChange={handleChange} defaultValue={profile?.userInfo?.age} label="Age" />

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      name="religion"
                      onChange={(e, value) => setForm({ ...form, religion: value })}
                      value={profile?.userInfo?.religion}
                      options={props.religionList.map((e) => e.name)}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label="Religion"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  name="diagnosis"
                  onChange={(e, value) => setForm({ ...form, diagnosis: value })}
                  value={profile?.userInfo?.diagnosis}
                  options={props?.diagnosisList?.map((e) => e.name)}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label="Diagnosis List"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  name="stages"
                  onChange={(e, value) => setForm({ ...form, stages: value })}
                  value={profile?.userInfo?.stages}
                  options={props.stagesList.map((e) => e.name)}
                  renderInput={(params) => (
                    <FormField defaultValue={profile?.userInfo?.stages} onChange={handleChange} {...params} label="Stages List" InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
            </>
          )}

          <MDBox ml="auto" mt={2}>
            <MDButton onClick={() => handleSubmit()} variant="gradient" color="dark" size="small">
              Update Profile
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
