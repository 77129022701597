import React, { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/profile";

function DoctorFilter() {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile);
    const auth = useSelector((state) => state.auth);
    const [form, setForm] = useState({})

    const handleChange = (e) => {
        let tempForm = {}
        tempForm[e.target.name] = e.target.value;
        setForm(tempForm)
    }

    const handleSearch = () => {
        dispatch(actions.advanceSearch_getOrgDoctor(form.email));
    }

    const handleReset = () => {
        dispatch(actions.getOrgDoctors());
    }


    return (
        <Card sx={{ height: "100%" }}>
            <MDBox display="flex">
                <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    variant="gradient"
                    bgColor="primary"
                    color="white"
                    shadow="md"
                    borderRadius="xl"
                    ml={3}
                    mt={-2}
                >
                    <Icon fontSize="medium" color="inherit">
                        search
                    </Icon>
                </MDBox>
                <MDBox>
                    <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                        Advanced Search
                    </MDTypography>
                </MDBox>
            </MDBox>

            <MDBox p={2} display="flex">
                <MDInput onChange={(e) => handleChange(e)} name="email" label="Enter email" style={{ width: 380 }} />
                <MDButton onClick={() => handleSearch()} style={{ marginLeft: 16 }} color="primary">{profile.searching ? "Searching" : "Search"}</MDButton>
                <MDButton onClick={() => handleReset()} style={{ marginLeft: 16 }} color="primary">Reset</MDButton>
            </MDBox>
        </Card>
    );
}

export default DoctorFilter;
