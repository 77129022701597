import React from "react";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

const MySelect = ({ label, options, name, value, handleSelect }) => {

  const handleChange = (e, value, reason) => {
    if (reason === "selectOption") {
      const selectedValue = options.find((ele) => ele.name === value);
      handleSelect(name, selectedValue);
    }
  };

  return (
    <MDBox>
      <MDBox display="inline-block">
        <MDTypography
          component="label"
          variant="caption"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
        >
          {label}
        </MDTypography>
      </MDBox>
      <Autocomplete
        name={name}
        value={value}
        options={options?.map((option) => option.name)}
        renderInput={(params) => <MDInput {...params} variant="standard" />}
        onChange={handleChange}
      />
    </MDBox>
  );
};

export default MySelect;
