/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
// import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import HorizontalBarChart from "./HorizontalBarChart";
import SalesTable from "examples/Tables/SalesTable";
import DataTable from "examples/Tables/DataTable";
import Loader from "components/Loader";

// Sales dashboard components
import ChannelsChart from "layouts/dashboards/sales/components/ChannelsChart";

// Data
import defaultLineChartData from "layouts/dashboards/sales/data/defaultLineChartData";
// import horizontalBarChartData from "layouts/dashboards/sales/data/horizontalBarChartData";
import salesTableData from "layouts/dashboards/sales/data/salesTableData";
import dataTableData from "layouts/dashboards/sales/data/dataTableData";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/dashboard";
import moment from "moment";

import Reports from "../../pages/dashboard/Reports"
import * as utils from "redux/utils";
import * as actionTypes from "redux/constants";
import InputFilters from "../../pages/dashboard/FiltersInput";


// let horizontalBarChartData = {
//   labels: ["Reminiscense", "Visual", "Decision-Making", "Daily tasks", "Spatial", "Languages"],
//   datasets: [
//     {
//       label: "Activity Level by Cognitive Modules",
//       color: "dark",
//       data: [15, 20, 12, 60, 20, 15],
//     },
//   ],
// };

const horizontalBarChartData = {
  labels: [
    "Reminiscence",
    "Language",
    "Visual_Tools",
    "Spatial_Time",
    "Decision_Making",
    "Daily_Tasks"
  ],
  datasets: [
    {
      label: "Activity Level by Cognitive Modules",
      color: "dark",
      data: [],
    },
  ],
};

function Sales() {

  const [changed, setChanged] = useState(false)
  const [userID, setuserID] = useState(undefined);
  const [report, setReport] = useState({ id: 3, name: "Last Month" });
  const [chartData, setChartData] = useState(horizontalBarChartData)
  const [topData, setTopData] = useState({})
  const [startDate, setStartDate] = useState(utils.parseDate(new Date()));
  const [endDate, setEndDate] = useState(utils.parseDate(new Date()));
  const [defaultDate, setDefaultData] = useState({
    fromDate: moment().subtract(30, "days").format("yyyy-MM-DD"),
    toDate: moment().format("yyyy-MM-DD"),
  })
  const [reportTimeLine, setreportTimeLine] = useState({
    fromDate: moment().format("yyyy-MM-DD"),
    toDate: moment().format("yyyy-MM-DD"),
  });

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const dashboard = useSelector((state) => state.dashboard);

  // console.log(dashboard)

  React.useEffect(() => {
    dispatch(actions.getPlayReports('23@gmail.com', defaultDate))
    dispatch(actions.getModuleReports('23@gmail.com', defaultDate))

  }, [dispatch])

  React.useEffect(() => {
    let tempTopData = {}
    const tempChartData = { ...horizontalBarChartData }
    // if (!dashboard?.moduleReport?.playReport?.length <= 0) {
    //   dashboard?.moduleReport?.playReport?.map((eachModule, i) => {
    //     if (eachModule.module !== "Meditation" && eachModule.module !== "Physical_Exercises" && eachModule.module !== "Behavioural") {
    //       if (!chartData.labels?.includes(eachModule.module)) {
    //         tempChartData.labels[i] = eachModule.module
    //         tempChartData.datasets[0].data[i] = eachModule.periodUserActivity
    //       }
    //     } else {
    //       tempTopData[eachModule.module] = eachModule.periodUserActivity
    //     }
    //   })
    //   setChartData(tempChartData)
    //   setTopData(tempTopData)
    // }

    // dashboard?.moduleReport?.playReport?.map((eachModule, i) => {
    //   if (horizontalBarChartData.labels.includes(eachModule)) {
    //     tempChartData.datasets[0].data[horizontalBarChartData.labels.indexOf(eachModule)] = eachModule.periodUserActivity
    //   }
    // })

    horizontalBarChartData.labels.map((eachLabel) => {
      const lvl = dashboard?.moduleReport?.playReport?.find((eachModule) => eachLabel === eachModule.module)
      tempChartData.datasets[0].data[horizontalBarChartData.labels.indexOf(eachLabel)] = lvl ? lvl.periodUserActivity : 0
    })
    setChartData(tempChartData)

    const topModule = dashboard?.moduleReport?.playReport?.filter((eachModule) => !horizontalBarChartData.labels.includes(eachModule.module))

    topModule?.map((eachModule) => {
      tempTopData[eachModule.module] = eachModule.periodUserActivity
    })
    setTopData(tempTopData)

  }, [dashboard])

  // console.log(dashboard)


  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] = useState("6 May - 7 May");
  const [customersDropdownValue, setCustomersDropdownValue] = useState("6 May - 7 May");
  const [revenueDropdownValue, setRevenueDropdownValue] = useState("6 May - 7 May");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);
  const [customersDropdown, setCustomersDropdown] = useState(null);
  const [revenueDropdown, setRevenueDropdown] = useState(null);

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) => setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({ currentTarget }) => setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({ currentTarget }) => setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );

  const handleReport = (name, value) => {
    console.log(value);
    setReport(value);
    reportTime(value.name.toLowerCase().trim());
  };

  const handleChange = (e) => {
    console.log(e.target.value, "DATES");
    const clone = { ...reportTimeLine };
    if (e.target.name === "startDate") {
      setStartDate(e.target.value);

      clone.fromDate = e.target.value;
      setreportTimeLine(clone);
    } else {
      clone.toDate = e.target.value;
      setEndDate(e.target.value);
      setreportTimeLine(clone);
      // dispatch(actions.getReports(userID, clone));
    }
  };

  const reportTime = (reportTime) => {
    switch (reportTime) {
      case "last year":
        return setreportTimeLine({
          fromDate: moment().subtract(365, "days").format("yyyy-MM-DD"),
          toDate: moment().format("yyyy-MM-DD"),
        });
      case "last month":
        return setreportTimeLine({
          fromDate: moment().subtract(30, "days").format("yyyy-MM-DD"),
          toDate: moment().format("yyyy-MM-DD"),
        });
      case "last week":
        return setreportTimeLine({
          fromDate: moment().subtract(7, "days").format("yyyy-MM-DD"),
          toDate: moment().format("yyyy-MM-DD"),
        });
      case "today":
        return setreportTimeLine({
          fromDate: moment().format("yyyy-MM-DD"),
          toDate: moment().format("yyyy-MM-DD"),
        });
      default:
        return "";
    }
  };

  const handleUserIDChange = (e) => {
    console.log(e.target.name, e.target.value)
    setuserID(e.target.value);
  };



  const handleSubmitReport = () => {

    if (auth.user.role === "EndUser") {
      console.log('auth.user.role ', auth.user.email);
      dispatch(actions.getPlayReports(auth?.user?.email, reportTimeLine))
      dispatch(actions.getModuleReports(auth?.user?.email, reportTimeLine))
    } else {
      dispatch(actions.getPlayReports(userID, reportTimeLine))
      dispatch(actions.getModuleReports(userID, reportTimeLine))
    }
    // setChanged(!changed)
  };

  if (dashboard.loading) {
    return <Loader />;
  } else {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <>
          {/* .    if (auth.user.role === "EndUser") { */}
          {actionTypes.ADVANCED_SECTION.includes(auth.user?.role) && (
            <Grid container>
              <Grid item xs={12} md={12}>
                <MDBox mt={3}>
                  <InputFilters
                    handleUserIDChange={handleUserIDChange}
                  />
                </MDBox>
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs={12} md={12}>
              <MDBox mt={4}>
                <Reports
                  dashboard={dashboard}
                  report={report}
                  handleReport={handleReport}
                  startDate={startDate}
                  endDate={endDate}
                  handleChange={handleChange}
                  handleSubmitReport={handleSubmitReport}
                />
              </MDBox>
            </Grid>
          </Grid>
        </>
        <MDBox py={3}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <DefaultStatisticsCard
                  title="Play Report"
                  count={dashboard?.playReport ? `${dashboard?.playReport?.playReport?.periodUserActivity}%` : '0%'}
                  percentage={{
                    color: "success",
                    // value: "+55%",
                    // label: "since last month",
                  }}
                /*  dropdown={{
                   action: openSalesDropdown,
                   menu: renderMenu(salesDropdown, closeSalesDropdown),
                   value: salesDropdownValue,
                 }} */
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DefaultStatisticsCard
                  title="Exercises Activity"
                  count={topData?.Physical_Exercises ? `${topData?.Physical_Exercises}%` : `0%`}
                  percentage={{
                    color: "success",
                    /* value: "+12%",
                    label: "since last month", */
                  }}
                /*  dropdown={{
                   action: openCustomersDropdown,
                   menu: renderMenu(customersDropdown, closeCustomersDropdown),
                   value: customersDropdownValue,
                 }} */
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DefaultStatisticsCard
                  title="Meditation Activity"
                  count={topData?.Meditation ? `${topData?.Meditation}%` : `0%`}
                  percentage={{
                    color: "secondary",
                    /* value: "+$213",
                    label: "since last month", */
                  }}
                /*  dropdown={{
                   action: openRevenueDropdown,
                   menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                   value: revenueDropdownValue,
                 }} */
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DefaultStatisticsCard
                  title="Behavioural Activity"
                  count={topData?.Behavioural ? `${topData?.Behavioural}%` : `0%`}
                  percentage={{
                    color: "secondary",
                    /* value: "+$213",
                    label: "since last month", */
                  }}
                /*  dropdown={{
                   action: openRevenueDropdown,
                   menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                   value: revenueDropdownValue,
                 }} */
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <HorizontalBarChart title="Activity by Modules" chart={chartData} />
            </Grid>
          </Grid>
        </MDBox>


        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <ChannelsChart />
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <DefaultLineChart
                title="Behavioural Trend"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="info" size="sm" badgeContent="" />
                      <MDBadgeDot color="dark" size="sm" badgeContent="" />
                    </MDBox>
                    <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                      <Tooltip title="See which ads perform better" placement="left" arrow>
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                }
                chart={defaultLineChartData}
              />
            </Grid>
          </Grid>

          {/*  <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  Top Selling Products
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                <DataTable
                  table={dataTableData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid> */}
        </MDBox>
      </DashboardLayout>
    );
  }
}

export default Sales;
