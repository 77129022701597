import * as services from "../services";
import * as data from "../data";
import * as utils from "../utils";
import * as familyApis from "../actions/family";
import * as eventApis from "../actions/event";
import * as itemApis from "../actions/item";

const colorNames = [
  "blue",
  "green",
  "orange",
  "yellow",
  "purple",
  "brown",
  "red",
  "maroon",
  "azure",
  "violet",
  "charcoal",
  "rose",
];

//--------------------------------------------------------- AUTHENTICATE MODULE ----------------------------------------------------------------
// Post Payloads
export const registerPayload = (form) => {
  return {
    FirstName: form.name,
    LastName: form?.lastName ? form.lastName : "",
    Email: form.email,
    Password: form.password,
  };
};

export const loginPayload = (UserName, Password) => {
  return {
    UserName,
    Password,
  };
};

// Get Payloads
export const userPayload = (data) => {
  return {
    token: data.token,
    userId: data.userId,
    email: data.email,
    userName: data.userName,
    role: data.role,
    customerId: data.customerId,
    subscriptionId: data.subscriptionId
  };
};

//--------------------------------------------------------- UPLOAD MODULE ----------------------------------------------------------------
// Post Payloads
export const familiesPayload = () => {
  return {
    User: {
      Email: "admin@cosmaonline.com",
    },
  };
};

export const familyPayload = ({ firstName, lastName, relationship, photo, base64String }) => {
  let formData = new FormData();
  formData.append("UserId", services.getUser());
  formData.append("FirstName", firstName);
  formData.append("LastName", lastName);
  formData.append("Relationship", relationship);
  if (photo) formData.append("formFiles", photo);
  formData.append("base64String", base64String)

  return formData;
};

export const eventPayload = ({ name, location, year, photo, base64String }) => {
  let formData = new FormData();
  formData.append("UserId", services.getUser());
  formData.append("Name", name);
  formData.append("Location", location);
  formData.append("Year", year);
  formData.append("formFiles", photo);
  formData.append("base64String", base64String)
  return formData;
};

export const itemPayload = ({ name, color, photo, base64String, colorName }) => {
  let formData = new FormData();
  formData.append("UserId", services.getUser());
  formData.append("Name", name);
  formData.append('ColorName', colorName)
  formData.append("ColourId", 1);
  formData.append("formFiles", photo);
  formData.append("base64String", base64String)
  return formData;
};

export const reminderPayload = ({
  medicationName,
  frequency,
  startDate,
  startTime,
  count,
  remindMeBefore,
}) => {
  return {
    MedicatonName: medicationName,
    Frequency: frequency,
    StartDate: startDate,
    StartTime: startTime,
    Often: 2,
    Repeat: count,
    RemindMeBefore: remindMeBefore.split(" ")[0],
    UserId: services.getUser(),
  };
};

export const behaviouralPayload = (form) => {
  return {
    UserId: services.getUser(),
    ...form,
  };
};

export const schedulePayload = ({ time, task }) => {
  return {
    UserId: services.getUser(),
    TimeModelId: time.id,
    TaskModelId: task.id,
  };
};

export const songPayload = ({ name, songReference, songLink }) => {
  let formData = new FormData();
  formData.append("UserId", services.getUser());
  formData.append("Name", name);
  formData.append("file", songReference);
  formData.append("SongLink", songLink);
  return formData;
};

// Get Payloads
export const getPagination = (data) => {
  return {
    page: data.page,
    pageSize: data.pageSize,
    pageCount: data.pageCount,
  };
};

export const getFamilyPhoto = async (photo) => {
  if (photo) {
    // console.log('photo', photo)
    return new Promise(async (resolve) => {
      // const arr = photo.split("/");
      // const photoId = arr[arr.length - 1];
      // const image = await familyApis.getFamilyImage(photoId);
      const image = photo
      resolve(image);
    });
  } else {
    return data.getPlaceHolder();
  }
};

export const getEventPhoto = async (photo) => {
  if (photo) {
    return new Promise(async (resolve) => {
      // const arr = photo.split("/");
      // const photoId = arr[arr.length - 1];
      // const image = await eventApis.getEventImage(photoId);
      const image = photo
      resolve(image);
    });
  } else {
    return data.getPlaceHolder();
  }
};

export const getItemPhoto = async (photo) => {
  if (photo) {
    return new Promise(async (resolve) => {
      // const arr = photo.split("/");
      // const photoId = arr[arr.length - 1];
      // const image = await itemApis.getItemImage(photoId);
      const image = photo
      resolve(image);
    });
  } else {
    return data.getPlaceHolder();
  }
};

export const getPhoto = () => {
  return data.getPlaceHolder();
};

export const getSong = () => {
  return data.getSongReference();
};

export const getFamiliesPayload = async (arr) => {
  // console.log(arr);
  let res = [];
  for (let i = 0; i < arr.length; i++) {
    const photo = await getFamilyPhoto(arr[i].photo);
    // const photo = arr[i].photo;
    res.push({
      ...arr[i],
      photo,
    });
  }
  return res;
};

export const getEventsPayload = async (arr) => {
  let res = [];
  for (let i = 0; i < arr.length; i++) {
    const photo = await getEventPhoto(arr[i].photo);
    res.push({
      ...arr[i],
      photo,
    });
  }
  return res;
};

export const getItemsPayload = async (arr) => {
  let res = [];
  for (let i = 0; i < arr.length; i++) {
    const photo = await getItemPhoto(arr[i].photo);
    res.push({
      ...arr[i],
      color: arr[i].colour?.name,
      photo,
    });
  }
  return res;
};

export const getRemindersPayload = (arr) => {
  return arr.reduce((res, ele) => {
    res.push({
      id: ele.id,
      title: ele.medicatonName,
      start: utils.parseDate(ele.startDate),
      end: utils.parseDate(ele.startDate),
      className: utils.getRandomClass(),
    });
    return res;
  }, []);
};

export const getBehaviouralsPayload = (arr) => {
  return arr.reduce((res, ele) => {
    res.push({
      ...ele,
    });
    return res;
  }, []);
};

export const getOptionsListPayload = (arr) => {
  return arr.reduce((res, ele, idx) => {
    res.push({
      id: idx + 1,
      name: ele.value,
    });
    return res;
  }, []);
};

export const getSchedulesPayload = (arr) => {
  return arr.reduce((res, ele) => {
    res.push({
      ...ele,
      time: ele.timeModel.time,
      task: ele.taskModel.name,
    });
    return res;
  }, []);
};

export const getSongsPayload = (arr) => {
  return arr.reduce((res, ele) => {
    res.push({
      ...ele,
      songReference: getSong(ele.songReference),
    });
    return res;
  }, []);
};

export const getColorOptionsListPayload = (arr) => {

  return arr.reduce((res, ele) => {
    res.push({
      id: ele.id,
      name: ele.name,
    });
    return res;
  }, []);
};

export const getTaskOptionsListPayload = (arr) => {
  return arr.reduce((res, ele) => {
    res.push({
      id: ele.id,
      name: ele.name,
    });
    return res;
  }, []);
};

//--------------------------------------------------------- DASHBOARD MODULE ----------------------------------------------------------------
// Get Payloads
export const getPieCharts = (arr) => {
  let labels = [];
  let series = [];
  let types = [];
  const myObj =
    arr &&
    arr.reduce((res, ele, idx) => {
      labels.push(`${ele.count}%`);
      series.push({
        value: Number(ele.count),
        className: `${colorNames[idx]}-pie`,
      });
      types.push({
        id: ele.id,
        name: ele.name,
        color: colorNames[idx],
      });
      res["labels"] = labels;
      res["series"] = series;
      res["types"] = types;
      return res;
    }, {});
  return {
    data: {
      labels: myObj.labels,
      series: myObj.series,
    },
    types: myObj.types,
  };
};

export const getLineCharts = (arr) => {
  let labels = ["06", "07", "08", "09", "10", "11", "12", "13", "14", "15"];
  let series = [];
  const myObj =
    arr &&
    arr.reduce((res, ele) => {
      const result = ele.count.reduce((res1, ele1, idx1) => {
        res1.push({
          value: Number(ele1),
          className: `${colorNames[idx1]}-pie`,
        });
        return res1;
      }, []);
      series.push(result);
      res["labels"] = labels;
      res["series"] = series;
      return res;
    }, {});
  return {
    data: {
      labels: myObj.labels,
      series: myObj.series,
    },
  };
};

//--------------------------------------------------------- PROFILE MODULE ----------------------------------------------------------------
// Get Payloads
const email = "admin@cosmaonline.com";

export const getProfile = (userName, orgName, diagnosisList, stagesList, sexList, religionList) => {
  return {
    userName,
    orgName,
    // diagnosisList: getOptionsListPayload(diagnosisList) || [],
    // stagesList: getOptionsListPayload(stagesList) || [],
    // sexList: getOptionsListPayload(sexList) || [],
    // religionList: getOptionsListPayload(religionList) || [],
  };
};

export const getProfiles = (data) => {
  return data;
};

export const getOtherProfiles = (data) => {
  const myData = data.find((e) => e.userName === email);
  if (myData) return myData
  else return data[0];
};

export const getAccounts = (data) => {
  const myData = data.find((e) => e.email === email);
  if (myData) return myData
  else return data[0];
};

export const getAllProfiles = (payload) => {
  const { orgs, doctors, patients, feedbacks } = payload
  return {
    orgs: orgs?.data,
    doctors: doctors?.data,
    patients: patients?.data,
    feedbacks: feedbacks?.data,
  };
};

export const getDates = () => {
  return {
    fromDate: "2022-06-07",
    toDate: "2022-06-10",
  };
};

export const getReports = (data) => {
  const icons = [
    "work",
    "translate",
    "folder-mydrive",
    "autorenew",
    "timer-send",
    "settings-input-svideo",
  ];
  const colors = ["primary", "secondary", "info", "success", "warning", "error"];

  const categories = data.reduce((res, ele, idx) => {
    const cats = ele[idx].mainCategoryList.map((res2, ele2) => {
      res2.push({
        id: ele2.category.categoryId,
        name: ele2.category.categoryName,
        icon: utils.getRandomValueFromArr(icons),
        color: utils.getRandomValueFromArr(colors),
        count: ele.averageScore.score.toFixed(2),
      });
      return res2;
    }, []);
    res = cats;
    return res;
  }, []);
  return {
    categories,
  };
};

//--------------------------------------------------------- USERS MODULE ----------------------------------------------------------------
export const usersPayload = (Name, Email) => {
  return {
    Name,
    Email
  }
}