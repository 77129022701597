import * as actionTypes from "../constants";
import * as apis from "../requests/apis";
import * as getPayload from "../requests/payloads";
import * as services from "../services";
import { clientRequest } from "../requests";
import { showToast } from "utils/toast";
import { TOAST_TYPE } from "utils/toast";

// Register
export const register = (form) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.REGISTER_LOADING });
    const data = getPayload.registerPayload(form);
    console.log(data);
    console.log(form);

    let res;
    if (form.type === "organisation")
      res = await clientRequest(
        actionTypes.POST,
        apis.ORG_REGISTER_API +
        `?First Name=${data.FirstName}&Last name=${data.LastName}&Email=${data.Email}&Password=${data.Password}`,
        data
      );
    else if (form.type === "doctor")
      res = await clientRequest(
        actionTypes.POST,
        apis.DOC_REGISTER_API + `?Email=${data.Email}&Password=${data.Password}&roleType=Doctor`,
        data
      );
    else if (form.type === "patient")
      res = await clientRequest(
        actionTypes.POST,
        apis.PATIENT_REGISTER_API +
        `?FirstName=${data.FirstName}&LastName=${data.LastName}&Email=${data.Email}&passWord=${data.Password}`,
        data
      );
    else
      res = await clientRequest(
        actionTypes.POST,
        apis.USER_REGISTER_API +
        `?Email=${data.Email}&Password=${data.Password}&First Name=${data.FirstName}&Last Name=${data.LastName}&name=REG`,
        data
      );
    console.log(res);
    const payload = getPayload.userPayload(res.data);
    services.setLoginUser(payload);
    dispatch({
      type: actionTypes.GET_USER_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_USER_FAIL,
      payload: err,
    });
  }
};

// Login
export const login = (form) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.LOGIN_LOADING });
    const data = getPayload.loginPayload(form.email, form.password);
    const res = await clientRequest(actionTypes.POST, apis.LOGIN_API, data);
    // console.log(res, "LOGIN test");
    const payload = getPayload.userPayload(res.data);
    services.setLoginUser(payload);
    // console.log(payload,"LOGIN")
    dispatch({
      type: actionTypes.GET_USER_SUCCESS,
      payload,
    });
    showToast("You are successfully loggedin.", TOAST_TYPE.success);
  } catch (err) {
    console.log(err.response.data);
    showToast(err.response.data, TOAST_TYPE.error);
    dispatch({
      type: actionTypes.GET_USER_FAIL,
      payload: err,
    });
  }
};

//forget password request
export const forgetPassword = (form) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FORGET_PASSWORD_LOADING });
    const res = await clientRequest(actionTypes.POST, apis.FORGET_PASSWORD_API + `?Email=${form.email}`);
    console.log(res)
    dispatch({
      type: actionTypes.FORGET_PASSWORD_REQ_SUCCESS,
    });
    showToast("You will receive an e-mail in maximum 60 seconds", TOAST_TYPE.success);
  } catch (err) {
    console.log(err);
    showToast(err.message, TOAST_TYPE.error);
    dispatch({
      type: actionTypes.FORGET_PASSWORD_REQ_FAIL,
      payload: err,
    });
  }
};


//RESET PASSWORD
export const resetPassword = (form) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.RESET_PASSWORD_LOADING });
    const res = await clientRequest(actionTypes.POST, apis.RESET_PASSWORD_API + `?Email=${form.email}&password=${form.password}`);
    dispatch({
      type: actionTypes.RESET_PASSWORD_SUCCESS,
    });
    showToast("Password changed successfully.", TOAST_TYPE.success);
  } catch (err) {
    console.log(err);
    showToast(err.message, TOAST_TYPE.error);
    dispatch({
      type: actionTypes.RESET_PASSWORD_FAIL,
      payload: err,
    });
  }
};

// Get User
export const getUser = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_USER });
    const user = services.getLoginUser();
    if (user.userId) {
      dispatch({
        type: actionTypes.GET_USER_SUCCESS,
        payload: user,
      });
    } else {
      dispatch({
        type: actionTypes.LOGOUT_SUCCESS,
      });
    }
  } catch (err) {
    dispatch({
      type: actionTypes.GET_USER_FAIL,
      payload: err,
    });
  }
};

// Logout
export const logout = () => async (dispatch) => {
  try {
    services.removeLoginUser();
    dispatch({
      type: actionTypes.GET_REPORT_CLEAR,
    });

    dispatch({
      type: actionTypes.LOGOUT_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.LOGOUT_FAIL,
      payload: err,
    });
  }
};

// Create Org
export const createOrg = (form) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CREATING_ORG });
    const data = getPayload.usersPayload(form.name, form.email);
    await clientRequest(actionTypes.POST, apis.CREATE_ORG_API, data);
    dispatch({
      type: actionTypes.CREATE_ORG_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.CREATE_ORG_FAIL,
      payload: err,
    });
  }
};

// Create Doctor
export const createDoctor = (form) => async (dispatch) => {
  console.log('console form action', form);
  // return
  try {
    dispatch({ type: actionTypes.CREATING_DOCTOR });
    const data = getPayload.usersPayload(form.name, form.email);
    await clientRequest(
      actionTypes.POST,
      apis.CREATE_DOCTOR_API +
      `?Name=${form?.name}&Email=${form?.email}&OrgEmail=${form?.GetEmail}`,
      data
    );
    showToast("doctor successfully added.", TOAST_TYPE.success);
    dispatch({
      type: actionTypes.CREATE_DOCTOR_SUCCESS,
      payload,
    });

  } catch (err) {
    showToast(err?.response?.data, TOAST_TYPE.success);
    dispatch({
      type: actionTypes.CREATE_DOCTOR_FAIL,
      payload: err,
    });

  }
};

// Create Patient
export const createPatient = (form) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CREATING_PATIENT });
    const data = getPayload.usersPayload(form.name, form.email);
    await clientRequest(
      actionTypes.POST,
      apis.CREATE_PATIENT_API +
      `?Name=${form?.name}&Email=${form?.email}&SubscriptionId=${form?.SubscriptionId}`,
      data
    );
    showToast("Patient successfully added.", TOAST_TYPE.success);
    dispatch({
      type: actionTypes.CREATE_PATIENT_SUCCESS,
      payload,
    });
  } catch (err) {
    showToast(err?.response?.data, TOAST_TYPE.success);
    dispatch({
      type: actionTypes.CREATE_PATIENT_FAIL,
      payload: err,
    });
  }
};
