import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

function Filters() {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="primary"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            search
          </Icon>
        </MDBox>
        <MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Advanced Search
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox p={2} display="flex">
        <MDInput label="Type here..." style={{ width: 380 }}/>
        <MDButton style={{ marginLeft: 16 }} color="primary">Search</MDButton>
      </MDBox>
    </Card>
  );
}

export default Filters;
