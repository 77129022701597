import Sales from "layouts/dashboards/sales";
// import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import AllProfiles from "layouts/pages/account/allProfiles";

// pages
import Register from "layouts/pages/auth/register";
import Login from "layouts/pages/auth/login";
import ForgetPassword from "layouts/pages/auth/forget-password";
import ResetPassword from "layouts/pages/auth/reset-password";

import Family from "layouts/pages/upload/family";
import FamilyForm from "layouts/pages/upload/family/FamilyForm";
import Event from "layouts/pages/upload/event";
import EventForm from "layouts/pages/upload/event/EventForm";
import Item from "layouts/pages/upload/item";
import ItemForm from "layouts/pages/upload/item/ItemForm";
import Reminder from "layouts/pages/upload/reminder";
import Behavioural from "layouts/pages/upload/behavioural";
import Schedule from "layouts/pages/upload/schedule";
import ScheduleForm from "layouts/pages/upload/schedule/ScheduleForm";
import Song from "layouts/pages/upload/song";
import SongForm from "layouts/pages/upload/song/SongForm";
import Dashboard from "layouts/pages/dashboard";
import Admin from "layouts/pages/admin";
import AdminForm from "layouts/pages/admin/AdminForm";
import Organisation from "layouts/pages/organisation";
import OrganisationForm from "layouts/pages/organisation/OrganisationForm";
import Doctor from "layouts/pages/doctor";
import DoctorForm from "layouts/pages/doctor/DoctorForm";
import Patient from "layouts/pages/patient";
import PatientForm from "layouts/pages/patient/PatientForm";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/illustrations/illustration-reset.jpg";
import BehaviouralForm from "layouts/pages/upload/behavioural/BehaviouralForm";
import FeedbackList from "layouts/pages/account/allProfiles/components/FeedbackList";
import DoctorList from "layouts/pages/account/allProfiles/components/DoctorList";
import OrganizationList from "layouts/pages/account/allProfiles/components/OrganizationList";
import PatientList from "layouts/pages/account/allProfiles/components/PatientList";
import UserProfile from "layouts/pages/account/allProfiles/components/UserProfile";

const routes = [
  {
    id: 1,
    type: "collapse",
    name: "User Profile",
    key: "auth",
    icon: <Icon fontSize="medium">grading</Icon>,
    /* icon: <MDAvatar src={profilePicture} alt="Kartheka" size="sm" />, */
    collapse: [
      {
        id: 2,
        name: "My Profile",
        key: "profile",
        route: "/auth/profile",
        component: <Settings />,
      },
      {
        id: 3,
        name: "Settings",
        key: "settings",
        route: "/auth/profile",
        component: <Settings />,
      },
      {
        id: 4,
        name: "Logout",
        key: "logout",
        route: "/auth/login",
        component: <Login />,
      },
      {
        id: 48,
        name: "Forget password",
        key: "forget-password",
        hidden: true,
        route: "/auth/forget-password",
        component: <ForgetPassword />,
      },
      {
        id: 49,
        name: "Reset password",
        key: "reset-password",
        hidden: true,
        route: "/auth/reset-password",
        component: <ResetPassword />,
      },
      {
        id: 34,
        name: "Register",
        key: "register",
        route: "/auth/register",
        hidden: true,
        component: <Register />,
      },
    ],
  },
  { id: 41, type: "divider", key: "divider-0", collapse: [] },
  {
    id: 35,
    type: "collapse",
    name: "All Profile",
    key: "list",
    icon: <Icon fontSize="medium">grading</Icon>,
    /* icon: <MDAvatar src={profilePicture} alt="Kartheka" size="sm" />, */
    collapse: [
      {
        id: 36,
        name: "Admin list",
        key: "doctors",
        route: "/list/doctors",
        component: <DoctorList />,
      },
      {
        id: 37,
        name: "User list",
        key: "patients",
        route: "/list/patients",
        component: <PatientList />,
      },
      {
        id: 38,
        name: "User Profile",
        key: "profiles",
        route: "/list/profiles",
        component: <UserProfile />,
      }
      , {
        id: 39,
        name: "Organization list",
        key: "organizations",
        route: "/list/organizations",
        component: <OrganizationList />,
      },
      {
        id: 40,
        name: "FeedBack list",
        key: "feedbacks",
        route: "/list/feedbacks",
        component: <FeedbackList />,
      },
    ],
  },
  { id: 5, type: "divider", key: "divider-0", collapse: [] },
  {
    id: 6,
    type: "collapse",
    name: "Dashboards",
    key: "pages",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        id: 7,
        name: "Score Board",
        key: "Cognitive Report",
        route: "/pages/dashboard",
        component: <Dashboard />,
      },
      {
        id: 8,
        name: "Behavioural Report",
        key: "Behavioural Report",
        route: "/pages/dashboard-2",
        component: <Sales />,
      },
    ],
  },
  { id: 9, type: "divider", key: "divider-0", collapse: [] },
  /* { id: 9, type: "title", title: "Pages", key: "title-pages", collapse: [] }, */
  {
    id: 10,
    type: "collapse",
    name: "Game Upload",
    key: "upload",
    icon: <Icon fontSize="medium">upload</Icon>,
    collapse: [
      { id: 11, name: "Family", key: "family", route: "/upload/family", component: <Family /> },
      { id: 12, name: "Event", key: "event", route: "/upload/event", component: <Event /> },
      { id: 13, name: "Item", key: "item", route: "/upload/item", component: <Item /> },
      {
        id: 16,
        name: "Schedule",
        key: "schedule",
        route: "/upload/schedule",
        component: <Schedule />,
      },
      { id: 17, name: "Song", key: "song", route: "/upload/song", component: <Song /> },

      {
        id: 18,
        hidden: true,
        name: "Add Family",
        key: "add-family",
        route: "/upload/family/add-family",
        component: <FamilyForm />,
      },
      {
        id: 19,
        hidden: true,
        name: "Add Event",
        key: "add-event",
        route: "/upload/event/add-event",
        component: <EventForm />,
      },
      {
        id: 20,
        hidden: true,
        name: "Add Item",
        key: "add-item",
        route: "/upload/item/add-item",
        component: <ItemForm />,
      },

      {
        id: 21,
        hidden: true,
        name: "Add Schedule",
        key: "add-schedule",
        route: "/upload/schedule/add-schedule",
        component: <ScheduleForm />,
      },
      {
        id: 22,
        hidden: true,
        name: "Add Song",
        key: "add-song",
        route: "/upload/song/add-song",
        component: <SongForm />,
      },
    ],
  },
  { id: 29, type: "divider", key: "divider-0", collapse: [] },
  {
    id: 28,
    type: "collapse",
    name: "Other Upload",
    key: "upload",
    icon: <Icon fontSize="medium">upload</Icon>,
    collapse: [
      {
        id: 14,
        name: "Reminder",
        key: "reminder",
        route: "/upload/reminder",
        component: <Reminder />,
      },
      {
        id: 15,
        name: "Behavioural",
        key: "behavioural",
        route: "/upload/behavioural",
        component: <Behavioural />,
      },
      {
        id: 32,
        hidden: true,
        name: "Add Behavioural",
        key: "add-behavioural",
        route: "/upload/behavioural/add-behavioural",
        component: <BehaviouralForm />,
      },
    ],
  },
  //{ id: 30, type: "divider", key: "divider-0", collapse: [] },
  {
    id: 23,
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="medium">people</Icon>,
    collapse: [
      // { id: 24, name: "Admin", key: "admin", route: "/users/admin", component: <AdminForm /> },
      {
        id: 25,
        name: "Organisation",
        key: "organisation",
        route: "/users/organisation",
        component: <OrganisationForm />,
      },
      { id: 26, name: "Admin", key: "doctor", route: "/users/doctor", component: <DoctorForm /> },
      { id: 27, name: "User", key: "patient", route: "/users/patient", component: <PatientForm /> },
      // {
      //   id: 28,
      //   hidden: true,
      //   name: "Add Admin",
      //   key: "add-admin",
      //   route: "/users/admin/add-admin",
      //   component: <AdminForm />,
      // },
      // {
      //   id: 29,
      //   hidden: true,
      //   name: "Add Organisation",
      //   key: "add-organisation",
      //   route: "/users/organisation/add-organisation",
      //   component: <OrganisationForm />,
      // },
      // {
      //   id: 30,
      //   hidden: true,
      //   name: "Add Doctor",
      //   key: "add-doctor",
      //   route: "/users/doctor/add-doctor",
      //   component: <DoctorForm />,
      // },
      // {
      //   id: 31,
      //   hidden: true,
      //   name: "Add Patient",
      //   key: "add-patient",
      //   route: "/users/patient/add-patient",
      //   component: <PatientForm />,
      // },
    ],
  },
];

export default routes;
