import * as actionTypes from "../constants";

const initialState = {
  loading: false,
  error: null,
  user: null,
  isAuth: false,
  loginLoading: false,
  registerLoading: false,
  creatingUser: false,
  forgetPassLoading: false,
  resetPasswordLoading: false
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_USER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FORGET_PASSWORD_LOADING:
    case actionTypes.RESET_PASSWORD_LOADING:
      return {
        ...state,
        resetPasswordLoading: true,
      };
    case actionTypes.FORGET_PASSWORD_REQ_SUCCESS:
    case actionTypes.FORGET_PASSWORD_REQ_FAIL:
    case actionTypes.RESET_PASSWORD_SUCCESS:
    case actionTypes.RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPasswordLoading: false
      }
    case actionTypes.CREATING_ORG:
    case actionTypes.CREATING_DOCTOR:
    case actionTypes.CREATING_PATIENT:
      return {
        ...state,
        creatingUser: true,
      };

    case actionTypes.CREATE_ORG_SUCCESS:
    case actionTypes.CREATE_DOCTOR_SUCCESS:
    case actionTypes.CREATE_PATIENT_SUCCESS:
    case actionTypes.CREATE_ORG_FAIL:
    case actionTypes.CREATE_DOCTOR_FAIL:
    case actionTypes.CREATE_PATIENT_FAIL:
      return {
        ...state,
        creatingUser: false,
      };

    case actionTypes.REGISTER_LOADING:
      return {
        ...state,
        registerLoading: true,
      };

    case actionTypes.LOGIN_LOADING:
      return {
        ...state,
        loginLoading: true,
      };

    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loginLoading: false,
        registerLoading: false,
        user: payload,
        isAuth: true,
      };

    case actionTypes.GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        loginLoading: false,
        registerLoading: false,
        user: null,
        isAuth: false,
        error: payload,
      };

    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        loginLoading: false,
        registerLoading: false,
        user: null,
        isAuth: false,
      };

    default:
      return state;
  }
}
