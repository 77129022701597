import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";


// react-router-dom components
import { Link } from "react-router-dom";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// local
import Radio from "./Radio";

// redux
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/auth";

const emptyForm = {
  name: "",
  lastName: '',
  email: "",
  password: "",
  confirmPassword: "",
  type: "organisation",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [showPassword, setShowPassword] = useState(false)


  const dispatch = useDispatch();
  const { registerLoading } = useSelector((state) => state.auth);

  const [form, setForm] = useState(emptyForm);

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  console.log(form);

  const handleSubmit = () => {
    console.log(form, "test");
    if (form.password === form.confirmPassword) {
      dispatch(actions.register(form));
    } else {
      alert('Password not matched.')
    }
  };

  const handleSetPassVisible = () => setShowPassword(!showPassword)


  const handleTabChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  const handleTab = (val) => {
    const clone = { ...form };
    clone.type = val;
    setForm(clone);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab onClick={() => handleTab("user")} label="Single User" {...a11yProps(0)} />
          <Tab label="Organisation" onClick={() => handleTab("organisation")} {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Name"
              name="name"
              value={form.name}
              onChange={handleChange}
              fullWidth
            />
          </MDBox>
       {/*    <MDBox mb={2}>
            <MDInput
              type="text"
              label="Last Name"
              name="LastName"
              value={form.lastName}
              onChange={handleChange}
              fullWidth
            />
          </MDBox> */}
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              name="email"
              value={form.email}
              onChange={handleChange}
              fullWidth
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type={!showPassword ? "password" : "text"}
              label="Password"
              name="password"
              value={form.password}
              onChange={handleChange}
              fullWidth
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type={!showPassword ? "password" : "text"}
              label="Confirm Password"
              name="confirmPassword"
              value={form.confirmPassword}
              onChange={handleChange}
              fullWidth
            />
          </MDBox>
          <MDBox display="flex" alignItems="center" ml={-1}>
            <Switch checked={showPassword} onChange={handleSetPassVisible} />
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              onClick={handleSetPassVisible}
              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
            >
              &nbsp;&nbsp;Show Password
            </MDTypography>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="primary" fullWidth onClick={handleSubmit}>
              {registerLoading ? "Registering..." : "Register"}
            </MDButton>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Already have an account?{" "}
              <MDTypography
                component={Link}
                to="/auth/login"
                variant="button"
                color="primary"
                fontWeight="medium"
                textGradient
              >
                Login
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <Radio name="type" value={form.type} onChange={handleChange} />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Name"
              name="name"
              value={form.name}
              onChange={handleChange}
              fullWidth
            />
          </MDBox>
         {/*  <MDBox mb={2}>
            <MDInput
              type="text"
              label="Last Name"
              name="LastName"
              value={form.lastName}
              onChange={handleChange}
              fullWidth
            />
          </MDBox> */}
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              name="email"
              value={form.email}
              onChange={handleChange}
              fullWidth
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type={!showPassword ? "password" : "text"}
              label="Password"
              name="password"
              value={form.password}
              onChange={handleChange}
              fullWidth
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type={!showPassword ? "password" : "text"}
              label="Confirm Password"
              name="confirmPassword"
              value={form.confirmPassword}
              onChange={handleChange}
              fullWidth
            />
          </MDBox>
          <MDBox display="flex" alignItems="center" ml={-1}>
            <Switch checked={showPassword} onChange={handleSetPassVisible} />
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              onClick={handleSetPassVisible}
              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
            >
              &nbsp;&nbsp;Show Password
            </MDTypography>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="primary" fullWidth onClick={handleSubmit}>
              {registerLoading ? "Registering..." : "Register"}
            </MDButton>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Already have an account?{" "}
              <MDTypography
                component={Link}
                to="/auth/login"
                variant="button"
                color="primary"
                fontWeight="medium"
                textGradient
              >
                Login
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </TabPanel>
    </Box>
  );
}
