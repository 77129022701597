import React from "react";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Loader from "components/Loader";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// local
import SongContainer from "./SongContainer";
import Filters from "./Filters";

// redux
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/song";
import * as actionTypes from "redux/constants";

const Song = () => {
  const dispatch = useDispatch();
  const song = useSelector((state) => state.song);
  const auth = useSelector((state) => state.auth);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [song.pagination.page]);

  React.useEffect(() => {
    dispatch(actions.getSongs(song.pagination.page, song.pagination.pageSize));

    return () => {
      dispatch(actions.resetSongsPagination());
    };
  }, [dispatch]);

  const handlePagination = (page) => {
    dispatch(actions.getSongs(page, song.pagination.pageSize));
  };

  const handleDelete = (id) => {
    const payload = { id };
    dispatch(actions.deleteSong(payload));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        {song.loading ? (
          <Loader />
        ) : (
          <>
            {actionTypes.ADVANCED_SECTION.includes(auth.user?.role) && (
              <Grid item xs={12} md={12} xl={12}>
                <MDBox py={3} pl={1}>
                { auth.user.role ==="SuperAdmin" &&
                    <Filters />
                }
                </MDBox>
              </Grid>
            )}

<Grid item xs={12} md={12} xl={12}>
          <MDBox py={3} pl={1}>
                {            
              <div class="card bg-gradient-default">
                <div class="card-body">
                <h5 class="font-weight-normal text-info text-gradient">Instruction</h5>
                <blockquote class="blockquote text-white mb-0">
                  <p class="text-dark ms-3">You will upload at least 4 of your favourite songs in MP3 format or Youtube link.
Press ‘Add song’. Press ‘Choose file’ to select a song from your device or copy the youtube url of the song in song link. Please do not choose both options.
</p>
                </blockquote>
              </div>
            </div>
                }
          </MDBox>
        </Grid>

            <Grid item xs={12} md={12} xl={12}>
              <MDBox py={3} pr={1}>
                <SongContainer
                  songs={song.songs}
                  pagination={song.pagination}
                  handlePagination={handlePagination}
                  handleDelete={handleDelete}
                />
              </MDBox>
            </Grid>
          </>
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default Song;
