/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Setting pages components
import TableCell from "layouts/pages/account/settings/components/TableCell";
import FormField from "layouts/pages/account/components/FormField";

import React from "react";
import axios from "axios";
import * as services from "redux/services";


function Notifications({ accounts, handleFeedback }) {

  const [form, setForm] = React.useState({
    subject: "",
    message: "",
  })

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  const handleSubmit = async () => {
    handleFeedback(form.subject, form.message)
    // try {
    //   const token = `Bearer ${services.getToken()}`;

    //   const req = await axios.post(`${process.env.REACT_APP_API}/BugReport/SaveBugReport`, form, {
    //     headers: {
    //       Authorization: token
    //     },
    //   })
    //   alert("Success")
    // } catch (error) {
    //   console.log(error.message)
    //   alert(error.message)
    // }
  }

  return (
    <Card id="notifications">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Feedbacks</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Choose how you receive feedbacks. These feedback settings apply to the things you’re
          watching.
        </MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <MDBox>
          <FormField name="subject" onChange={handleChange} label="Subject" />
        </MDBox>
        <label style={{ fontSize: 12, color: "gray" }}>Message</label>
        <MDBox mt={1}>
          <textarea
            name="message"
            onChange={handleChange}
            rows={5}
            style={{
              borderColor: "#DDD",
              width: "100%",
              padding: 8,
              fontSize: 16,
              fontFamily: "inherit",
            }}
          />
        </MDBox>
        <MDBox ml="auto" mt={2}>
          <MDButton onClick={() => handleSubmit()} variant="gradient" color="dark" size="small">
            Submit
          </MDButton>
        </MDBox>
      </MDBox>
      {/* <MDBox pb={3} px={3}>
        <MDBox minWidth="auto" sx={{ overflow: "scroll" }}>
          <Table sx={{ minWidth: "36rem" }}>
            <MDBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Activity
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  Email
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  Push
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  SMS
                </TableCell>
              </TableRow>
            </MDBox>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Mentions
                    </MDTypography>
                    <MDTypography variant="caption" color="text" fontWeight="regular">
                      Notify when another user mentions you in a comment
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Comments
                    </MDTypography>
                    <MDTypography variant="caption" color="text" fontWeight="regular">
                      Notify when another user comments your item.
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Follows
                    </MDTypography>
                    <MDTypography variant="caption" color="text" fontWeight="regular">
                      Notify when another user follows you.
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]} noBorder>
                  <MDTypography display="block" variant="button" color="text">
                    Log in from a new device
                  </MDTypography>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                  <Switch defaultChecked />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </MDBox>
      </MDBox> */}
    </Card>
  );
}

export default Notifications;
