import React from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Loader from "components/Loader";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Select from "components/Select";
import Radio from "components/Radio";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// redux
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/behavioural";

const emptyForm = {
  breakfastIntakeTime: "",
  lunchIntakeTime: "",
  dinnerIntakeTime: "",
  otherIntakeTime: "",
  breakfast1: "",
  breakfast2: "",
  breakfast3: "",
  lunch1: "",
  lunch2: "",
  lunch3: "",
  dinner1: "",
  dinner2: "",
  dinner3: "",
  other: "",
  sleepTime: "",
  sleepDuration: "",
  sleeper: "",
  exercise: "",
  walk: "",
  miles: "",
  medication: "",
  hobbies: "",
};

const BehaviouralForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const behavioural = useSelector((state) => state.behavioural);

  const [form, setForm] = React.useState(emptyForm);

  React.useEffect(() => {
    dispatch(actions.getBehaviouralOptions());
  }, [dispatch]);

  React.useEffect(() => {
    if (behavioural.behaviouralSaved) navigate(`/upload/behavioural`);
  }, [behavioural, navigate]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSelect = (name, value) => {
    setForm({ ...form, [name]: value.name });
  };

  const handleSubmit = () => {
    dispatch(actions.addBehavioural(form));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        {behavioural.loading ? (
          <Loader />
        ) : (
          <>
            <Grid item xs={12} md={12} xl={12}>
              <MDBox py={3} pr={1}>
                <Card sx={{ width: "100%" }}>
                  <MDBox display="flex">
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      variant="gradient"
                      bgColor="primary"
                      color="white"
                      shadow="md"
                      borderRadius="xl"
                      ml={3}
                      mt={-2}
                    >
                      <Icon fontSize="medium" color="inherit">
                        add
                      </Icon>
                    </MDBox>
                    <MDBox>
                      <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                        Add Behavioural
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <Grid container>
                    <Grid item xs={12}>
                      <Card>
                        <MDBox p={3}>
                          <MDBox mt={1} mb={2}>
                            <MDTypography variant="subtitle2" fontWeight="bold">
                              Food Intake Time
                            </MDTypography>
                          </MDBox>

                          {/* Breakfast Intake Time */}
                          <MDBox mt={1}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Select
                                  label="Breakfast"
                                  options={behavioural.timeOptions}
                                  name="breakfastIntakeTime"
                                  value={form.breakfastIntakeTime}
                                  handleSelect={handleSelect}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Select
                                  label="Lunch"
                                  options={behavioural.timeOptions}
                                  name="lunchIntakeTime"
                                  value={form.lunchIntakeTime}
                                  handleSelect={handleSelect}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox mt={1}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Select
                                  label="Dinner"
                                  options={behavioural.timeOptions}
                                  name="dinnerIntakeTime"
                                  value={form.dinnerIntakeTime}
                                  handleSelect={handleSelect}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Select
                                  label="Other"
                                  options={behavioural.timeOptions}
                                  name="otherIntakeTime"
                                  value={form.otherIntakeTime}
                                  handleSelect={handleSelect}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>

                          <MDBox mt={4} mb={2}>
                            <MDTypography variant="subtitle2" fontWeight="bold">
                              Breakfast
                            </MDTypography>
                          </MDBox>

                          {/* Breakfast */}
                          <MDBox mt={1}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={4}>
                                <FormField
                                  type="text"
                                  label="Breakfast 1"
                                  name="breakfast1"
                                  value={form.breakfast1}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <FormField
                                  type="text"
                                  label="Breakfast 2"
                                  name="breakfast2"
                                  value={form.breakfast2}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <FormField
                                  type="text"
                                  label="Breakfast 3"
                                  name="breakfast3"
                                  value={form.breakfast3}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>

                          <MDBox mt={4} mb={2}>
                            <MDTypography variant="subtitle2" fontWeight="bold">
                              Lunch
                            </MDTypography>
                          </MDBox>

                          {/* Lunch */}
                          <MDBox mt={1}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={4}>
                                <FormField
                                  type="text"
                                  label="Lunch 1"
                                  name="lunch1"
                                  value={form.lunch1}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <FormField
                                  type="text"
                                  label="Lunch 2"
                                  name="lunch2"
                                  value={form.lunch2}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <FormField
                                  type="text"
                                  label="Lunch 3"
                                  name="lunch3"
                                  value={form.lunch3}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>

                          <MDBox mt={4} mb={2}>
                            <MDTypography variant="subtitle2" fontWeight="bold">
                              Dinner
                            </MDTypography>
                          </MDBox>

                          {/* Dinner */}
                          <MDBox mt={1}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={4}>
                                <FormField
                                  type="text"
                                  label="Dinner 1"
                                  name="dinner1"
                                  value={form.dinner1}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <FormField
                                  type="text"
                                  label="Dinner 2"
                                  name="dinner2"
                                  value={form.dinner2}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <FormField
                                  type="text"
                                  label="Dinner 3"
                                  name="dinner3"
                                  value={form.dinner3}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>

                          {/* Other */}
                          <MDBox mt={1}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12}>
                                <FormField
                                  type="text"
                                  label="Other"
                                  name="other"
                                  value={form.other}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>

                          <MDBox mt={4} mb={2}>
                            <MDTypography variant="subtitle2" fontWeight="bold">
                              Sleep Details
                            </MDTypography>
                          </MDBox>

                          {/* Sleep */}
                          <MDBox mt={1}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Select
                                  label="Sleep Time"
                                  options={behavioural.timeOptions}
                                  name="sleepTime"
                                  value={form.sleepTime}
                                  handleSelect={handleSelect}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Select
                                  label="Sleep Duration"
                                  options={behavioural.sleepOptions}
                                  name="sleepDuration"
                                  value={form.sleepDuration}
                                  handleSelect={handleSelect}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>

                          <MDBox mt={4} mb={2}>
                            <MDTypography variant="subtitle2" fontWeight="bold">
                              Sound Sleeper
                            </MDTypography>
                          </MDBox>

                          {/* Sound Sleeper */}
                          <MDBox mt={1}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Radio
                                  name="sleeper"
                                  value={form.sleeper}
                                  handleChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}></Grid>
                            </Grid>
                          </MDBox>

                          <MDBox mt={4} mb={2}>
                            <MDTypography variant="subtitle2" fontWeight="bold">
                              Other Details
                            </MDTypography>
                          </MDBox>

                          {/* Other */}
                          <MDBox mt={1}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={4}>
                                <Select
                                  label="Exercise"
                                  options={behavioural.exerciseOptions}
                                  name="exercise"
                                  value={form.exercise}
                                  handleSelect={handleSelect}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Select
                                  label="Walk"
                                  options={behavioural.walkOptions}
                                  name="walk"
                                  value={form.walk}
                                  handleSelect={handleSelect}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Select
                                  label="Miles"
                                  options={behavioural.mileOptions}
                                  name="miles"
                                  value={form.miles}
                                  handleSelect={handleSelect}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>

                          <MDBox mt={1}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Select
                                  label="Medication"
                                  options={behavioural.medicationOptions}
                                  name="medication"
                                  value={form.medication}
                                  handleSelect={handleSelect}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Select
                                  label="Hobbies"
                                  options={behavioural.hobbyOptions}
                                  name="hobbies"
                                  value={form.hobbies}
                                  handleSelect={handleSelect}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>

                          <MDBox mt={12}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12}>
                                <MDBox mt={1} display="flex" justifyContent="end">
                                  <MDButton
                                    color="primary"
                                    onClick={!behavioural.savingBehavioural && handleSubmit}
                                  >
                                    {behavioural.savingBehavioural ? "Submitting..." : "Submit"}
                                  </MDButton>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </Grid>
                  </Grid>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} xl={4}></Grid>
          </>
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default BehaviouralForm;
