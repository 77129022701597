import React from "react";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Loader from "components/Loader";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// local
import ScheduleContainer from "./ScheduleContainer";
import Filters from "./Filters";

// redux
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/schedule";
import * as actionTypes from "redux/constants";

const Schedule = () => {
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.schedule);
  const auth = useSelector((state) => state.auth);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [schedule.pagination.page]);

  React.useEffect(() => {
    dispatch(actions.getSchedules(schedule.pagination.page, schedule.pagination.pageSize));

    return () => {
      dispatch(actions.resetSchedulePagination());
    };
  }, [dispatch]);

  const handlePagination = (page) => {
    dispatch(actions.getSchedules(page, schedule.pagination.pageSize));
  };

  const handleDelete = (id) => {
    const payload = { id };
    dispatch(actions.deleteSchedule(payload));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        {schedule.loading ? (
          <Loader />
        ) : (
          <>
            {actionTypes.ADVANCED_SECTION.includes(auth.user?.role) && (
              <Grid item xs={12} md={12} xl={12}>
                <MDBox py={3} pl={1}>
                { auth.user.role ==="SuperAdmin" &&
                    <Filters />
                }
                </MDBox>
              </Grid>
            )}

<Grid item xs={12} md={12} xl={12}>
          <MDBox py={3} pl={1}>
                {            
              <div class="card bg-gradient-default">
                <div class="card-body">
                <h5 class="font-weight-normal text-info text-gradient">Instruction</h5>
                <blockquote class="blockquote text-white mb-0">
                  <p class="text-dark ms-3">
                  In this section you will are required to create your own personal daily routine schedule that takes place from the morning that you wake up until you got to sleep. You select ‘Add New Schedule’. Then you select the daily routine task in the ‘Task’ section and the time it occurs in the ‘Time’ section.
You cannot have two events to take place at the same time, nor can they be repeated.</p>
                </blockquote>
              </div>
            </div>
                }
          </MDBox>
        </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <MDBox py={3} pr={1}>
                <ScheduleContainer
                  schedules={schedule.schedules}
                  pagination={schedule.pagination}
                  handlePagination={handlePagination}
                  handleDelete={handleDelete}
                />
              </MDBox>
            </Grid>
          </>
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default Schedule;
