import React from "react";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Loader from "components/Loader";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// local
import FamilyContainer from "./FamilyContainer";
import Filters from "./Filters";

// redux
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/family";
import * as actionTypes from "redux/constants";

const Family = () => {
  const dispatch = useDispatch();
  const family = useSelector((state) => state.family);
  const auth = useSelector((state) => state.auth);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [family.pagination.page]);

  React.useEffect(() => {
    dispatch(actions.getFamilies(family.pagination.page, family.pagination.pageSize));

    return () => {
      dispatch(actions.resetFamilyPagination());
    }
  }, [dispatch]);

  const handlePagination = (page) => {
    dispatch(actions.getFamilies(page, family.pagination.pageSize));
  }

  const handleDelete = (id) => {
    const payload = { id };
    dispatch(actions.deleteFamily(payload));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        {family.loading ? (
          <Loader />
        ) : (
          <>
            {actionTypes.ADVANCED_SECTION.includes(auth.user?.role) && (
              <Grid item xs={12} md={12} xl={12}>
                <MDBox py={3} pl={1}>
                { auth.user.role ==="SuperAdmin" &&
                    <Filters />
                }
                </MDBox>
              </Grid>
            )}

       <Grid item xs={12} md={12} xl={12}>
          <MDBox py={3} pl={1}>
                {            
              <div class="card bg-gradient-default">
                <div class="card-body">
                <h5 class="font-weight-normal text-info text-gradient">Instruction</h5>
                <blockquote class="blockquote text-white mb-0">
                  <p class="text-dark ms-3">
You will upload at least 10 pictures of your family members, caregivers and other
people significant in your life. You upload the picture by choosing ‘Add Family’. You will be asked to write their first and last name, what is your relationship to them and to choose a file from your personal storage e.g. your computer to upload.
You should not give the same relationship status in two different pictures (e.g. use son1, son2 or friend1 etc if the relationship is same). Choose pictures where you have the person's face shown clearly.</p>
                </blockquote>
              </div>
            </div>
                }
          </MDBox>
        </Grid>
 
            <Grid item xs={12} md={12} xl={12}>
              <MDBox py={3} pr={1}>
                <FamilyContainer families={family.families} pagination={family.pagination} handlePagination={handlePagination} handleDelete={handleDelete} />
              </MDBox>
            </Grid>
          </>
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default Family;
