import React from "react";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Loader from "components/Loader";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// local
import BehaviouralContainer from "./BehaviouralContainer";
import Filters from "./Filters";

// redux
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/behavioural";
import * as actionTypes from "redux/constants";

const Behavioural = () => {
  const dispatch = useDispatch();
  const behavioural = useSelector((state) => state.behavioural);
  const auth = useSelector((state) => state.auth);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [behavioural.pagination.page]);

  React.useEffect(() => {
    dispatch(actions.getBehaviourals(behavioural.pagination.page, behavioural.pagination.pageSize));

    return () => {
    //   dispatch(actions.resetFamilyPagination());
    }
  }, [dispatch]);

  const handlePagination = (page) => {
    dispatch(actions.getBehaviourals(page, behavioural.pagination.pageSize));
  }

  const handleDelete = (id) => {
    const payload = { id };
    dispatch(actions.deleteBehavioural(payload));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        {behavioural.loading ? (
          <Loader />
        ) : (
          <>
            {actionTypes.ADVANCED_SECTION.includes(auth.user?.role) && (
              <Grid item xs={12} md={12} xl={12}>
                <MDBox py={3} pl={1}>
                  {auth.user.role === "SuperAdmin" &&
                    <Filters />
                  }
                </MDBox>
              </Grid>
            )}

<Grid item xs={12} md={12} xl={12}>
          <MDBox py={3} pl={1}>
                {            
              <div class="card bg-gradient-default">
                <div class="card-body">
                <h5 class="font-weight-normal text-info text-gradient">Instruction</h5>
                <blockquote class="blockquote text-white mb-0">
                  <p class="text-dark ms-3">Here, you provide information about what time you take all of your daily meals and what you eat; how many hours do you sleep and the quality of your sleep, your walking habits, medication intake and hobbies. Press ‘Submit’.
You can always amend your choices by using 'Add Behavioural' and deleting the old form. This info will help us provide you the behavioural report you see in the dashboard. </p>
                </blockquote>
              </div>
            </div>
                }
          </MDBox>
        </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <MDBox py={3} pr={1}>
                <BehaviouralContainer behaviourals={behavioural.behaviourals} pagination={behavioural.pagination} handlePagination={handlePagination} handleDelete={handleDelete} />
              </MDBox>
            </Grid>
          </>
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default Behavioural;
