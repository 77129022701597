import React from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// common
import Pagination from "layouts/pages/upload/common/Pagination";

// local
import BehaviouralCard from "./BehaviouralCard";

const BehaviouralContainer = ({ behaviourals, pagination, handlePagination, handleDelete }) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="primary"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            watch
          </Icon>
        </MDBox>
        <MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            List Of Behaviourals
            <MDButton
              color="primary"
              className="absolute-r-16"
              onClick={() => navigate(`/upload/behavioural/add-behavioural`)}
            >
              + Add Behavioural
            </MDButton>
          </MDTypography>
        </MDBox>
      </MDBox>
      <Grid container>
        {behaviourals &&
          behaviourals.map((ele, idx) => (
            <Grid key={idx} item xs={12} md={12} lg={12}>
              <MDBox p={2} pb={behaviourals.length - 1 === idx ? 2 : 0} m={0}>
                <BehaviouralCard
                  no={`${pagination.page * pagination.pageSize - pagination.pageSize + (idx + 1)}.`}
                  id={ele.id}
                  behavioural={ele}
                  handleDelete={handleDelete}
                />
              </MDBox>
            </Grid>
          ))}
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Pagination
            pageCount={pagination.pageCount}
            page={pagination.page}
            handleClick={handlePagination}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default BehaviouralContainer;
