import React from "react";
import { v4 as uuidv4 } from "uuid";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Loader from "components/Loader";

// layouts
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// redux
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/auth";
import * as services from "redux/services";
import * as actions2 from "redux/actions/profile";

const DoctorForm = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.profile);

  const emptyForm = {
    id: uuidv4(),
    name: "",
    email: "",
    GetEmail: auth?.user?.email,
  };
  const [form, setForm] = React.useState(emptyForm);
  const [dataChanged, setDataChanged] = React.useState(false)

  React.useEffect(() => {
    if (!auth.creatingUser) setForm(emptyForm);
  }, [auth.creatingUser]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    emptyForm;
    // fetching doctor list
    if (auth.user) {
      const { role, email, subscriptionId, userName } = auth.user
      if (role === 'SuperAdmin') {
        dispatch(actions2.getAllDoctors())
      } else {
        dispatch(actions2.getAllDoctors(email))
      }
    }
  }, [dispatch, auth.user, dataChanged]);

  const handleSubmit = () => {
    const clone = { ...form };
    clone.GetEmail = auth?.user?.email;
    console.log(clone);
    dispatch(actions.createDoctor(clone));
    setDataChanged(!dataChanged)
  };

  const handleDelete = (email) => {
    dispatch(actions2.deleteAccount(email))
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item xs={12} md={12} xl={12}>
          <MDBox py={3} pr={1}>
            <Card sx={{ width: "100%" }}>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="primary"
                  color="white"
                  shadow="md"
                  borderRadius="xl"
                  ml={3}
                  mt={-2}
                >
                  <Icon fontSize="medium" color="inherit">
                    add
                  </Icon>
                </MDBox>
                <MDBox>
                  <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                    Add Admin
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Grid container>
                <Grid item xs={12}>
                  <Card>
                    <MDBox p={3}>
                      <MDBox mt={1}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            <FormField
                              type="text"
                              label="Name"
                              name="name"
                              value={form.name}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mt={1}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <FormField
                              type="text"
                              label="Email"
                              name="email"
                              value={form.email}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mt={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}></Grid>
                          <Grid item xs={12} sm={6}>
                            <MDBox display="flex" justifyContent="end">
                              <MDButton color="primary" onClick={handleSubmit}>
                                {auth.creatingUser ? "Submitting..." : "Submit"}
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </MDBox>
          <Card id="basic-info" >
            <MDBox p={3}>
              <div id="cosmo-profile">
                {
                  profile.loading ? <p>Loading Data ....</p> : <>
                    <table>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Registration Status</th>
                        <th>Actions</th>
                      </tr>

                      {profile.allDoctors.length > 0 &&
                        profile.allDoctors.map((ele, idx) => (
                          <tr key={idx}>
                            <td>{ele.firstName} {ele.lastName}</td>
                            <td>{ele.email}</td>
                            <td>{ele.registrationStatus ? 'true' : 'false'}</td>
                            <td>
                              <MDBox>
                                <Grid container>
                                  <Grid item xs={12} sm={12}>
                                    <MDBox display="flex" justifyContent="center">
                                      <MDButton color="primary" onClick={() => handleDelete(ele.email)}>
                                        {"Delete"}
                                      </MDButton>
                                    </MDBox>
                                  </Grid>
                                </Grid>
                              </MDBox>
                            </td>
                          </tr>
                        ))}

                    </table>
                    {profile.allDoctors.length <= 0 &&
                      <div className="text-center p-5">
                        <p>No doctor found</p>
                      </div>
                    }
                  </>
                }

              </div>
            </MDBox>
          </Card>

        </Grid>
        <Grid item xs={12} md={4} xl={4}>
        </Grid>
      </Grid>

    </DashboardLayout >
  );
};

export default DoctorForm;
