/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Loader from "components/Loader";

// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import Header from "layouts/pages/account/settings/components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
//import Authentication from "layouts/pages/account/settings/components/Authentication";
import Accounts from "layouts/pages/account/settings/components/Accounts";
import Notifications from "layouts/pages/account/settings/components/Notifications";
// import Sessions from "layouts/pages/account/settings/components/Sessions";
import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";

// redux
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/profile";
import * as actions2 from "redux/actions/auth";
import * as actionTypes from "redux/constants";
import axios from "axios"

function Settings() {
  const [profileInfo, setProfileInfo] = React.useState({})
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const auth = useSelector((state) => state.auth);

  const [dropDown, setDropDown] = React.useState({
    sexList: [
      {
        "id": 0,
        "name": ""
      },
      {
        "id": 1,
        "name": "Male"
      },
      {
        "id": 2,
        "name": "Female"
      },
      {
        "id": 3,
        "name": "Prefer not to say"
      }
    ],
    religionList: [
      {
        "id": 0,
        "name": ""
      },
      {
        "id": 1,
        "name": "Christianity"
      },
      {
        "id": 2,
        "name": "Islam"
      },
      {
        "id": 3,
        "name": "Hinduism"
      },
      {
        "id": 4,
        "name": "Buddhism"
      },
      {
        "id": 5,
        "name": "Judaism"
      },
      {
        "id": 6,
        "name": "Others"
      },
      {
        "id": 7,
        "name": "No Belief"
      },
      {
        "id": 8,
        "name": "Prefer Not to Say"
      }
    ],
    stagesList: [
      {
        "id": 0,
        "name": ""
      },
      {
        "id": 1,
        "name": "Early"
      },
      {
        "id": 2,
        "name": "Mild"
      },
      {
        "id": 3,
        "name": "Moderate"
      },
      {
        "id": 4,
        "name": "Severe"
      }
    ],
    diagnosisList: [
      {
        "id": 0,
        "name": ""
      },
      {
        "id": 1,
        "name": "MCI"
      },
      {
        "id": 2,
        "name": "Undiagnosed Dementia symptoms"
      },
      {
        "id": 3,
        "name": "Alzheimer's"
      },
      {
        "id": 4,
        "name": "Vascular Dementia"
      },
      {
        "id": 5,
        "name": "Mixed Dementia"
      },
      {
        "id": 6,
        "name": "FrontoTemporal Dementia"
      },
      {
        "id": 7,
        "name": "Parkinson's Disease"
      },
      {
        "id": 8,
        "name": "Younger-Onset Dementia"
      }
    ]
  })

  useEffect(() => {
    dispatch(actions.getProfile());
    dispatch(actions.getProfileById(auth?.user?.email))
    // dispatch(actions.getProfiles());
    dispatch(actions.getOtherProfiles());
    dispatch(actions.getAccounts());
  }, [dispatch]);

  // console.log('test', auth)

  const handleDelete = () => {
    console.log(auth.user)
    if (auth.user) {
      dispatch(actions.deleteAccount(auth.user.email))
      dispatch(actions2.logout());
    }
  }

  const handleChangePassword = (oldPassword, newPassword) => {
    dispatch(actions.changePassword({
      Email: auth.user.email,
      OldPassword: oldPassword,
      NewPassword: newPassword
    }))
  }

  const handleFeedback = (subject, message) => {
    dispatch(actions.submitBugReport({
      subject,
      message,
      userId: auth.user.userId
    }))
  }

  if (profile.loading) {
    return <Loader />;
  } else {
    // return <div>TEST</div>
    return (
      <BaseLayout>
        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Sidenav />
            </Grid>
            <Grid item xs={12} lg={9}>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Header userName={auth?.user?.userName} />
                  </Grid>
                  <Grid item xs={12}>
                    <BasicInfo
                      profileInfo={profile?.userInfo}
                      // orgName={profileInfo?.firstName}
                      // qualifications={profileInfo?.qualifications}
                      // email={profileInfo?.email}
                      // userName={profile.profileInfo?.userName}
                      sexList={dropDown.sexList}
                      religionList={dropDown.religionList}
                      diagnosisList={dropDown.diagnosisList}
                      stagesList={dropDown.stagesList}
                      role={auth?.user?.role}
                      actionTypes={actionTypes}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ChangePassword accounts={auth} handleChangePassword={handleChangePassword} />
                  </Grid>
                  <Grid item xs={12}>
                    <Accounts
                      accounts={profile?.accounts}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Notifications
                      accounts={profile?.accounts}
                      handleFeedback={handleFeedback}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Sessions />
                  </Grid> */}
                  <Grid item xs={12}>
                    <DeleteAccount profileInfo={profile?.accounts} handleDelete={handleDelete} />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </BaseLayout>
    );
  }
}

export default Settings;
