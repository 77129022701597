import * as actionTypes from "../constants";
import * as apis from "../requests/apis";
import * as getPayload from "../requests/payloads";
import * as services from "../services";
import { clientRequest } from "../requests";
import { showToast } from "utils/toast";
import { TOAST_TYPE } from "utils/toast";

// Get Profile
export const getProfile = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_PROFILE });

    const userName = await clientRequest(actionTypes.GET, apis.GET_USERNAME_API);
    const orgName = await clientRequest(actionTypes.GET, apis.GET_ORG_API);
    // const diagnosis = await clientRequest(actionTypes.GET, apis.GET_DIAGNOSIS_API);
    // const stagesList = await clientRequest(actionTypes.GET, apis.GET_STAGES_LIST_API);
    // const sexList = await clientRequest(actionTypes.GET, apis.GET_SEX_LIST_API);
    // const religionList = await clientRequest(actionTypes.GET, apis.GET_RELIGION_LIST_API);
    const payload = getPayload.getProfile(
      userName.data,
      orgName.data,
      // diagnosis.data,
      // stagesList.data,
      // sexList.data,
      // religionList.data
    );
    dispatch({
      type: actionTypes.GET_PROFILE_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_PROFILE_FAIL,
      payload: err,
    });
  }
};

//Get profile by id
export const getProfileById = (email) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_USERINFO });
    const userInfo = await clientRequest(actionTypes.GET, apis.GET_PROFILE_BY_ID_API + `?Email=${email}`,)
    dispatch({
      type: actionTypes.GET_USERINFO_SUCCESS,
      payload: userInfo.data[0]
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_USERINFO_FAIL,
      payload: error
    })
  }
}

//save profile
export const saveUserProfile = (info) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATE_USERINFO })
    const updateInfo = await clientRequest(actionTypes.POST, apis.UPDATE_USERINFO_API, info)
    dispatch({
      type: actionTypes.UPDATE_USERINFO_SUCCESS,
      payload: updateInfo.data
    })
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_USERINFO_FAIL,
      payload: error
    })
  }
}

export const getProfiles = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_PROFILES });
    const profiles = await clientRequest(actionTypes.GET, apis.GET_PROFILES_API);
    const payload = getPayload.getProfiles(profiles.data);
    dispatch({
      type: actionTypes.GET_PROFILES_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_PROFILES_FAIL,
      payload: err,
    });
  }
};

export const getOtherProfiles = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_OTHER_PROFILES });
    const otherProfiles = await clientRequest(actionTypes.GET, apis.GET_OTHER_PROFILES_API);
    const payload = getPayload.getOtherProfiles(otherProfiles.data);
    dispatch({
      type: actionTypes.GET_OTHER_PROFILES_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_OTHER_PROFILES_FAIL,
      payload: err,
    });
  }
};

export const getAccounts = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_ACCOUNTS });
    const accounts = await clientRequest(actionTypes.GET, apis.GET_ACCOUNTS_API);
    const payload = getPayload.getAccounts(accounts.data);
    // console.log('get accounts', accounts);
    dispatch({
      type: actionTypes.GET_ACCOUNTS_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_ACCOUNTS_FAIL,
      payload: err,
    });
  }
};

export const getAllProfiles = (email) => async (dispatch) => {
  try {
    console.log('console form get all profile', email)
    dispatch({ type: actionTypes.FETCH_ALL_PROFILES });
    const doctors = await clientRequest(actionTypes.GET, apis.GET_DOCTORS_API + `?Email=${email ? email : ""}`);
    const patients = await clientRequest(actionTypes.GET, apis.GET_PATIENTS_API) + `?Email=${email ? email : ""}`;
    const feedbacks = await clientRequest(actionTypes.GET, apis.GET_FEEDBACKS_API + `?Email=${email ? email : ""}`);
    const orgs = await clientRequest(actionTypes.GET, apis.GET_ORGS_API + `?Email=${email ? email : ""}`);
    const payload = getPayload.getAllProfiles(doctors, patients, feedbacks, orgs,);
    dispatch({
      type: actionTypes.GET_ALL_PROFILES_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_ALL_PROFILES_FAIL,
      payload: err,
    });
  }
};
export const getAllDoctors = (email) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_All_DOCTORS });
    const doctors = await clientRequest(actionTypes.GET, apis.GET_DOCTORS_API + `?Email=${email ? email : ""}`);
    const payload = doctors.data
    dispatch({
      type: actionTypes.GET_All_DOCTORS_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_All_DOCTORS_FAIL,
      payload: err,
    });
  }
};
export const getAllPatients = (email) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_All_PATIENTS });
    const patients = await clientRequest(actionTypes.GET, apis.GET_PATIENTS_API + `?Email=${email ? email : ""}`);
    const payload = patients.data
    dispatch({
      type: actionTypes.GET_All_PATIENTS_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_All_PATIENTS_FAIL,
      payload: err,
    });
  }
};

export const getOrgDoctors = (email) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_ALL_PROFILES });
    const doctors = await clientRequest(actionTypes.GET, apis.GET_ORG_DOCTOR_API + `?Email=${email ? email : ""}`);
    const payload = getPayload.getAllProfiles({ doctors });
    dispatch({
      type: actionTypes.GET_ALL_PROFILES_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_ALL_PROFILES_FAIL,
      payload: err,
    });
  }
}
export const getPatients = (email) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_ALL_PROFILES });
    const patients = await clientRequest(actionTypes.GET, apis.GET_PATIENTS_API + `?Email=${email ? email : ""}`);
    const payload = getPayload.getAllProfiles({ patients });
    dispatch({
      type: actionTypes.GET_ALL_PROFILES_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_ALL_PROFILES_FAIL,
      payload: err,
    });
  }
}
export const getFeedBack = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_ALL_PROFILES });
    const feedbacks = await clientRequest(actionTypes.GET, apis.GET_FEEDBACKS_API);
    const payload = getPayload.getAllProfiles({ feedbacks });
    dispatch({
      type: actionTypes.GET_ALL_PROFILES_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_ALL_PROFILES_FAIL,
      payload: err,
    });
  }
}
export const getOrgs = (email) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_ALL_PROFILES });
    const orgs = await clientRequest(actionTypes.GET, apis.GET_ORG_LIST_API + `?Email=${email ? email : ""}`);
    console.log('orgs', orgs)
    const payload = getPayload.getAllProfiles({ orgs });
    dispatch({
      type: actionTypes.GET_ALL_PROFILES_SUCCESS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_ALL_PROFILES_FAIL,
      payload: err,
    });
  }
}
export const getAllProfilesById = (email, role) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_PROFILES_BY_ID });
    const allProfiles = await clientRequest(actionTypes.GET, apis.GET_PROFILES_BY_ID_API + `?Email=${email ? email : ""}&Role=${role ? role : ""}`);
    // console.log('orgs', orgs)
    // const payload = getPayload.getAllProfiles({ orgs });
    dispatch({
      type: actionTypes.GET_PROFILES_BY_ID_SUCCESS,
      payload: allProfiles?.data,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_PROFILES_BY_ID_FAIL,
      payload: err,
    });
  }
}

export const advanceSearch_getOrgDoctor = (email) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.ADVANCE_SEARCH });
    const doctors = await clientRequest(actionTypes.GET, apis.GET_ORG_DOCTOR_API + `?Email=${email}`);
    // const payload = getPayload.getAllProfiles({ doctors });
    dispatch({
      type: actionTypes.ADVANCE_SEARCH_SUCCESS,
      payload: {
        orgs: [],
        doctors: doctors.data,
        patients: [],
        feedbacks: []
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.ADVANCE_SEARCH_FAIL,
      payload: err,
    });
  }
}

export const advanceSearch_getPatient = (email) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.ADVANCE_SEARCH });
    const patients = await clientRequest(actionTypes.GET, apis.GET_PATIENTS_API + `?Email=${email}`);
    // const payload = getPayload.getAllProfiles({ doctors });
    dispatch({
      type: actionTypes.ADVANCE_SEARCH_SUCCESS,
      payload: {
        orgs: [],
        doctors: [],
        patients: patients.data,
        feedbacks: []
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.ADVANCE_SEARCH_FAIL,
      payload: err,
    });
  }
}

export const advanceSearch_getProfilesById = (email) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.ADVANCE_SEARCH });
    const profiles = await clientRequest(actionTypes.GET, apis.GET_PROFILES_BY_ID_API + `?Email=${email}`);
    // const payload = getPayload.getAllProfiles({ doctors });
    dispatch({
      type: actionTypes.GET_PROFILES_BY_ID_SUCCESS,
      payload: profiles.data
    });
  } catch (err) {
    dispatch({
      type: actionTypes.ADVANCE_SEARCH_FAIL,
      payload: err,
    });
  }
}

export const advanceSearch_getOrs = (email) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.ADVANCE_SEARCH });
    const orgs = await clientRequest(actionTypes.GET, apis.GET_ORG_LIST_API + `?Email=${email}`);
    // const payload = getPayload.getAllProfiles({ doctors });
    dispatch({
      type: actionTypes.ADVANCE_SEARCH_SUCCESS,
      payload: {
        orgs: orgs.data,
        doctors: [],
        patients: [],
        feedbacks: []
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.ADVANCE_SEARCH_FAIL,
      payload: err,
    });
  }
}

//change password 
export const changePassword = ({ Email, OldPassword, NewPassword }) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CHANGE_PASSWORD })
    const info = await clientRequest(actionTypes.POST, apis.CHANGE_PASSWORD_API, { Email, OldPassword, NewPassword })
    dispatch({
      type: actionTypes.CHANGE_PASSWORD_SUCCESS,
      payload: info
    })
  } catch (error) {
    dispatch({
      type: actionTypes.CHANGE_PASSWORD_FAIL,
      payload: error
    })
  }
}

//bug report
export const submitBugReport = ({ subject, message, userId }) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.NEW_FEEDBACK })
    const submitBug = await clientRequest(actionTypes.POST, apis.ADD_FEEDBACK_API, {
      subject,
      message,
      userId,
      dateReported: new Date().toISOString(),
      dateReportUlpoaded: new Date().toISOString(),
    })
    console.log(submitBug)
    dispatch({
      type: actionTypes.NEW_FEEDBACK_SUCCESS,
      payload: submitBug
    })
  } catch (error) {
    dispatch({
      type: actionTypes.NEW_FEEDBACK_FAIL,
      payload: error
    })
  }
}

export const deleteAccount = (email) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETE_ACCOUNT_REQUEST });
    const res = await clientRequest(actionTypes.POST, apis.DELETE_ACCOUNT_API + `?Email=${email}`);
    console.log('delete api', res.data)
    dispatch({
      type: actionTypes.DELETE_ACCOUNT_SUCCESS,
      payload,
    });
    showToast("Profile deleted successfully.", TOAST_TYPE.success);

  } catch (err) {
    dispatch({
      type: actionTypes.DELETE_ACCOUNT_FAIL,
      payload: err,
    });
  }
};
