import * as actionTypes from "../constants";
import * as data from "../data";

const initialState = {
  loading: true,
  error: null,
  reminders: [],
  frequencyOptions: [],
  startTimeOptions: [],
  countOptions: data.countOptions(),
  remindMeOptions: data.remindMeOptions(),
  savingReminder: false,
  reminderSaved: false,
  deletingReminder: false,
  reminderDeleted: false,
};

export default function reminder(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_REMINDERS:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_REMINDERS_SUCCESS:
      return {
        ...state,
        error: null,
        frequencyOptions: payload.frequencyOptions,
        startTimeOptions: payload.startTimeOptions,
        reminders: payload.reminders,
        loading: false,
        savingReminder: false,
        reminderSaved: false,
        deletingReminder: false,
        reminderDeleted: false,
      };

    case actionTypes.SAVING_REMINDER:
      return {
        ...state,
        savingReminder: true,
      };

    case actionTypes.ADD_REMINDER_SUCCESS: {
      return {
        ...state,
        savingReminder: false,
        reminderSaved: true,
      };
    }

    case actionTypes.EDIT_REMINDER_SUCCESS: {
      let lastestReminders = state.reminders.map((ele) => (ele.id === payload.id ? payload : ele));
      return {
        ...state,
        reminders: lastestReminders,
      };
    }

    case actionTypes.DELETING_REMINDER:
      return {
        ...state,
        deletingReminder: true,
      };

    case actionTypes.DELETE_REMINDER_SUCCESS: {
      let lastestReminders = state.reminders.filter((ele) => ele.id !== payload.id);
      return {
        ...state,
        reminders: lastestReminders,
        reminderDeleted: true,
      };
    }

    case actionTypes.GET_REMINDERS_FAIL:
    case actionTypes.ADD_REMINDER_FAIL:
    case actionTypes.EDIT_REMINDER_FAIL:
    case actionTypes.DELETE_REMINDER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        savingReminder: false,
        reminderSaved: false,
        deletingReminder: false,
        reminderDeleted: false,
      };

    default:
      return state;
  }
}
