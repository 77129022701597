import { Link } from 'react-router-dom';

export default function Button(props) {
    return (
        <a href={props.to}>
            <span className={`csCustomBtn me-3` + ' ' + props.Class}>
                {props.BtnContent}
            </span>
        </a>
    )
}