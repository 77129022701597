import { combineReducers } from "redux";

import searcher from "./searcher";
import auth from "./auth";
import family from "./family";
import event from "./event";
import item from "./item";
import reminder from "./reminder";
import behavioural from "./behavioural";
import schedule from "./schedule";
import song from "./song";
import dashboard from "./dashboard";
import profile from "./profile";

export default combineReducers({
  searcher,
  auth,
  family,
  event,
  item,
  reminder,
  behavioural,
  schedule,
  song,
  dashboard,
  profile
});
