import * as actionTypes from "../constants";
import * as apis from "../requests/apis";
import * as payloads from "../requests/payloads";
import * as utils from "../utils";
import * as data from "../data";
import { clientRequest, contentHeader, clientImageRequest } from "../requests";

// Get Events
export const getEvents = (page, pageSize, reset = false) => async (dispatch) => {
  try {
    if(reset) dispatch({ type: actionTypes.RESET_EVENT });
    else dispatch({ type: actionTypes.FETCH_EVENTS });
    const res = await clientRequest(
      actionTypes.GET,
      `${apis.GET_EVENTS_API}?page=${page}&pageSize=${pageSize}`,
      null
    );
    const data = await payloads.getEventsPayload(res.data.eventList);
    const pagination = payloads.getPagination(res.data);
console.log('event list',data)
    dispatch({
      type: actionTypes.GET_EVENTS_SUCCESS,
      payload: {
        data,
        pagination,
      },
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_EVENTS_FAIL,
      payload: err,
    });
  }
};

// Add Event
export const addEvent = (form) => async (dispatch) => {
  try {
    // console.log('event action',form)
    dispatch({ type: actionTypes.SAVING_EVENT });
    const payload = payloads.eventPayload(form);
    const result = await clientRequest(actionTypes.POST, apis.ADD_EVENT_API, payload, contentHeader);
    // console.log(result)
    setTimeout(() => {
      dispatch({
        type: actionTypes.ADD_EVENT_SUCCESS,
      });
    }, 2000);
  } catch (err) {
    dispatch({
      type: actionTypes.ADD_EVENT_FAIL,
      payload: err,
    });
  }
};

// Edit Event
export const editEvent = (form) => async (dispatch) => {
  try {
    // const res = await clientRequest(actionTypes.GET, apis.GET_EVENTS_API, null);

    dispatch({
      type: actionTypes.EDIT_EVENT_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.EDIT_EVENT_FAIL,
      payload: err,
    });
  }
};

// Delete Event
export const deleteEvent = (form) => async (dispatch) => {
  try {
    await clientRequest(actionTypes.POST, `${apis.DELETE_EVENT_API}?Id=${form.id}`, null);
    dispatch({
      type: actionTypes.DELETE_EVENT_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.DELETE_EVENT_FAIL,
      payload: err,
    });
  }
};

// Reset Events Pagination
export const resetEventsPagination = () => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.RESET_EVENT_PAGINATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.RESET_EVENT_PAGINATION_FAIL,
    });
  }
};

// Get Event Image 
export const getEventImage = async (photoId) => {
  try{
    const result = await clientImageRequest(actionTypes.GET, `${apis.GET_EVENT_IMAGE_API}?id=${photoId}`, null, null, 'arraybuffer');
    if(result && result.data) return utils.imageEncode(result.data);
    else return data.getPlaceHolder();
  }catch(err){
    return data.getPlaceHolder();
  }
}

// Get Advance Search
export const getAdvanceSearchEvent = (username) => async (dispatch) => {
  try{
    dispatch({ type: actionTypes.ADVANCE_SEARCH_REQUEST_EVENT });
    const res = await clientRequest(actionTypes.GET, `${apis.GET_ADVANCE_SEARCH_API}?username=${username}&searchType=event`);
    const data = await payloads.getEventsPayload(res.data);
    dispatch({
      type: actionTypes.GET_ADVANCE_SEARCH_EVENT_SUCCESS,
      payload: data,
    });
  }catch(err) {
    dispatch({ type: actionTypes.GET_ADVANCE_SEARCH_EVENT_FAIL, payload: err });
  }
}