const BASE_URL = process.env.REACT_APP_API;

// Login Section
export const REGISTER_API = `${BASE_URL}/Account/register`;
export const LOGIN_API = `${BASE_URL}/Account/login`;
export const FORGET_PASSWORD_API = `${BASE_URL}/Profile/ResetPasswordRequest`
export const RESET_PASSWORD_API = `${BASE_URL}/Profile/ResetPassword`

export const ORG_REGISTER_API = `${BASE_URL}/Account/OrgUserRegistration`;
export const DOC_REGISTER_API = `${BASE_URL}/Account/DoctorRegistration`;
export const PATIENT_REGISTER_API = `${BASE_URL}/Account/PatientRegistration`;
export const USER_REGISTER_API = `${BASE_URL}/Account/SingleUserRegistration`;

// Upload Section
export const GET_FAMILIES_API = `${BASE_URL}/cosmaresource/family`;
export const ADD_FAMILY_API = `${BASE_URL}/FamilyUi/SaveFamily`;
export const DELETE_FAMILY_API = `${BASE_URL}/FamilyUi/DeleteFamily`;
export const GET_FAMILY_IMAGE_API = `https://cosmaonline.com/Image/GetFamilyImageFile`;

export const GET_EVENTS_API = `${BASE_URL}/cosmaresource/event`;
export const ADD_EVENT_API = `${BASE_URL}/EventUi/SaveEvent`;
export const DELETE_EVENT_API = `${BASE_URL}/EventUi/DeleteEvent`;
export const GET_EVENT_IMAGE_API = `https://cosmaonline.com/Image/GetEventImageFile`;

export const GET_ITEMS_API = `${BASE_URL}/cosmaresource/item`;
export const ADD_ITEM_API = `${BASE_URL}/ItemUi/Save`;
export const DELETE_ITEM_API = `${BASE_URL}/ItemUi/DeleteItem`;
export const GET_ITEM_IMAGE_API = `https://cosmaonline.com/Image/GetItemImageFile`;

export const GET_REMINDERS_API = `${BASE_URL}/Reminders/GetReminders`;
export const ADD_REMINDER_API = `${BASE_URL}/Reminders/SaveReminder`;
export const DELETE_REMINDER_API = `${BASE_URL}/Reminders/Delete`;

export const GET_FREQUENCY_LIST_API = `${BASE_URL}/Reminders/GetFrequencyList`;
export const GET_LIST_TIME_API = `${BASE_URL}/Reminders/GetListTime`;

export const GET_BEHAVIOURALS_API = `${BASE_URL}/Behavioural/Details`;
export const ADD_BEHAVIOURAL_API = `${BASE_URL}/Behavioural/CreateBehavioural`;
export const DELETE_BEHAVIOURAL_API = `${BASE_URL}/Behavioural/Delete`;

export const GET_EXERCISES_LIST_API = `${BASE_URL}/BehaviouralUi/GetExerciseList`;
export const GET_WALK_LIST_API = `${BASE_URL}/BehaviouralUi/GetWalkList`;
export const GET_MEDICATION_LIST_API = `${BASE_URL}/BehaviouralUi/GetMedicationList`;
export const GET_HOBBIES_LIST_API = `${BASE_URL}/BehaviouralUi/GetHobbiesList`;
export const GET_MILES_LIST_API = `${BASE_URL}/BehaviouralUi/GetMilesList`;
export const GET_SLEEP_DURATION_LIST_API = `${BASE_URL}/BehaviouralUi/GetSleepdurationList`;
export const GET_TIME_LIST_API = `${BASE_URL}/BehaviouralUi/GetTimeList`;
export const GET_TASK_LIST_API = `${BASE_URL}/Task/GetTasks`;
export const GET_COLOR_LIST_API = `${BASE_URL}/Colour/GetColours`;

export const GET_SCHEDULES_API = `${BASE_URL}/Schedule/GetSchedules`;
export const ADD_SCHEDULE_API = `${BASE_URL}/Schedule/CreateSchedule`;
export const DELETE_SCHEDULE_API = `${BASE_URL}/Schedule/Delete`;

export const GET_SONGS_API = `${BASE_URL}/cosmaresource/song`;
export const ADD_SONG_API = `${BASE_URL}/SongUi/Save`;
export const DELETE_SONG_API = `${BASE_URL}/SongUi/Delete`;

// Profile Section
export const GET_USERNAME_API = `${BASE_URL}/CustomerUi/GetUserName`;
export const GET_ORG_API = `${BASE_URL}/CustomerUi/GetOrganisation`;
export const GET_DIAGNOSIS_API = `${BASE_URL}/CustomerUi/GetDiagnosisList`;
export const GET_STAGES_LIST_API = `${BASE_URL}/CustomerUi/GetStagesList`;
export const GET_SEX_LIST_API = `${BASE_URL}/CustomerUi/GetSexList`;
export const GET_RELIGION_LIST_API = `${BASE_URL}/CustomerUi/GetReligionList`;

export const GET_PROFILES_API = `${BASE_URL}/Profile/GetProfiles`;
export const GET_OTHER_PROFILES_API = `${BASE_URL}/OtherProfile/GetOtherProfiles`;

export const GET_ACCOUNTS_API = `${BASE_URL}/MyAccount/GetAccounts`;

export const GET_ORGS_API = `${BASE_URL}/Profile/GetOrgUser`;
export const GET_DOCTORS_API = `${BASE_URL}/Profile/GetOrgDoctors`;
export const GET_PATIENTS_API = `${BASE_URL}/Profile/GetSingleUser`;
export const GET_FEEDBACKS_API = `${BASE_URL}/BugReport/GetBugReport`;
export const ADD_FEEDBACK_API = `${BASE_URL}/BugReport/SaveBugReport`;
// export const DELETE_ACCOUNT_API = `${BASE_URL}/MyAccount/DeleteAccount`;
export const DELETE_ACCOUNT_API = `${BASE_URL}/Profile/DeleteProfile`;

export const GET_REPORTS_API = `${BASE_URL}/score/GetScoreReport`;
export const GET_MODULE_PLAY_REPORTS_API = `${BASE_URL}/PlayReport/GetModulePlayReport`;
export const GET_PLAY_REPORTS_API = `${BASE_URL}/PlayReport/GetPlayReport`;

export const GET_ADVANCE_SEARCH_API = `${BASE_URL}/api/Search/AdvancedSearch`;

// Users Section
export const CREATE_ORG_API = `${BASE_URL}/Org/Save`;
export const CREATE_DOCTOR_API = `${BASE_URL}/Doctor/SaveDoctor`;
export const CREATE_PATIENT_API = `${BASE_URL}/Patient/SavePatient`;

//api list added by ataur

//profile
export const GET_PROFILE_BY_ID_API = `${BASE_URL}/Profile/GetProfileById`;
export const UPDATE_USERINFO_API = `${BASE_URL}/Profile/SaveProfile`
export const CHANGE_PASSWORD_API = `${BASE_URL}/Profile/ChangePassword`

//all profiles
export const GET_ORG_LIST_API = `${BASE_URL}/Profile/GetOrg`
export const GET_ALL_ORG_USER_API = `${BASE_URL}/Profile/GetAllOrgUsers`
export const GET_ORG_DOCTOR_API = `${BASE_URL}/Profile/GetOrgDoctors`
export const GET_PROFILES_BY_ID_API = `${BASE_URL}/Profile/GetProfileById`