import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Select from "components/Select";

function Filters({ dashboard, user, handleUser }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="primary"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            search
          </Icon>
        </MDBox>
        <MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Advanced Search
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox p={2} display="flex">
        <Grid container>
          <Grid item xs={12} sm={4}>
            <MDBox px={2}>
              <Select
                label="Select User"
                options={dashboard?.userOptions}
                name="user"
                value={user?.name}
                handleSelect={handleUser}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Filters;
