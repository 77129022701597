import React from "react";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Loader from "components/Loader";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// local
import EventContainer from "./EventContainer";
import Filters from "./Filters";

// redux
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/event";
import * as actionTypes from "redux/constants";

const Event = () => {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.event);
  const auth = useSelector((state) => state.auth);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [event.pagination.page]);

  React.useEffect(() => {
    dispatch(actions.getEvents(event.pagination.page, event.pagination.pageSize));

    return () => {
      dispatch(actions.resetEventsPagination());
    }
  }, [dispatch]);

  const handlePagination = (page) => {
    dispatch(actions.getEvents(page, event.pagination.pageSize));
  }

  const handleDelete = (id) => {
    const payload = { id };
    dispatch(actions.deleteEvent(payload));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        {event.loading ? (
          <Loader />
        ) : (
          <>
            {actionTypes.ADVANCED_SECTION.includes(auth.user?.role) && (
              <Grid item xs={12} md={12} xl={12}>
                <MDBox py={3} pl={1}>
                {auth.user.role === "SuperAdmin" &&
                    <Filters />
                  }
                </MDBox>
              </Grid>
            )}

<Grid item xs={12} md={12} xl={12}>
          <MDBox py={3} pl={1}>
                {            
              <div class="card bg-gradient-default">
                <div class="card-body">
                <h5 class="font-weight-normal text-info text-gradient">Instruction</h5>
                <blockquote class="blockquote text-white mb-0">
                  <p class="text-dark ms-3">
                  You will upload at least 10 pictures of events which are personal and meaningful to you. You upload the picture of an item by choosing ‘Add Event’. You will be required to write the event name (e.g. wedding day), the location it took place and the year that occurred. You upload each picture by the ‘Choose file’ function.
The events in the pictures should not be on the same year, name or location. If the location is same then use the country, county, town or city name to differetiate between the pictures. Therefore, choose pictures of your wedding day, birth of a child or grandchild, a significant birthday, or any other event </p>
                </blockquote>
              </div>
            </div>
                }
          </MDBox>
        </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <MDBox py={3} pr={1}>
                <EventContainer events={event.events} pagination={event.pagination} handlePagination={handlePagination} handleDelete={handleDelete} />
              </MDBox>
            </Grid>
          </>
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default Event;
