import React from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function BehaviouralCard({ no, id, behavioural, handleDelete }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={4}
      m={0}
    >
      <Grid container>
        <Grid item xs={12} md={3} lg={4}>
          <MDBox mt={3}>
            <MDBox width="100%" display="flex" flexDirection="column">
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" fontWeight="medium">
                  {no}
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Breakfast Intake Time :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.breakfastIntakeTime}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Lunch Intake Time :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.lunchIntakeTime}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Dinner Intake Time :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.dinnerIntakeTime}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Other Intake Time :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.otherIntakeTime}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>

        <Grid item xs={12} md={3} lg={4}>
          <MDBox mt={6}>
            <MDBox width="100%" display="flex" flexDirection="column">
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Breakfast 1 :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.breakfast1}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Breakfast 2 :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.breakfast2}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Breakfast 3 :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.breakfast3}
                  </MDTypography>
                </MDTypography>
              </MDBox>

              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Lunch 1 :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.lunch1}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Lunch 2 :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.lunch2}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Lunch 3 :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.lunch3}
                  </MDTypography>
                </MDTypography>
              </MDBox>

              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Dinner 1 :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.dinner1}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Dinner 2 :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.dinner2}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Dinner 3 :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.dinner3}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Other :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.other}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>

        <Grid item xs={12} md={3} lg={3}>
          <MDBox mt={6}>
            <MDBox width="100%" display="flex" flexDirection="column">
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Sleep Time :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.sleepTime}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Sleep Duration :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.sleepDuration}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Sleeper :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.sleeper}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Exercise :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.exercise}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Walk :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.walk}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Miles :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.miles}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Medication :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.medication}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="subtitle1" color="text" style={{ display: "flex" }}>
                  Hobbies :&nbsp;
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    {behavioural.hobbies}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>

        <Grid item xs={12} md={3} lg={1}>
          <MDBox display="flex" justifyContent="end">
            <MDBox mr={1}>
              <MDButton variant="text" color="error" onClick={()=>handleDelete(id)}>
                <Icon>delete</Icon>&nbsp;delete
              </MDButton>
            </MDBox>
            {/* <MDButton variant="text" color={darkMode ? "white" : "dark"}>
              <Icon>edit</Icon>&nbsp;edit
            </MDButton> */}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default BehaviouralCard;
