import cookie from "react-cookies";

export const setToken = (token) => {
  cookie.save("cosma-token", token, { path: "/" });
};

export const getToken = () => {
  return cookie.load("cosma-token");
};

export const removeToken = () => {
  cookie.remove("cosma-token", { path: "/" });
};

export const setUser = (userId) => {
  cookie.save("cosma-user", userId, { path: "/" });
};

export const getUser = () => {
  return cookie.load("cosma-user");
};

export const removeUser = () => {
  cookie.remove("cosma-user", { path: "/" });
};

export const setUserName = (userId) => {
  cookie.save("cosma-user-name", userId, { path: "/" });
};

export const getUserName = () => {
  return cookie.load("cosma-user-name");
};

export const removeUserName = () => {
  cookie.remove("cosma-user-name", { path: "/" });
};

export const setUserRole = (userId) => {
  cookie.save("cosma-user-role", userId, { path: "/" });
};

export const getUserEmail = () => {
  return cookie.load("cosma-user-email");
};

export const removeUserEmail = () => {
  cookie.remove("cosma-user-email", { path: "/" });
};

export const setUserEmail = (email) => {
  cookie.save("cosma-user-email", email, { path: "/" });
};

export const getUserRole = () => {
  return cookie.load("cosma-user-role");
};

export const removeUserRole = () => {
  cookie.remove("cosma-user-role", { path: "/" });
};

export const getUserSubscriptionId = () => {
  return cookie.load("cosma-user-SubscriptionId:");
};

export const removeUserSubscriptionId = () => {
  cookie.remove("cosma-user-SubscriptionId:", { path: "/" });
};

export const setUserSubscriptionId = (email) => {
  cookie.save("cosma-user-SubscriptionId:", email, { path: "/" });
};

export const setLoginUser = (data) => {
  console.log(data,"JAY")
  setToken(data.token);
  setUser(data.userId);
  setUserName(data.userName);
  setUserRole(data.role);
  setUserEmail(data.email)
  setUserSubscriptionId(data.subscriptionId)
};

export const getLoginUser = () => {
  return {
    token: getToken(),
    userId: getUser(),
    userName: getUserName(),
    email: getUserEmail(),
    role: getUserRole(),
    subscriptionId:getUserSubscriptionId(),
  };
};

export const removeLoginUser = () => {
  removeToken();
  removeUser();
  removeUserName();
  removeUserRole();
  removeUserEmail();
  removeUserSubscriptionId();
};
