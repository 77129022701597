import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// local
import Filters from "./Filters";
import Profile from "./Profile";
import Reports from "./Reports";
import DonutChart from "./DonutChart";
import SubCategories from "./SubCategories";
import LineChart from "./LineChart";
import Tips from "./Tips";

// redux
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/dashboard";
import * as actionTypes from "redux/constants";
import * as utils from "redux/utils";
import FormField from "../account/components/FormField";
import InputFilters from "./FiltersInput";
import moment from "moment";

const MEMORY = "Memory";

function Dashboard() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const dashboard = useSelector((state) => state.dashboard);

  const [user, setUser] = useState({ id: 1, name: "admin@cosmaonline.com" });
  const [report, setReport] = useState({ id: 1, name: "Today" });
  const [startDate, setStartDate] = useState(utils.parseDate(new Date()));
  const [endDate, setEndDate] = useState(utils.parseDate(new Date()));
  const [cat, setCat] = useState("");
  const [subCat, setSubCat] = useState("");
  const [profile, setProfile] = useState({ id: 1, name: "Admin", email: "admin@cosmaonline.com" });
  const [memory, setMemory] = useState({ name: "memory", value: "" });
  const [Catmemory, setCatMemory] = useState({ name: "memory", value: "" });
  const [userID, setuserID] = useState(undefined);
  const [reportTimeLine, setreportTimeLine] = useState({
    fromDate: moment().format("yyyy-MM-DD"),
    toDate: moment().format("yyyy-MM-DD"),
  });
  const handleCat = (value) => {
    setCat(value);
    console.log(value, "Handle Cat");
    if (value !== MEMORY) setSubCat("");
  };

  console.log('cat memory', Catmemory)
  console.log('memory', memory)

  const handleSubCat = (value) => {
    console.log(value, "CHART TYPE");
    setSubCat(value);
  };

  const handleUser = (name, value) => {
    setUser(value);
    // console.log(dashboard);
    // const profile = dashboard.users.find((ele) => ele.email === value.name);
    console.log(profile);
    dispatch(actions.getReports(profile?.id));
    setProfile(profile);
  };

  const reportTime = (reportTime) => {
    switch (reportTime) {
      case "last year":
        return setreportTimeLine({
          fromDate: moment().subtract(365, "days").format("yyyy-MM-DD"),
          toDate: moment().format("yyyy-MM-DD"),
        });
      case "last month":
        return setreportTimeLine({
          fromDate: moment().subtract(30, "days").format("yyyy-MM-DD"),
          toDate: moment().format("yyyy-MM-DD"),
        });
      case "last week":
        return setreportTimeLine({
          fromDate: moment().subtract(7, "days").format("yyyy-MM-DD"),
          toDate: moment().format("yyyy-MM-DD"),
        });
      case "today":
        return setreportTimeLine({
          fromDate: moment().format("yyyy-MM-DD"),
          toDate: moment().format("yyyy-MM-DD"),
        });
      default:
        return "";
    }
  };

  const handleReport = (name, value) => {
    console.log(value);
    setReport(value);
    reportTime(value.name.toLowerCase().trim());
  };

  const handleSubmitReport = () => {
    if (auth.user.role === "EndUser") {
      dispatch(actions.getReports(auth?.user?.email, reportTimeLine));
    } else {
      dispatch(actions.getReports(userID, reportTimeLine));
    }
  };

  const handleChange = (e) => {
    console.log(e.target.value, "DATES");
    const clone = { ...reportTimeLine };
    if (e.target.name === "startDate") {
      setStartDate(e.target.value);

      clone.fromDate = e.target.value;
      setreportTimeLine(clone);
    } else {
      clone.toDate = e.target.value;
      setEndDate(e.target.value);
      setreportTimeLine(clone);
      // dispatch(actions.getReports(userID, clone));
    }
  };

  const handleMemoryType = (e) => {
    setMemory({ ...memory, value: e.target.value });
  };

  const handleCatMemoryType = (e) => {
    setCatMemory({ ...Catmemory, value: e.target.value });
  };

  const handleUserIDChange = (e) => {
    console.log(e.target.name, e.target.value)
    setuserID(e.target.value);
  };



  const memoryColor = (color) => {
    console.log(color, "COLOR");
    switch (color) {
      case "episodic memory":
        return "primary";
      case "procedural memory":
        return "secondary";
      case "semantic memory":
        return "warning";
      case "working memory":
        return "primary";
      case "working spatial memory":
        return "secondary";
      default:
        return "info";
    }
  };

  console.log(dashboard)
  console.log('sub cat test hello', subCat)


  return (
    <DashboardLayout>
      <DashboardNavbar />

      <>
        {/* .    if (auth.user.role === "EndUser") { */}
        {actionTypes.ADVANCED_SECTION.includes(auth.user?.role) && (
          <Grid container>
            <Grid item xs={12} md={12}>
              <MDBox mt={3}>
                <InputFilters handleUserIDChange={handleUserIDChange} />
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <MDBox mt={4}>
                <Reports
                  dashboard={dashboard}
                  report={report}
                  handleReport={handleReport}
                  startDate={startDate}
                  endDate={endDate}
                  handleChange={handleChange}
                />
              </MDBox>
            </Grid> */}
          </Grid>
        )}
        <Grid container>
          {/* <Grid item xs={12} md={6}>
              <MDBox mt={4} pr={2}>
                <Profile id={profile.id} name={profile.name} email={profile.email} />
              </MDBox>
            </Grid> */}

          <Grid item xs={12} md={12}>
            <MDBox mt={4}>
              <Reports
                dashboard={dashboard}
                report={report}
                handleReport={handleReport}
                startDate={startDate}
                endDate={endDate}
                handleChange={handleChange}
                handleSubmitReport={handleSubmitReport}
              />
            </MDBox>
          </Grid>
        </Grid>
      </>

      <Grid container>
        <Grid item xs={12} md={12}>
          <MDBox py={3} pr={1} mt={1.5}>
            <Grid container spacing={3}>
              {dashboard?.dashboardData?.length > 0
                ? dashboard?.dashboardData[0]?.DashboardScores?.map((ele, idx) => (
                  <Grid key={idx} item xs={12} md={4}>
                    <MDBox mb={1.5} style={{ cursor: "pointer" }} onClick={() => handleCat(ele)}>
                      <ComplexStatisticsCard
                        color={ele.color}
                        icon={ele.icon}
                        title={ele.name}
                        count={ele.score.toFixed(2)}
                        percentage={{
                          color: "success",
                          amount: "+55%",
                          label: `than ${report?.name}`,
                        }}
                        cat={cat}
                      />
                    </MDBox>
                  </Grid>
                ))
                : dashboard?.categories?.map((ele, idx) => (
                  <Grid key={idx} item xs={12} md={4}>
                    <MDBox mb={1.5} style={{ cursor: "pointer" }}>
                      <ComplexStatisticsCard
                        color={ele.color}
                        icon={ele.icon}
                        title={ele.name}
                        count={ele.count}
                        percentage={{
                          color: "success",
                          amount: "+55%",
                          label: `than ${report?.name}`,
                        }}
                        cat={cat}
                      />
                    </MDBox>
                  </Grid>
                ))}
            </Grid>
          </MDBox>
        </Grid>
      </Grid>

      {cat && (
        <>
          <Grid container>
            <Grid item xs={12} md={12}>
              <MDBox>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <MDBox pr={3}>
                      {cat?.subCategoryList.length > 0 && (
                        <DonutChart
                          types={
                            memory.value === "Long Term"
                              ? dashboard?.memories?.longTerm?.SubSubCategoryList
                              : dashboard?.memories?.shortTerm?.SubSubCategoryList
                          }
                          Cat={cat}
                          title={cat?.name}
                          handleCat={handleCat}
                          memory={Catmemory}
                          handleMemoryType={handleCatMemoryType}
                          chart={cat.subCategoryList}
                          report={report}
                        />
                      )}
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={cat?.subCategoryList.length > 0 ? 8 : 12}>
                    <LineChart
                      title={
                        Catmemory.value !== ""
                          ? Catmemory.value === "Long Term"
                            ? cat?.subCategoryList[0]?.name
                            : cat?.subCategoryList[1]?.name
                          : cat.name
                      }
                      color={undefined}
                      chart={
                        Catmemory.value !== ""
                          ? Catmemory.value === "Long Term"
                            ? cat?.subCategoryList[0]?.Chart
                            : cat?.subCategoryList[1]?.Chart
                          : cat.Chart
                      }
                      report={report}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
          {console.log(dashboard.memories.longTerm, "TEST")}
          {cat?.name?.toLowerCase() === "memory" && (
            <MDBox mt={4}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <MDBox pr={3}>
                        <SubCategories
                          types={
                            memory.value === "Long Term"
                              ? dashboard.memories.longTerm.SubSubCategoryList
                              : dashboard.memories.shortTerm.SubSubCategoryList
                          }
                          subCat={subCat}
                          handleSubCat={handleSubCat}
                          memory={memory}
                          handleMemoryType={handleMemoryType}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <MDBox pr={3}>
                        <LineChart
                          title={subCat?.name}
                          color={memoryColor(subCat?.name?.toLowerCase().trim())}
                          chart={subCat?.Chart}
                          report={report}
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          )}
        </>
      )}
      <MDBox mt={4}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Tips />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
