import * as actionTypes from "../constants";
import * as apis from "../requests/apis";
import * as payloads from "../requests/payloads";
import { clientRequest, contentHeader } from "../requests";

// Get Behaviourals
export const getBehaviourals = (page, pageSize, reset = false) => async (dispatch) => {
  try {
    if(reset) dispatch({ type: actionTypes.RESET_BEHAVIOURAL });
    else dispatch({ type: actionTypes.FETCH_BEHAVIOURALS });
    const res = await clientRequest(actionTypes.GET, apis.GET_BEHAVIOURALS_API, null);
    const behaviourals = payloads.getBehaviouralsPayload(res.data);
    const data = {
      behaviourals,
    };
    dispatch({
      type: actionTypes.GET_BEHAVIOURALS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_BEHAVIOURALS_FAIL,
      payload: err,
    });
  }
};

export const getBehaviouralOptions = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCH_BEHAVIOURAL_OPTIONS });
    const exercisesList = await clientRequest(actionTypes.GET, apis.GET_EXERCISES_LIST_API, null);
    const walkList = await clientRequest(actionTypes.GET, apis.GET_WALK_LIST_API, null);
    const medicationList = await clientRequest(actionTypes.GET, apis.GET_MEDICATION_LIST_API, null);
    const hobbiesList = await clientRequest(actionTypes.GET, apis.GET_HOBBIES_LIST_API, null);
    const milesList = await clientRequest(actionTypes.GET, apis.GET_MILES_LIST_API, null);
    const durationList = await clientRequest(
      actionTypes.GET,
      apis.GET_SLEEP_DURATION_LIST_API,
      null
    );
    const timesList = await clientRequest(actionTypes.GET, apis.GET_TIME_LIST_API, null);

    const exerciseOptions = payloads.getOptionsListPayload(exercisesList.data);
    const walkOptions = payloads.getOptionsListPayload(walkList.data);
    const medicationOptions = payloads.getOptionsListPayload(medicationList.data);
    const hobbyOptions = payloads.getOptionsListPayload(hobbiesList.data);
    const mileOptions = payloads.getOptionsListPayload(milesList.data);
    const sleepOptions = payloads.getOptionsListPayload(durationList.data);
    const timeOptions = payloads.getOptionsListPayload(timesList.data);

    const data = {
      exerciseOptions,
      walkOptions,
      medicationOptions,
      hobbyOptions,
      mileOptions,
      sleepOptions,
      timeOptions,
    };

    dispatch({
      type: actionTypes.GET_BEHAVIOURAL_OPTIONS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.GET_BEHAVIOURAL_OPTIONS_FAIL,
      payload: err,
    });
  }
};

// Add Behavioural
export const addBehavioural = (form) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SAVING_BEHAVIOURAL });
    const payload = payloads.behaviouralPayload(form);
    await clientRequest(actionTypes.POST, apis.ADD_BEHAVIOURAL_API, payload, contentHeader);
    setTimeout(() => {
      dispatch({
        type: actionTypes.ADD_BEHAVIOURAL_SUCCESS,
      });
    }, 2000);
  } catch (err) {
    dispatch({
      type: actionTypes.ADD_BEHAVIOURAL_FAIL,
      payload: err,
    });
  }
};

// Edit Behavioural
export const editBehavioural = (form) => async (dispatch) => {
  try {
    // await clientRequest(actionTypes.GET, apis.GET_FAMILIES_API, null);

    dispatch({
      type: actionTypes.EDIT_BEHAVIOURAL_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.EDIT_BEHAVIOURAL_FAIL,
      payload: err,
    });
  }
};

// Delete Behavioural
export const deleteBehavioural = (form) => async (dispatch) => {
  try {
    await clientRequest(actionTypes.POST, `${apis.DELETE_BEHAVIOURAL_API}?Id=${form.id}`, null);
    dispatch({
      type: actionTypes.DELETE_BEHAVIOURAL_SUCCESS,
      payload: form,
    });
  } catch (err) {
    dispatch({
      type: actionTypes.DELETE_BEHAVIOURAL_FAIL,
      payload: err,
    });
  }
};

// Get Advance Search
export const getAdvanceSearchBehavioural = (username) => async (dispatch) => {
  try{
    dispatch({ type: actionTypes.ADVANCE_SEARCH_REQUEST_BEHAVIOURAL });
    const res = await clientRequest(actionTypes.GET, `${apis.GET_ADVANCE_SEARCH_API}?username=${username}&searchType=behavioural`);
    const data = await payloads.getBehaviouralsPayload(res.data);
    dispatch({
      type: actionTypes.GET_ADVANCE_SEARCH_BEHAVIOURAL_SUCCESS,
      payload: data,
    });
  }catch(err) {
    dispatch({ type: actionTypes.GET_ADVANCE_SEARCH_BEHAVIOURAL_FAIL, payload: err });
  }
}