import React from "react";
import Icon from "@mui/material/Icon";

// components
import MDPagination from "components/MDPagination";

const Pagination = ({ pageCount, page, handleClick }) => {

  const getPages = () => {
    let pages = [];
    for(let i=1; i<=pageCount; i++) {
      pages.push(i);
    }
    return pages;
  }

  const pages = getPages();

  return (
    <div style={{ margin: 16, marginBottom: 24 }}>
      <MDPagination size="large">
        {
          pages.map((ele, idx) => <MDPagination key={idx} item active={ele === page ? true : false} onClick={()=>handleClick(ele)}>{ele}</MDPagination>)
        }
      </MDPagination>
    </div>
  );
};

export default Pagination;
