import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// redux
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/song";

function Filters() {
  const dispatch = useDispatch();
  const song = useSelector((state) => state.song);
  const auth = useSelector((state)=>state.auth)
  const [username, setUsername] = React.useState("");

  const handleSearch = () => {
    dispatch(actions.getAdvanceSearchSong(username));
  };

  const handleResetSearch = () => {
    setUsername("");
    dispatch(actions.getSongs(song.pagination.page, song.pagination.pageSize, true));
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="primary"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            search
          </Icon>
        </MDBox>
        <MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Advanced Search
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox p={2} display="flex">
        <MDInput
          label="Type here..."
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{ width: 380 }}
        />
        <MDButton
          style={{ marginLeft: 16 }}
          color="primary"
          disabled={username ? false : true}
          onClick={handleSearch}
        >
          {song.advanceSearchLoading ? "Searching..." : "Search"}
        </MDButton>
        <MDButton style={{ marginLeft: 16 }} color="primary" onClick={handleResetSearch}>
          {song.resetting ? "Resetting..." : "Reset"}
        </MDButton>
      </MDBox>
    </Card>
  );
}

export default Filters;
