import React, { useEffect, useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import DefaultDoughnutChart from "./DoughnutCharts/DefaultDoughnutChart";

import Categories from "./Categories";

function DonutChart({ chart, title, ...props }) {
  // console.log(longTerm,shortTerm,"TEST")
  const [data, setdata] = useState([]);
  useEffect(() => {
    let scoreArray = [];
    let datesArray = [];
    for (let index = 0; index < chart?.length; index++) {
      scoreArray?.push(chart[index]?.score);
      // datesArray?.push(chart[index]?.date.split("T")[0]);
    }
    setdata({ scoreArray });
  }, [title]);


  // console.log('chart', chart.sort((a, b) => {
  //   let x = new Date(a.date)
  //   let y = new Date(b.date)
  //   return x == y ? 0 : x > y ? 1 : -1;
  // }))
  console.log('chart page data', data)
  return (
    <DefaultDoughnutChart
      icon={{ color: "primary", component: "donut_small" }}
      title="All Memory Types"
      description={props.report?.name}
      {...props}
      chart={{
        labels: ["Long Term Memory", "Short Term Memory"],
        datasets: {
          label: title,
          backgroundColors: ["success", "info"],
          // data: [30,50]
          data: data.scoreArray,
        },
      }}
    />
  );
}

export default DonutChart;
