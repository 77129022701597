import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

//image crop
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop"
import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from "../../../../utils/imageCrop/useDebounceEffect";
import { canvasPreview } from "../../../../utils/imageCrop/canvasPreview";

//modal
import { Button, Modal } from "react-bootstrap";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Select from "components/Select";

// layouts
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// redux
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/item";
import * as data from "redux/data";

const colorList = [
  { id: 1, name: 'Red' },
  { id: 2, name: 'Blue' },
  { id: 3, name: 'Yellow' },
  { id: 4, name: 'Green' },
  { id: 5, name: 'Black' },
  { id: 6, name: 'Purple' },
  { id: 7, name: 'White' },
  { id: 8, name: 'Gray' },
  { id: 9, name: 'Brown' },
  { id: 10, name: 'Pink' },
  { id: 11, name: 'Orange'},
  { id: 12, name: 'Cyan (SkyBlue)'}
]

const emptyForm = {
  id: uuidv4(),
  name: "",
  colorName: "",
  colourId: "1",
  photo: data.getPlaceHolder(),
  base64String: ""
};

function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const ItemForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const item = useSelector((state) => state.item);

  const [form, setForm] = React.useState(emptyForm);
  const [imgSrc, setImgSrc] = React.useState('')
  const previewCanvasRef = React.useRef(null)
  const imgRef = React.useRef(null)
  const [crop, setCrop] = React.useState()
  const [cropImgData, setCropImgData] = React.useState("")
  const [completedCrop, setCompletedCrop] = React.useState()
  const [scale, setScale] = React.useState(1)
  const [rotate, setRotate] = React.useState(0)
  const [aspect, setAspect] = React.useState(16 / 9)
  const [show, setShow] = React.useState(false);
  const [imgCropStatus, setImgCropStatus] = React.useState(false)
  const [imgCropPermission, setImgCropPermission] = React.useState(false)


  const handleClose = () => {
    setForm({ ...form, base64String: "" })
    setImgCropStatus(false)
    setImgCropPermission(false)
    setShow(false);
  }

  const handleShow = () => setShow(true);

  const handleConfirm = () => {
    if (imgCropStatus && imgCropPermission) {
      const onlyFile = cropImgData.split(",")[1]
      const fileSize = onlyFile.split('').length / 1024 / 1024
      if (fileSize > 15) {
        alert('File can not bigger than 15mb.')
      } else {
        setForm({ ...form, base64String: onlyFile });
      }
    } else {
      const onlyFile = imgSrc.split(",")[1]
      const fileSize = onlyFile.split('').length / 1024 / 1024
      if (fileSize > 15) {
        alert('File can not bigger than 15mb.')
      } else {
        setForm({ ...form, base64String: onlyFile });
      }
    }
    setImgCropStatus(false)
    setImgCropPermission(false)
    setShow(false);
  }


  React.useEffect(() => {
    if (item.itemSaved) navigate(`/upload/item`);
  }, [item, navigate]);

  const handleCropPhoto = async () => {
    const base64Data = previewCanvasRef.current?.toDataURL()
    const base64Str = base64Data ? base64Data.split(',')[1] : ""
    setCropImgData(base64Data)
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handlePhoto = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleShow()
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  };

  const handleSubmit = () => {
    if (!form.base64String) {
      alert('Image is missing. Maybe forget to confirm when you upload.')
    } else {
      dispatch(actions.addItem(form));
    }
  };


  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  const handleSelect = (name, value) => {
    setForm({ ...form, [name]: value.name });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item xs={12} md={12} xl={12}>
          <MDBox py={3} pr={1}>
            <Card sx={{ width: "100%" }}>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="primary"
                  color="white"
                  shadow="md"
                  borderRadius="xl"
                  ml={3}
                  mt={-2}
                >
                  <Icon fontSize="medium" color="inherit">
                    add
                  </Icon>
                </MDBox>
                <MDBox>
                  <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                    Add Item
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Grid container>
                <Grid item xs={12}>
                  <Card>
                    <MDBox p={3}>
                      <MDBox mt={1}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type="text"
                              label="Name"
                              name="name"
                              value={form.name}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}></Grid>
                        </Grid>
                      </MDBox>

                      <MDBox>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Select
                              label="Color"
                              options={colorList}
                              name="colorName"
                              value={form?.colorName}
                              handleSelect={handleSelect}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}></Grid>
                        </Grid>
                      </MDBox>

                      <MDBox mt={4}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <MDButton color="secondary">
                              <input
                                type="file"
                                label="Photo"
                                name="photo"
                                accept="image/*"
                                onChange={handlePhoto}
                              />
                            </MDButton>
                          </Grid>
                          <>
                            <Modal show={show} centered>
                              {
                                imgCropPermission && <strong className="text-center m-3">Note : To crop image move the crop window</strong>
                              }
                              <Modal.Body>
                                {Boolean(imgSrc) && imgCropPermission ? (
                                  <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => {
                                      setCrop(percentCrop)
                                      setImgCropStatus(true)
                                    }}
                                    onComplete={(c) => {
                                      setCompletedCrop(c)
                                      setTimeout(() => {
                                        handleCropPhoto()
                                      }, 1000)
                                    }}
                                    aspect={aspect}
                                  >
                                    <img
                                      ref={imgRef}
                                      alt="Crop image"
                                      src={imgSrc}
                                      className="text-center"
                                      style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                      onLoad={onImageLoad}
                                    />
                                  </ReactCrop>
                                ) :
                                  <div className="w-100">
                                    <img
                                      ref={imgRef}
                                      alt="image"
                                      src={imgSrc}
                                      // width={400}
                                      // height={200}
                                      style={{ width: "100%" }}
                                    />
                                  </div>

                                }
                                <div className="text-center" style={{ display: "none" }}>
                                  {Boolean(completedCrop) && (
                                    <canvas
                                      ref={previewCanvasRef}
                                      style={{
                                        border: '1px solid black',
                                        objectFit: 'contain',
                                        width: completedCrop.width,
                                        height: completedCrop.height,
                                      }}
                                    />
                                  )}
                                </div>

                              </Modal.Body>
                              <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                  Close
                                </Button>
                                {
                                  !imgCropPermission &&
                                  <Button variant="success" onClick={() => {
                                    setImgCropPermission(!imgCropPermission)
                                  }}>
                                    Crop image
                                  </Button>
                                }

                                <Button variant="primary" onClick={handleConfirm}>
                                  Confirm
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </>

                          <Grid item xs={12} sm={6}>
                            <MDBox display="flex" justifyContent="end">
                              <MDButton color="primary" onClick={!item.savingItem && handleSubmit}>
                                {item.savingItem ? "Submitting..." : "Submit"}
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={4} xl={4}></Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ItemForm;
