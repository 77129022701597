import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

export default function MyRadio({ value, ...props }) {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={name}
        value={value}
        {...props}
      >
        <FormControlLabel value="organisation" control={<Radio />} label="Organisation" />
        <FormControlLabel value="doctor" control={<Radio />} label="Admin" />
        <FormControlLabel value="patient" control={<Radio />} label="User" />
      </RadioGroup>
    </FormControl>
  );
}
