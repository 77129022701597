import React, { useEffect, useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";

const LineChart = ({ title, color, chart, ...props }) => {
  const [data, setdata] = useState([]);
  console.log(title,"TITLE")
  useEffect(() => {
    let scoreArray = [];
    let datesArray = [];
    for (let index = 0; index < chart?.sort((a, b) => {
      let x = new Date(a.date)
      let y = new Date(b.date)
      return x == y ? 0 : x > y ? 1 : -1;
    }).length; index++) {
      scoreArray?.push(chart[index]?.score);
      datesArray?.push(chart[index]?.date.split("T")[0]);
    }
    setdata({ scoreArray, datesArray });
  }, [title]);

  console.log('line chart',data);

  return (
    <MDBox mt={6} pr={1}>
      <ReportsLineChart
        color={
          color !== undefined
            ? color
            : title === "Attention"
            ? "primary"
            : title === "Language"
            ? "secondary"
            : title === "Memory"
            ? "info"
            : title === "Orientation"
            ? "success"
            : title === "Reaction Time"
            ? "warning"
            : title === "Long-Term Memory"
            ? "warning"
            : "error"
        }
        title={title}
        description={<>{/* (<strong>+15%</strong>) increase in {props.report?.name}. */}</>}
        date="updated 4 min ago"
        chart={{
          labels: data?.datesArray,
          datasets: { title, data: data?.scoreArray },
        }}
      />
    </MDBox>
  );
};

export default LineChart;
