import { Card } from "@mui/material"
import MDBox from "components/MDBox"
import BaseLayout from "layouts/pages/account/components/BaseLayout"
import { useDispatch, useSelector } from "react-redux"
import Loader from "components/Loader";
import MDTypography from "components/MDTypography"
import { useEffect } from "react";
import * as actions from "redux/actions/profile";
import * as actionType from "redux/constants"
import { Table } from "react-bootstrap";

const FeedbackList = () => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile);
    const auth = useSelector((state) => state.auth);

    console.log(profile)

    useEffect(() => {
        dispatch(actions.getFeedBack());
    }, [dispatch]);

    if (profile.loading) {
        return <Loader />;
    } else {
        return (
            <BaseLayout>
                <MDBox mt={4}>
                    <MDBox mb={3}>
                        <Card id="2fa" sx={{ overflow: "visible" }}>
                            <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <MDTypography variant="h5">All Feedbacks</MDTypography>
                                {/* <MDBadge variant="contained" color="success" badgeContent="enabled" container /> */}
                            </MDBox>
                            <MDBox p={3}>
                                <MDBox>
                                    <div>
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>Subject</th>
                                                    <th>Message</th>
                                                    <th>DateReported</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    profile.allProfiles?.feedbacks?.length > 0 &&
                                                    profile.allProfiles?.feedbacks?.map((ele, idx) => (
                                                        <tr key={idx}>
                                                            <td>{ele.subject}</td>
                                                            <td>{ele.message}</td>
                                                            <td>{ele.dateReported}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                        {
                                            !profile.allProfiles?.feedbacks?.length && <p className="text-center p-5">No data found ! </p>
                                        }
                                    </div>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </MDBox>
                </MDBox>
            </BaseLayout>
        )
    }


}

export default FeedbackList