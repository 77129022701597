import moment from "moment";

const dateFormat = "YYYY-MM-DD";

const classNames = ["success", "info", "warning", "error", "primary"];

export const parseDate = (data) => {
  return moment(data).format(dateFormat);
};

export const getRandomClass = () => {
  const arr = classNames.sort(() => 0.5 - Math.random());
  return arr[0];
};

export const imageEncode = (arrayBuffer) => {
  let u8 = new Uint8Array(arrayBuffer);
  let b64encoded = btoa(
    [].reduce.call(
      new Uint8Array(arrayBuffer),
      function (p, c) {
        return p + String.fromCharCode(c);
      },
      ""
    )
  );
  let mimetype = "image/jpeg";
  return "data:" + mimetype + ";base64," + b64encoded;
};

export const getRandomValueFromArr = (myArr) => {
  const arr = myArr.sort(() => 0.5 - Math.random());
  return arr[0];
};