import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TimelineItem from "examples/Timeline/TimelineItem";

const Tips = ({ id, name, email, ...props }) => {
  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="info"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            watch
          </Icon>
        </MDBox>
        <MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Tips
          </MDTypography>
        </MDBox>
      </MDBox>
      <Grid container>
        <Grid item xs={12}>
          <MDBox mt={2} px={4} py={2}>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Coming Soon...
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Tips;
