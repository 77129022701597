import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Select from "components/Select";

// layouts
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

const DATEWISE = "Datewise";

const Reports = ({ dashboard, report, handleReport, startDate, endDate, handleChange , handleSubmitReport }) => {
  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="success"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            filter
          </Icon>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          width="100%"
          height="50px"
          alignItems="center"
          pr={4}
          py={2}
        >
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Filter Report
          </MDTypography>
          <MDButton onClick={handleSubmitReport} style={{ marginLeft: 16 }} color="primary">
            Load Reports
          </MDButton>
        </MDBox>
      </MDBox>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <MDBox p={4} pt={1} pb={3}>
            <Select
              label="Select Report"
              options={dashboard?.reportOptions}
              name="report"
              value={report?.name}
              handleSelect={handleReport}
            />

            {report?.name === DATEWISE && (
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <MDBox mt={2} pr={2}>
                    <FormField
                      type="date"
                      label="From Date"
                      name="startDate"
                      value={startDate}
                      onChange={handleChange}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MDBox mt={2} pl={2}>
                    <FormField
                      type="date"
                      label="To Date"
                      name="endDate"
                      value={endDate}
                      onChange={handleChange}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            )}
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Reports;
